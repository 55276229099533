import _ from 'lodash';

import slugify from 'slugify';

import { isBooleanInput } from '@/components/forms/inputs/BooleanInput.vue';
import { isFilesInput } from '@/components/forms/inputs/FilesInput.vue';
import { default as dayjs } from '@/utils/dayjs';

import { hashString } from '@/utils/media';
import { createOrderSortFunction } from '@/utils/algorithms';

const LOCALIZATION_DATE_TIME_FORMATS = {
    'datetime': 'LLL',
    'date': 'LL',
    'time': 'LT'
};

export const MULTIPLE_ANIMALS_IN_CONNECTED_SEPARATOR = ';';

//Use shorter keys in store to reduce storage needs.
//Everything in one index, because flags take less storage than IDs in separate indizes.
//Only saved what is needed. Flags just don't need to be null to be considered true! Usually 1 indicating true!
export const METADATA_SHORT_KEY_MAPPING = {
    'timestamp': 't',
    'created_at': 'g',
    'updated_at': 'm',
    'updates': 'u',
    'updated': 'o',
    'hidden': 'h',
    'connections': 'c', //Each connection is uniquely defined by its ID and the day of the timestamp. If the timestamp differs by at least one day, then it is shown separately!
    //'analyses': 'i'
}

export const ANALYSIS_STATUS_SHORT_KEY_MAPPING = {
    'completed': 'c',
    'queued': 'q',
    'unqueued': 'u'
}
  
export const REPORT_INDEX_SHORT_KEY_MAPPING = {
    'connections': 'c',
    'reports': 'r',
    'horses': 'a',
    'count': 'n',
    'uploadStatus': 's'
}

//Gives all the property fields as values, with alternative names as the keys (can be the same)
export const REPORT_PROPERTY_MAPPINGS = {
    'timestamp': 'timestamp',
    'animal': 'horse',
    'location': 'location',
    'control_examination': 'control_examination'
}

//Map pluralized file types to singular variant
export const MULTIPLE_FILE_TYPE_MAPPINGS = { 
    'images': 'image',
    'videos': 'video',
    'audios': 'audio'
}

export const TYPE_API_MAPPINGS = {
    'boolean': {
        'bool': 'bool'
    },
    'datetime': {
        'datetime': 'date-time-val',
        'date': 'date-val',
        'time': 'time-val'
    },
    'files': {
        'image': 'image',
        'video': 'video',
        'audio': 'audio',
        'images': 'images',
        'videos': 'videos',
        'audios': 'audios'
    },
    'values': {
        'email': 'text',
        'number': 'number',
        'decimal': 'decimal',
        'password': 'text',
        'search': 'text',
        'tel': 'text',
        'text': 'text',
        'url': 'text',
        'address': 'text',
        'color': 'color'
    }
};
//Using invertBy here to create arrays of all possible duplicates
export const TYPE_API_REVERSE_MAPPINGS = _.mapValues(TYPE_API_MAPPINGS, (typeMappings) => _.invertBy(typeMappings));
//Return all options, not inside of categories, and remove duplicate reverse mapping options
export const ALL_UNIQUE_TYPE_API_REVERSE_MAPPINGS = _.reduce(TYPE_API_REVERSE_MAPPINGS, (result, typeMappings) => {
    //Assign all options of each category to the accumulator, removing all multiple options with mapValues
    _.assign(result, _.mapValues(typeMappings, (typeOptions, type) => {
        //If it is exactly one option (unique, return it)
        if (typeOptions.length == 1) return typeOptions[0];
        //Othewise, if we have more than one option or no options, do not map in reverse, keep the original type
        else return type;
    }));

    return result;
}, {});

export function createLocalSlugs(longDescriptor, separator = '-') {
    let slug = slugify(longDescriptor, {
        replacement: separator,
        lower: true, // convert to lower case
        strict: true, // strip special characters
        locale: 'de',
        trim: true
    });

    return { slug }; //Return object to potentially create more slug variants
}

const SHORT_HASH_LENGTH = 10;
const SHORT_SLUG_LENGTH = 20;

const EXAMINATION_STAGE_ATTRIBUTES = ['descriptor', 'name', 'order'];

export async function generateIdentifiersForReportType(type) {
    //Raise error if invalid type is provided
    if (type == null || type.descriptor == null) throw 'Missing type for generating identifiers';

    let examination_stage = (type.examination_stage != null) ? type.examination_stage : {};
    let sub_section = (type.sub_section != null) ? type.sub_section : {}; 
    let sub_category = (type.sub_category != null) ? type.sub_category : {}; 
    let section = (type.section != null) ? type.section : {}; 

    //Create a path that contains all the categorizing properties, besides descriptor
    let categoryPathArray = [examination_stage.descriptor || 'nostage', sub_category.descriptor || 'nosub', type.main_category || 'nomain']; //TODO Decision has to be made for main_category if number or text, before deploying. Should it be descriptor here or not?
    //Create an additional path with sections for sub category and the type itself
    let categoryAndSectionArray = [ 
        _.pick(examination_stage, EXAMINATION_STAGE_ATTRIBUTES),
        _.pick(sub_section, EXAMINATION_STAGE_ATTRIBUTES),
        _.pick(sub_category, EXAMINATION_STAGE_ATTRIBUTES),
        _.pick(section, EXAMINATION_STAGE_ATTRIBUTES)
    ];
    let categorySlug = createLocalSlugs(categoryPathArray.join(' '), '_');
    let typeDescriptorSlug = createLocalSlugs(type.descriptor); //Slugs remove all commas!
    let shortTypeDescriptorSlug = typeDescriptorSlug.slug.substring(0, SHORT_SLUG_LENGTH);

    let fullSlug = [categorySlug.slug, typeDescriptorSlug.slug].join('_');
    let slugHash = await hashString(fullSlug);
    let shortHash = slugHash.substring(0, SHORT_HASH_LENGTH);

    //Create a short readable identifier out of part of the slug and the hash 
    let shortIdentifier = `${shortTypeDescriptorSlug}${shortHash}`;

    return { shortIdentifier, fullSlug, slugHash, shortHash, categoryAndSectionArray };
}

export function mergeWithArrays(objValue, srcValue) {
    if (Array.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
}

export function mergeWithArraysUnion(objValue, srcValue) {
    if (Array.isArray(objValue)) {
      return _.union(objValue, srcValue);
    }
}

export function mergeWithArraysOfValuesUnionKeyOrEquals(objValue, srcValue) {
    if (Array.isArray(objValue)) {
        let newArray = _.unionWith(srcValue, objValue, (arrVal, othVal) => { //Arrays are reversed here, to always apply srcValue, if it is found there first!
            //If keys exist and the key is the same, union
            if (arrVal != null && arrVal.key != null && othVal != null && othVal.key != null && arrVal.key === othVal.key) {
                return true;
            }
            //Otherwise compare for equality
            return _.isEqual(arrVal, othVal);
        });

        //Take all non-null values in the array that either do not have a value property, or if they have it has to be non-null!
        return _.filter(newArray, (arrVal) => (arrVal != null && (!(_.has(arrVal, 'value')) || arrVal.value != null)));
    }
}

export function isReportTypeVisible(reportTypeVersion){
    if (reportTypeVersion == null) {
        return false;
    }
    if (reportTypeVersion.just_for_ai) {
        return false;
    }
    if (reportTypeVersion.disabled_for_new) {
        return false;
    }
    return true;
}

export function isReportTypeVisibleForExistingReports(reportTypeVersion){
    if (reportTypeVersion == null) {
        return false;
    }
    if (reportTypeVersion.just_for_ai) {
        return false;
    }
    return true;
}

export const CATEGORY_SEPARATOR = '->';

export const UNCATEGORIZED_CATEGORY = 'uncategorized';

export const UNCATEGORIZED_METADATA_CATEGORY = 'uncategorized_metadata';

export const ADDITIONAL_COMMENT_FIELD = 'additional_comments';

//Placeholder ID for animals without personal info yet
export const UNKOWN_ANIMAL_ID = 'UNKNOWN_ANIMAL_ID_PLACHOLDER';

//Define the properties that get picked when loading the reports
export const METADATA_PROPERTY = 'metadata';
export const FIELDS_PROPERTY = 'fields';

//Category, Sub-Category, Name
export const MAX_COMPONENT_DEPTH = 3;

//Every component can be prefixed by sub_categories or items, besides the first one ((MAX_COMPONENT_DEPTH * 2) - 1). Also index can be added in the end (+1).
export const MAX_HIERARCHICAL_DEPTH = (MAX_COMPONENT_DEPTH * 2);

//Defines all possible file attributes inside an analysis result
export const ANALYSIS_FILE_ATTRIBUTES = ['visuals'];

//Creates a key out of the given components
export const buildKey = function(categoryDescriptor, subCategoryDescriptor, name){
    let key = categoryDescriptor;
    if (subCategoryDescriptor != null){
    key += CATEGORY_SEPARATOR + subCategoryDescriptor;
    }
    key += CATEGORY_SEPARATOR + name;
    
    return key;
}

//Returns an array of all the components of a given key. If it is just one component, uncategorized is added as the category!
export const parseKey = function(key){
    if (key == null) return null;

    let components = key.split(CATEGORY_SEPARATOR);

    if (components.length < 2) {
        components = [UNCATEGORIZED_CATEGORY].concat(components);
    }

    if (components.length > MAX_COMPONENT_DEPTH) { //Limit to specified depth
        components = components.slice(0, MAX_COMPONENT_DEPTH);
    }
    
    return components;
}

export const SORT_ORDER_KEY = 'sortOrder';

export const COMPONENT_TYPE_KEY = '__component';

//Returns a new object with type replaced by __component with the correct API form of the type
export const convertFieldToComponentType = function(field) {
    return {
        ...(_.omit(field, 'type')),
        [COMPONENT_TYPE_KEY]: 'report-fields.' + field.type
    }
}

export const extractValueFromField = function(field) {
    //Check if it is a componentObject with the value or files embedded in it
    if (_.isObject(field) && _.has(field, '__component')) {
        //Return the first component that is not undefined as the modelValue
        if (field['value'] !== undefined) {
            return field['value'];
        }
        if (field['file'] !== undefined) {
            return field['file'];
        }
        if (field['files'] !== undefined) {
            return field['files'];
        }
        //If none have been found return undefined
        return undefined;
    }
    //Otherwise it is a primitive, just return the value
    return field;
}

//Recursively map (sub-)categories and their items to be an object with an order property set to preserve the order.
export const typeDefinitionToHierarchicalObject = function(categories) {
    //Create an empty object to set all the categories by its name
    let newCategories = {};

    if (Array.isArray(categories)) {
        categories.forEach((category, categoryIndex) => {
            //Check if category has a valid name for setting it in the new object
            if (category.name != null) {
                //Create an object for the new mapped properties of this category with the index being the sortOrder, and all properties that will not be used further added again
                let newCategory = {
                    ...(_.omit(category, ['name', 'items', 'sub_categories'])),
                    [SORT_ORDER_KEY]: categoryIndex
                };
                
                //If we have valid items, convert them to an object as well and set them in the new category
                if (Array.isArray(category['items'])) {
                    let newItems = {};

                    //Map each item to use the name as the key in the new object and remove the name
                    category['items'].forEach((item, itemIndex) => {
                        if (item.name != null) {
                            //Set in object with everything besides name and set the sortOrder
                            newItems[item.name] = {
                                ...(_.omit(item, 'name')),
                                [SORT_ORDER_KEY]: itemIndex
                            }
                        }
                    });
            
                    newCategory['items'] = newItems;
                }
            
                //If it is another category level, continue the recursion
                if (Array.isArray(category['sub_categories'])) {
                    newCategory['sub_categories'] = typeDefinitionToHierarchicalObject(category['sub_categories']);
                }

                newCategories[category.name] = newCategory;
            }
        });
    }

    return newCategories;
}

//Add at every second position for each level in the hierarchy 'sub_categories', or if it is the last element 'items'
export const addKeyHierarchyAttributes = function(keyHierarchy) {
    if (keyHierarchy == null) return null;
    //Maps each item to be an array of two, flattens, and removes the first element
    return _.drop(_.flatMap(keyHierarchy, (currentKey, keyIndex) => {
        //If we are not at the last element (because the separator between the second to last (category) and the last (item) has to be different), join with 'sub_categories'
        if ((keyIndex + 1) < keyHierarchy.length) return ['sub_categories', currentKey];
        ///Otherwise join with items
        return ['items', currentKey];
    }), 1);
}

//Creates a hierarchical object of the array of fields using their keys.
//If typeDefinitionFormat is true, returns in the same format as a type definition (sub_categories and items set)
//If convertTypeToComponentType is true, maps the type key and value to look like the API format (__component)
export const reportFieldsToHierarchicalObject = function(fields, typeDefinitionFormat = false, convertTypeToComponentType = false){
    let hierarchicalFields = {};
    let hierarchicalTypes = {};

    for (let originalField of fields) {
        let field = originalField;
        //Map the field type to be the internal type for all uses!
        let mappedType = ALL_UNIQUE_TYPE_API_REVERSE_MAPPINGS[field.type] || field.type; //Use either the internal type, if mapping exists or the given type
        field.type = mappedType;
        field.index = (field.index != null) ? field.index : undefined; //Use undefined inside the field if it is null

        let keyHierarchy = parseKey(field.key);

        //Create a separate key hierarchy for the type definition format
        let typeDefinitionKeyHierarchy = addKeyHierarchyAttributes(keyHierarchy);
        //Save the data in typeDefinitionFormat if requested
        if (typeDefinitionFormat === true) keyHierarchy = typeDefinitionKeyHierarchy;
        //Only add to the type if it is not the additional_comments field, as they are always added separately!
        if (_.last(typeDefinitionKeyHierarchy) != ADDITIONAL_COMMENT_FIELD) {
            //Always also save the field's type separately as metadata in the type definition format
            _.set(hierarchicalTypes, typeDefinitionKeyHierarchy, _.pick(field, 'type'), Object);
        }

        //Convert the type key to component format after setting it in the type definition
        if (convertTypeToComponentType === true) {
            field = convertFieldToComponentType(field);
        }

        if (keyHierarchy != null) {
            //Add undefined sub_category and index only if it is not to the typeDefinitionFormat
            if (typeDefinitionFormat !== true) {
                //If no sub_category is defined (less than MAX_COMPONENT_DEPTH), add undefined as second to last element
                if (keyHierarchy.length < MAX_COMPONENT_DEPTH) keyHierarchy.splice(-1, 0, 'undefined');
                //Add the index to the hierarchy after sub_category (undefined is kept as null)
                keyHierarchy.splice(-1, 0, (field.index != null) ? field.index : null);
            }

            //Add each field in hierarchy without the key - Fields without category are added as uncategorized. Set the keys to be objects!
            _.set(hierarchicalFields, keyHierarchy, _.omit(field, 'key'), Object);
        }
    }

    return [hierarchicalFields, hierarchicalTypes];
}

//Reverses the above function up to maximum hierarchical depth recursively
export const flattenHierarchicalObject = function(object, recursionLevel = 0) {
    //Map all the values in the level to the fields or an array of fields from the recursion that gets flattened
    let result = _.flatMap(object, (value) => {
        //If the current level is an object or array either return it, if it has a key (fields always have key set) or go to the next recursion level
        if (_.isObject(value)) {
            if (_.has(value, 'key')) return value;
            else if (recursionLevel < MAX_HIERARCHICAL_DEPTH) return flattenHierarchicalObject(value, recursionLevel + 1);
        }
    });
    //If we are at the entry level of the recursion also remove all null values from the array, in case any paths lead to invalid values in the hierarchical tree
    if (recursionLevel <= 0) return _.compact(result);
    return result;
}

//Merges recursively the two given objects and only keeps those keys (categories, sub_categories and items) that exist in the first given object
export const mergeHierarchicalObjectsWithoutExtend = function(object, source) {
    if (object == null) return {};
    //Get all the category or item names that exist in source. The function recurses if it encounters items or sub_categories
    let pickedSourceCategoriesOrItems = _.pick(source, Object.keys(object));

    //Merge all the properties that are remaining, unless it is items or sub_categories, then it needs to apply the same function recursively to filter out all the keys that are not in the first object
    let mergedCategoriesOrItems = _.mergeWith(object, pickedSourceCategoriesOrItems, (objectValue, sourceValue, key) => {
        if (key == 'items' || key == 'sub_categories') return mergeHierarchicalObjectsWithoutExtend(objectValue, sourceValue);
    });

    //Returns the final object with only the categories, sub_categories and items that are in the first object and all other properties have been merged
    return mergedCategoriesOrItems;
}

export const getSortedKeysBySortOrder = function(object, reverseInvalidOrder = false, sortOrderKey = SORT_ORDER_KEY) {
    if (object == null) return [];

    let keys = Object.keys(object);

    keys.sort((a, b) => {
        //If both have a valid sort order, compare as usual
        if (object[a] != null && object[b] != null && object[a][sortOrderKey] != null && object[b][sortOrderKey] != null) {
            //If they are identical, sort them alphabetically
            if (object[a][sortOrderKey] == object[b][sortOrderKey]) return a.localeCompare(b);
            //Otherwise compare by order!
            return object[a][sortOrderKey] - object[b][sortOrderKey];
        } 
        //If either one of the entries is valid, but not both as the above if statement did not catch, sort the invalid one after the valid one
        else if (object[a] != null && object[a][sortOrderKey] != null) {
            return (reverseInvalidOrder) ? 1 : -1;
        } else if (object[b] != null && object[b][sortOrderKey] != null) {
            return (reverseInvalidOrder) ? -1 : 1;
        }
        //If both are invalid, sort them by the keys!
        return a.localeCompare(b);
    });

    return keys;
}

export const getSortedEntriesBySortOrder = function(object, reverseInvalidOrder = false, sortOrderKey = SORT_ORDER_KEY) {
    let keys = getSortedKeysBySortOrder(object, reverseInvalidOrder, sortOrderKey);

    //Returns key value pairs for every sorted key from the object
    return _.map(keys, (key) => [key, object[key]]);
}

export const localizeSingleField = function(field, i18n) {
    let type = field.type;

    let dateTimeFormat = LOCALIZATION_DATE_TIME_FORMATS[type] || null;

    let isDateTime = (dateTimeFormat != null);
    let isBoolean = (isBooleanInput(type));

    //Try to get locale or return empty object to not get into error and just use user locale instead
    let localeProperties = i18n.$getCurrentProperties() || {};

    //If it is a file, never show displayValue
    if (isFilesInput(type)) {
        field.isFile = true;
        field.value = null;
    } else {
        //Otherwise process the value
        if (field.value != null){
            //If we have a date or time type convert it
            if (isDateTime) {
                try {
                //Use js internal Date object for parsing the ISO String to treat 0-padded years correctly, e.g. 0001 is not 1901!
                let localDateTime = dayjs((new Date(field.value))).millisecond(0).second(0); //Remove precision of seconds and milliseconds!
                if (localDateTime != null && localDateTime.isValid()) { //TODO Conversion of timezone needed or wanted if it is time without date? Establish everywhere!           
                    field.value = localDateTime.format(dateTimeFormat);
                }
                } catch {
                //Skip conversion to date on error!
                }
            } else if (isBoolean) {
                field.value = i18n.$t('default_interaction.' + ((field.value) ? 'yes' : 'no'));
            }
        }
    }
    
    //Always make the value to be a string!
    if (field.value != null){
        if (_.isNumber(field.value)) {
            field.value.toLocaleString(localeProperties.full_locale); //FIXME Funktioniert noch nicht
        } else {
            field.value = _.toString(field.value);
        }
    }

    //Return the new field, no matter if it was successfully localized or left as is
    return field;
}

//Recursively apply mapping functions to categories and their items. Only include keys (with the separator separating categories and names).
//previousCategoryChain is a recursion parameter, to inform the mapping functions about the current and all previous levels.
export const applyFunctionsToHierarchicalReportFields = (categories, mappingFunctions = {}, previousCategoryChain = null) => {
    let {mapCategory, mapItem} = mappingFunctions;
    
    return _.mapValues(categories, (category, categoryName) => {
        //Creates an array of all the levels so far
        let currentCategoryChain = _.concat(previousCategoryChain, [categoryName]);

        //Create a new object for this category without the sub_categories and items that get processed separately
        let newCategory = _.omit(category, ['sub_categories', 'items']);

        //If a mapping function is defined, process this category. currentCategoryChain can be used to determine if it is a sub_category and at which level
        if (mapCategory != null) newCategory = mapCategory(categoryName, category, currentCategoryChain);

        if ('items' in category) {
            //If a mapping function is defined, process this item. Otherwise just take the existing items. currentCategoryChain can be used to determine if it is in a sub_category and at which level.
            newCategory['items'] = ((mapItem != null) ? _.mapValues(category['items'], (item, itemName) => mapItem(itemName, item, currentCategoryChain)) : category['items']);
        }
    
        //If it contains sub_categories call same function with next recursion level
        if ('sub_categories' in category) {
            newCategory['sub_categories'] = applyFunctionsToHierarchicalReportFields(category['sub_categories'], mappingFunctions, currentCategoryChain);
        }

        return newCategory
    });
}

//Searches recursively through the object and returns the first find. Depth first search up to maxDepth. path is a recursion parameter.
export const findFirstKeyDeep = (object, key, maxDepth = MAX_COMPONENT_DEPTH, path = []) => {
    if (object != null) {
        for (let [objectKey, objectValue] of Object.entries(object)) {
            //Save the current path for the whole depth
            let currentPath = _.concat(path, [objectKey]);
            //If the key is the correct one, return the value and the path
            if (objectKey == key) return { value: object[key], path: currentPath };
            //Otherwise if it is an object continue down to the next level
            if (_.isPlainObject(objectValue) && path.length < maxDepth) {
                //If it can be found in this branch return it, otherwise continue with the next one
                let foundKey = findFirstKeyDeep(objectValue, key, maxDepth, currentPath);
                if (foundKey != null) return foundKey;
            }
        }
    }
    return null;
}

export function localizeReportTypeCategoryPath(pathComponent, descriptor, name, i18n, locale) {
    //If we have a null value, return a placeholder
    if (descriptor == null || descriptor == 'undefined' || descriptor == 'null') {
        if (pathComponent == 'examination_stage') return (i18n != null) ? i18n.$t('analysis.protocols.no_examination_stage') : 'No stage';
        if (pathComponent == 'sub_category') return (i18n != null) ? i18n.$t('analysis.protocols.no_sub_category') : 'No sub category';
        //Fallback, in case pathComponent is incorrect
        return '-';
    }

    //First check if name is valid and use it for translation
    if (name != null && locale != null && name[locale] != null) {
        return name[locale];
    }
    //TODO Try to get translations for all 4 levels here! Check other translation methods

    //Then take descriptor as last resort
    return descriptor;
}

/**
 * Report type index is built from the hierarchical index that consists of 4 layers and the entry types themselves in the last layer:
 * examination_stage: descriptor as key mandatory!
 *   sub_category_section: (optional) -> descriptor as key can be 'null' or 'undefined' -> Counts as no section, set in the beginning
 *      sub_category: descriptor as key mandatory!
 *        entry_type_section: (optional) -> descriptor as key can be 'null' or 'undefined' -> Counts as no section, set in the beginning
 *          entry_types: The entry types themselves, with unique identifier (independent from version) as the key and the metadata as value
 * 
 * 5 for loops are thus necessary to build an array index here with categories and sections all in order.
 * (Secions are just elements in the corresponding category separating the other elements)
 * 
 * Example for resulting index:
 * 
 *  [
 *    {
 *       descriptor: 'stage1',
 *       sub_categories: [
 *         {
 *           descriptor: 'sub_category_without_section',
 *           entry_types: [
 *             {
 *               descriptor: 'entry_type_section_a',
 *               section: true
 *             },
 *             {
 *               descriptor: 'entry_type_under_section_a',
 *               id: 'name+hash'
 *             }
 *           ]
 *         },
 *         {
 *           descriptor: 'sub_section1',
 *           section: true
 *         },
 *         {
 *           descriptor: 'sub_category_under_section1',
 *           entry_types: [
 *             {
 *               descriptor: 'entry_type_without_section',
 *               id: 'name+hash'
 *             }
 *           ]
 *         }
 *       ]
 *     }
 *   ]
 */
export function orderAndLocalizeReportTypeIndex(hierarchicalIndex, i18n, locale){
    //Array for the resulting index
    let arrayIndex = [];

    if (hierarchicalIndex != null) {
        //Take all stages (keys) and sort them
        let stageDescriptors = Object.keys(hierarchicalIndex).sort(createOrderSortFunction(null, true, locale)); //Sort alphabetically, null means no object for searching for sort order, true means sort undefined or null in the beginning //TODO In the future give object that contains a sort order for each key!

        //Go through each stage using their key and add the sub_categories (+ sections)
        for (let stageDescriptor of stageDescriptors) {
            //Get all sections (keys) (null is treated as no section) and sort them
            let subCategorySectionKeys = Object.keys(hierarchicalIndex[stageDescriptor].entries).sort(createOrderSortFunction(null, true, locale)); //Sort alphabetically, null means no object for searching for sort order, true means sort undefined or null in the beginning //TODO In the future give object that contains a sort order for each key!

            let subCategoryArray = [];

            //Go through each section to add the sub_categories within
            for (let subCategorySectionKey of subCategorySectionKeys) {
            //First, if it is a valid section add the section separating element in the array before all the sub_categories are added
            if (subCategorySectionKey != 'undefined' && subCategorySectionKey != 'null') {
                subCategoryArray.push({ 
                'descriptor': subCategorySectionKey,
                'localizedDescriptor': localizeReportTypeCategoryPath('sub_section', subCategorySectionKey, (hierarchicalIndex[stageDescriptor].entries[subCategorySectionKey] != null) ? hierarchicalIndex[stageDescriptor].entries[subCategorySectionKey].name : undefined, i18n, locale),
                'section' : true
                });
            }

            let subCategories = hierarchicalIndex[stageDescriptor].entries[subCategorySectionKey].entries;

            //Then add all sub_categories inside this section
            if (subCategories != null) {
                //Get all sub_categories (keys) and sort them
                let subCategoryKeys = Object.keys(subCategories).sort(createOrderSortFunction(null, true, locale)); //Sort alphabetically, null means no object for searching for sort order, true means sort undefined or null in the beginning //TODO In the future give object that contains a sort order for each key!
                //Go through each sub_category and add the entry_types (+sections)
                for (let subCategoryDescriptor of subCategoryKeys) {
                //Get all sections (keys) (null is treated as no section) and sort them
                let entryTypeSectionKeys = Object.keys(subCategories[subCategoryDescriptor].entries);
                
                let entryTypeArray = [];
                
                //Go through each section to add the entry_types within
                for (let entryTypeSectionKey of entryTypeSectionKeys) {
                    //First, if it is a valid section add the section separating element in the array before all the entry_types are added
                    if (entryTypeSectionKey != 'undefined' && entryTypeSectionKey != 'null') {
                    entryTypeArray.push({ 
                        'descriptor': entryTypeSectionKey,
                        'localizedDescriptor': localizeReportTypeCategoryPath('section', entryTypeSectionKey, (subCategories[subCategoryDescriptor].entries[entryTypeSectionKey] != null) ? subCategories[subCategoryDescriptor].entries[entryTypeSectionKey].name : undefined, i18n, locale),
                        'section' : true
                    });
                    }

                    let entryTypes = subCategories[subCategoryDescriptor].entries[entryTypeSectionKey].entries;

                    //Then add all entry_types inside this section
                    if (entryTypes != null) {
                    //Get all entry_types (keys) and sort them
                    let entryTypeKeys = Object.keys(entryTypes).sort(createOrderSortFunction(entryTypes, undefined, locale)); //Sort by order property and then alphabetically!
                    for (let entryTypeKey of entryTypeKeys) {
                        entryTypeArray.push({
                        'descriptor': entryTypes[entryTypeKey].descriptor,
                        'localizedDescriptor': entryTypes[entryTypeKey].descriptor, //TODO Take translation directly!!!
                        'id': entryTypeKey, //ID is hash of name and category path, unqiue and independent from version
                        'main_category': entryTypes[entryTypeKey].main_category
                        });
                    }
                    }
                }

                //Adding the created list of sub_categories (+ sections) with the entry_types and their sections inside
                subCategoryArray.push({ 
                    'descriptor': subCategoryDescriptor,
                    'localizedDescriptor': localizeReportTypeCategoryPath('sub_category', subCategoryDescriptor, (subCategories[subCategoryDescriptor] != null) ? subCategories[subCategoryDescriptor].name : undefined, i18n, locale),
                    'entry_types' : entryTypeArray
                });
                }
            }
            }

            //Adding the stage with the sub_categories and everything inside to the index
            arrayIndex.push({
            'descriptor': stageDescriptor,
            'localizedDescriptor': localizeReportTypeCategoryPath('examination_stage', stageDescriptor, (hierarchicalIndex[stageDescriptor] != null) ? hierarchicalIndex[stageDescriptor].name : undefined, i18n, locale),
            'sub_categories': subCategoryArray
            });
        }
    }

    return arrayIndex;
}