<template id="files-input">
  <div class="preview-slides-container">
    <ion-slides v-if="computedValue && computedValue.length" class="preview-slides" :options="slideOpts" :scrollbar="inputParameters.multipleFiles" :key="joinedColorCodedFiles">
      <ion-slide v-for="(media, mediaIndex) in joinedColorCodedFiles" :key="mediaIndex"> <!-- TODO Test preset files in CreateReport! EditModal works! -->
        <div :class="['preview', (disabled) ? 'disabled' : '']" :style="(media != null && media.color != null) ? `--border-color: var(${media.color})` : undefined">
          <MediaPreview class="preview-media" :type="media.mime" :mediaUrl="media.blobURL" :thumbnailUrl="media.thumbnail" @click="openGalleryModal($event, mediaIndex)"></MediaPreview>
          <ion-button class="delete-button" color="danger" shape="round" @click="openGalleryModal($event, mediaIndex, true)">
            <font-awesome-icon slot="icon-only" :icon="faMinus" />
          </ion-button>
        </div>
      </ion-slide>
    </ion-slides>
    <div v-else :class="['preview-placeholder', (disabled) ? 'disabled' : '']">{{ custom_placeholder ? custom_placeholder : i18n.$t('forms.files.record_or_select') }}</div>
  </div>

  <div slot="end" id="input-buttons">
    <div class="file-status-counts" v-if="inputParameters.multipleFiles">
      <ion-badge slot="end" color="success">{{ (separatedCompletedFiles.fileCounts.modified > 0) ? separatedCompletedFiles.fileCounts.modified : null }}</ion-badge>
      <ion-badge slot="end" color="primary">{{ (separatedCompletedFiles.fileCounts.preset > 0) ? separatedCompletedFiles.fileCounts.preset : null }}</ion-badge>
    </div>

    <input tabindex="-1" class="invisible-input" ref="fileInput" @change="editSelectedFiles($event.target.files)" v-if="inputParameters.fileType === 'image'" :name="key" type="file" :accept="(inputParameters.useStillFrame) ? 'image/*,video/*' : 'image/*'" :multiple="inputParameters.multipleFiles" capture />
    <input tabindex="-1" class="invisible-input" ref="fileInput" @change="editSelectedFiles($event.target.files)" v-else-if="inputParameters.fileType === 'video'" :name="key" type="file" accept="video/*" :multiple="inputParameters.multipleFiles" capture />
    <input tabindex="-1" class="invisible-input" ref="fileInput" @change="addSelectedFiles($event.target.files)" v-else-if="inputParameters.fileType === 'audio'" :name="key" type="file" accept="audio/*" :multiple="inputParameters.multipleFiles" capture />

    <ion-button @click.stop="captureFile" @keydown="handleSpacebar($event, ()=>$event.target.click())" :disabled="disabled">
      <ion-icon slot="icon-only" :icon="inputParameters.icon"></ion-icon>
      <font-awesome-icon v-if="inputParameters.multipleFiles" class="capture-add-icon-addition" :icon="faPlus" slot="icon-only" />
    </ion-button>
    <ion-button @click.stop="chooseFile" @keydown="handleSpacebar($event, ()=>$event.target.click())" :disabled="disabled">
      <ion-icon slot="icon-only" :icon="folderOpen"></ion-icon>
      <font-awesome-icon v-if="inputParameters.multipleFiles" class="choose-add-icon-addition" :icon="faPlus" slot="icon-only" />
    </ion-button>
  </div>
</template>

<script>
import { IonBadge, IonButton, IonIcon, IonSlides, IonSlide } from '@ionic/vue';
import { defineComponent, computed, ref, watch, onMounted } from 'vue';

import { useI18n } from '@/utils/i18n';

import { camera, videocam, mic, folderOpen } from 'ionicons/icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import { TYPE_API_MAPPINGS, MULTIPLE_FILE_TYPE_MAPPINGS } from '@/utils/report';

import { hashBlob, getFirstFrameFromVideo } from '@/utils/media';

import { handleSpacebar } from '@/utils/interaction';

import MediaPreview from '@/components/MediaPreview.vue';

import { openCameraModal, default as cameraModalComponent, parseCameraOptions } from '@/components/CameraModal.vue';
import { openGallery, default as galleryModalComponent } from '@/components/Gallery.vue';

import _ from 'lodash';

const PREFERRED_LABEL_POSITION = 'stacked';

export const isFilesInput = function(type) {
  return (type in TYPE_API_MAPPINGS['files']);
}

export const getFilesInputParameters = function(type) {
  return {
    labelPosition: PREFERRED_LABEL_POSITION,
    componentType: 'FilesInput',
    apiComponentType: TYPE_API_MAPPINGS['files'][type] || undefined,
    isFile: true,
    multipleFiles: (type in MULTIPLE_FILE_TYPE_MAPPINGS),
    disableResetButton: true
  }
}

const FilesInput = defineComponent({
  name: 'FilesInput',
  components: { IonBadge, IonButton, IonIcon, IonSlides, IonSlide, FontAwesomeIcon, MediaPreview },
  props: {
    'key': String,
    'type': String,
    'presetValue': [Array, String, Object],
    'modelValue': [Array, String, Object],
    'disabled': {
      type: Boolean,
      default: false
    },
    'captureOptions': {
      type: Object,
      default: () => {
        return {};
      }
    },
    'custom_placeholder': String
  },
  emits: ['update:modelValue', 'update:modified', 'invalidityMessage'],
  setup(props, { emit }) {
    const i18n = useI18n();


    //Map file types to their icons
    const FILE_TYPE_ICONS = {
        'image': camera,
        'video': videocam,
        'audio': mic
    }
    const CAMERA_INPUT_TYPES = ['image', 'video'];
    
    const FILE_OBJECT_SORTING_ATTRIBUTES = ['hash', 'blobURL'];
    const STATE_COLORS = {
      preset: '--ion-color-primary-text',
      modified: '--ion-color-success-shade'
    }

    //Set input parameters based on the file type and the capture device
    const inputParameters = computed(() => {
      let parameters;

      //Fallback, single image
      if (props.type == null) {
        parameters = {
          multipleFiles: false,
          fileType: 'image'
        };
      }
      //If it is a pluralized filey type, map it
      else if (props.type in MULTIPLE_FILE_TYPE_MAPPINGS) {
        parameters = {
          multipleFiles: true,
          fileType: MULTIPLE_FILE_TYPE_MAPPINGS[props.type]
        };
      }
      //Otherwise use single variant of the given type 
      else {
        parameters = {
          multipleFiles: false,
          fileType: props.type
        };
      }

      //Set camera parameters according to the fileType
      if (CAMERA_INPUT_TYPES.includes(parameters.fileType)) {
        parameters['usesCamera'] = true;

        //Set capture options for camera
        let options;
        if (props.captureOptions != null) {
          options = parseCameraOptions(parameters.fileType, props.captureOptions);

          parameters['useStillFrame'] = (options.stillFrame === true);
        }

        parameters['cameraOptions'] = options;
      } else {
        parameters['usesCamera'] = false;
        parameters['cameraOptions'] = {}; //Always add empty options as a fallback
      }
      //Map icon for the selected fileType or use folderOpen as fallback
      if (parameters.fileType in FILE_TYPE_ICONS) parameters['icon'] = FILE_TYPE_ICONS[parameters.fileType];
      else parameters['icon'] = folderOpen;

      return parameters;
    });

    const slideOpts = computed(() => {
      return{
        updateOnWindowResize: true,
        resizeObserver: true,
        zoom: false,
        autoHeight: false,
        slidesPerView: 'auto',
        allowTouchMove: inputParameters.value.multipleFiles,
        spaceBetween: 5,
        scrollbar: ((!inputParameters.value.multipleFiles) ? false : {
          el: '.swiper-scrollbar',
          draggable: true,
          hide: false
        })
      };
    });

    const fileInput = ref(null);

    const resetFileInput = function() {
      if (fileInput.value != null) fileInput.value.value = '';
    }

    //If it is a Blob (includes File objects too) convert it to an object containing URL, name (if present), mime type and hash. Also includes attribute if it is newly created and has to be revoked if removed!
    //If it is not a blob just return 
    const convertFileToObjectWithURL = function(objectValue, newFile = false) {
      if (objectValue instanceof Blob) {
        let blobURL = URL.createObjectURL(objectValue);
        let mime = objectValue.type;
        return { 
          blobURL,
          name: objectValue.name || undefined,
          mime,
          hashPromise: hashBlob(objectValue), //TODO Generate hash on server when uploading and compare. Save that as hash (without promise)
          //If it is of type video and we have a valid blob, try to get the first frame from it
          thumbnailPromise: (mime != null && blobURL != null && mime.includes('video')) ? getFirstFrameFromVideo(blobURL, mime) : undefined,
          newFile: newFile || undefined
        }
      }

      return {
        ...objectValue,
        newFile: newFile || objectValue.newFile || undefined
      };
    }

    const createFileObjectArray = function(value, newFile) {
      let inputArray;
      //Always convert to an array. If it is null use an empty array
      if (value == null) {
        inputArray = [];
      } else if (!Array.isArray(value)) {
        inputArray = [value];
      } else {
        inputArray = value;
      }

      return _.map(inputArray, (value) => convertFileToObjectWithURL(value, newFile));
    }

    const processedPresetValue = computed(() => {
      return createFileObjectArray(props.presetValue);
    });

    /**
     * Compare first by hash, then by url. Names are not compared, for better usability, not depending on users custom names.
     * If eithter the hash or the url are equal we can assume it is the same file. Otherwise we assume inequality.
     */
    const compareFileObjects = function(fileA, fileB) {
      //First try to compare their hashes
      if (fileA.hash != null && fileB.hash != null) {
        return fileA.hash === fileB.hash;
      }
      //If either hash is not found, use url instead
      if (fileA.blobURL != null && fileB.blobURL != null) {
        return fileA.blobURL === fileB.blobURL;
      }

      //If all else fails, consider them not equal
      return false;
    }

    //Waits for all hashing operations in the array to complete, sets the hash parameter of each once the promise resolves and returns the new array (always a shallow copy!)
    const completePromises = async function(fileObjectArray) {
      //Create new array for modifications
      let completedFileObjectArray = [];

      //Go through each fileObject and wait for the hashPromise if it exists
      for (let fileObject of fileObjectArray) {
        if (fileObject.hashPromise != null) {
          fileObject.hash = await fileObject.hashPromise;
        }
        delete fileObject.hashPromise; //Always delete in case it was undefined
        if (fileObject.thumbnailPromise != null) {
          fileObject.thumbnail = await fileObject.thumbnailPromise;
        }
        delete fileObject.thumbnailPromise; //Always delete in case it was undefined
        completedFileObjectArray.push(fileObject);
      }

      //Return a new array with all promises completed
      return completedFileObjectArray;
    }

    //Always returns an array, even if multiple files are not allowed. When preset is valid, return null, otherwise return undefined.
    const processOutputValue = function(value) {
      //If we have no files in the new model, return corresponding indicator
      if (value == null || value.length <= 0) {
        //If a preset value was set, then return null to signify the value change -> Used to detect a removed existing value -> Deletes all files
        if (props.presetValue !== undefined) {
          return null;
        } 
        //Without a preset value, return undefined to signify that this value was never entered
        else {
          return undefined;
        }
      }
      //If we have valid files return them. Deleting single files in the API should be handled by comparing the given hashes. Send just the hashes of preset files to save resources. API deletes all that are not present anymore!
      return value;
    }

    //Completes hashing for given model and preset arrays and return the difference (model without presets)
    //and intersection (only presets that are in model, no additional ones), basically splitting modelFiles using presetFiles.
    const getModifiedAndPresetFromModel = async function(modelFiles, presetFiles) {
      //completePromises always returns a new array, so we can modify it
      const completedModelFiles = await completePromises(modelFiles);
      //Compare with fully hashed preset fileList to determine equality
      const completedPresetFiles = await completePromises(presetFiles);

      //Get the modified files by taking the difference
      const modifiedFileList = _.differenceWith(completedModelFiles, completedPresetFiles, compareFileObjects);
      //Get all files from the model that are presets. Preset might be more than in model if a preset file gets removed! Uses the instances of the presets (first array for intersection function)!
      const presetFileList = _.intersectionWith(completedPresetFiles, completedModelFiles, compareFileObjects);

      return {
        completedModel: completedModelFiles,
        completedPreset: completedPresetFiles,
        modified: modifiedFileList,
        preset: presetFileList
      };
    }

    const computedValue = computed({
      get: () => {
        return createFileObjectArray(props.modelValue);
      },
      set: (newFiles) => {
        //Only fully hashed file lists are returned, it never returns a file object with promise present!
        //Wait for the hashing to be complete and filter out duplicates - Each file is only added once, either by hash or by objectURL (just applies to preset files)
        getModifiedAndPresetFromModel(newFiles, processedPresetValue.value).then((fileLists) => {
          //Use modified and preset parts of the new model separately, to prefer presets over new files to reduce server overhead
          //Union applies the arrays from left to right, so presets are kept preferrably when it matches with a new one. Returned array always contains only unique values.
          return _.unionWith(fileLists.preset, fileLists.modified, compareFileObjects);
        })
        .then((newUniqueFiles) => {
          emit('update:modelValue', processOutputValue(newUniqueFiles)); 
        });
      }
    });

    //Add files to the current fileList, if multiple are allowed or replace selection - Second optional parameter allows removing files first, before adding the new ones; Must be an array
    const addSelectedFiles = function(fileList, replaceFileList = []){ //If replaceFileList is empty, nothing gets removed!
      //Filter out all invalid fileTypes!
      let filteredFileList = _.filter(Array.from(fileList), (file) => {
        return file.type != null && file.type.includes(inputParameters.value.fileType);
      });
      //Convert to array of fileObjects with URLs set
      let newFileList = createFileObjectArray(filteredFileList, true); //Mark all new files with a flag
      
      if (newFileList != null && newFileList.length > 0) { //Only update, if any files are to be set!
        if (inputParameters.value.multipleFiles && Array.isArray(computedValue.value)){
          //computedValue is always an array! - First remove all values to be replaced (if any) and then add the new file list
          computedValue.value = _.differenceWith(computedValue.value, replaceFileList, compareFileObjects).concat(newFileList);
        } else { //No need to remove any, as all files get replaced anyway!
          computedValue.value = newFileList;
        }
      }
      resetFileInput();

      //Return the files that have been added - Do not use outside of checking if a file was modified when editing again
      return newFileList;
    }

    //Saves a list of the modified and preset files separately (with hashing completed) and the respective counts
    const separatedCompletedFiles = ref({
      lists: {
        //Both lists are sorted by FILE_OBJECT_SORTING_ATTRIBUTES
        modified: [],
        preset: []
      },
      fileCounts: {
        modified: 0,
        preset: 0,
        presetNotInModel: 0
      }
    });

    //Create a sorted list of files, with modified first, then preset and with attribute color set to show a specific color for each state
    const joinedColorCodedFiles = computed(() => {
      const colorCodedModifiedFiles = _.map(separatedCompletedFiles.value.lists.modified, (value) => {
        //Create a copy of fileObject to set color only here
        return {
          ...value,
          color: STATE_COLORS['modified']
        }
      });
      const colorCodedPresetFiles = _.map(separatedCompletedFiles.value.lists.preset, (value) => {
        //Create a copy of fileObject to set color only here
        return {
          ...value,
          color: STATE_COLORS['preset']
        }
      });

      return _.concat([], colorCodedModifiedFiles, colorCodedPresetFiles);
    });

    //Used to update and notify about changes in the files or presets
    const updateFileModels = function(newFiles, newPresetFiles) {
      getModifiedAndPresetFromModel(newFiles, newPresetFiles).then((fileLists) => {
        //Update the saved file list and counts
        separatedCompletedFiles.value.lists.modified = _.sortBy(fileLists.modified, FILE_OBJECT_SORTING_ATTRIBUTES); //All files without preset
        separatedCompletedFiles.value.lists.preset = _.sortBy(fileLists.preset, FILE_OBJECT_SORTING_ATTRIBUTES); //Preset files
        separatedCompletedFiles.value.fileCounts.modified = fileLists.modified.length;
        separatedCompletedFiles.value.fileCounts.preset = fileLists.preset.length;
        //All files that are presets but are not in the intersection with the model, are presets that have been deleted
        separatedCompletedFiles.value.fileCounts.presetNotInModel = (fileLists.completedPreset.length - fileLists.preset.length);

        //emit modified attribute change with the new state. Either we have files that were not in the preset, or files from the preset are not in the model anymore --> modified
        emit('update:modified', (fileLists.modified.length > 0 || separatedCompletedFiles.value.fileCounts.presetNotInModel > 0));
      });
    }

    /**
     * Wait for changes in the model or the preset, complete all the hashing operations, update the modified files and emit the modified flag if any are modified
     */
    watch([computedValue, processedPresetValue], ([newFiles, newPresetFiles]) => {
      updateFileModels(newFiles, newPresetFiles);
    });

    const openCamera = async function(selectedFile, editIndex = null, editCount = null, startEditFromModal = false) {
      return openCameraModal(cameraModalComponent, {
        ...inputParameters.value.cameraOptions,
        selectedFile,
        editIndex,
        editCount
      },
      ((editCount > 0 && editIndex > 1) || startEditFromModal), //Disable enter animation, when we edit more than one file and it is not the first one to better transition to it. Or if we explicitly has a modal open beforehand.
      (editCount > 0 && editIndex < editCount), //Slow down leave animation, if it is more than one edited and not the last one to better transition to the next one
      true //Return before dismiss and start processing, not after animation, data is already set!
      ).then((capturedBlobData) => {
        if (capturedBlobData.data != null) {
          let filePromises = [];
          let capturedBlobs;
          if (Array.isArray(capturedBlobData.data)) {
            capturedBlobs = capturedBlobData.data;
          } else {
            capturedBlobs = [capturedBlobData.data];
          }

          //Convert all blobs to file objects to get their hashes once!
          for (let blob of capturedBlobs) {
            let blobURL = blob.url;
            filePromises.push(
              fetch(blobURL)
                .then(fetchResult => fetchResult.blob())
                .then(blobFile => {
                  return {
                    url: blobURL,
                    file: (new File([blobFile], props.key || 'capturedFile', { type: blob.type || blobFile.type })), //Use the given mimeType or read it from the blob
                  };
                })
                .catch((error) => {
                  //Return null if an error occurs
                  console.error(error);
                  return null;
                })
            );
          }
          
          return Promise.all(filePromises).then((fileArray) => {
            //Only return files for which no error occured!
            return _.filter(fileArray, (fileObject) => fileObject != null);
          });
        }
      });
    }

    const modifySingleFile = async function(fileObject) {
      if (fileObject != null) {
        let newFiles = await openCamera(fileObject, null, null, true); //Open camera to edit the existing file and wait for the new files. No enter animation, as we come from the gallery modal.
        if (newFiles != null && Array.isArray(newFiles) && newFiles.length > 0) { //Only consider deleting, if any files are returned
          //Filter out the given fileObject from the list of new files --> File given back without editing, no need to re-add it or delete it!
          let filteredNewFiles = _.filter(newFiles, (newFileObject) => newFileObject.url != fileObject.blobURL);
          //Only continue, if any files are remaining
          if (filteredNewFiles.length > 0) {
            //Add the remaining files and remove the fileObject that is present -> As a file got returned, the old fileObject has been replaced!
            addSelectedFiles(_.map(filteredNewFiles, 'file'), [fileObject]);
          }
        }
      }
    }

    //Open editor of camera dialog, if we edit before adding
    const editSelectedFiles = async function(fileList){
      let fileArray = Array.from(fileList);
      let fileCount = fileArray.length;

      if (fileCount > 0) {
        for (let fileIndex = 0; fileIndex < fileCount; fileIndex++) {
          //Wait until the previous modal is about to be closed before opening the next one. Reduces the time in between closing and opening the new one. Also waits for data to be added to prevent race condition when adding fast!
          let newFiles = await openCamera(fileArray[fileIndex], fileIndex + 1, fileCount);
          if (newFiles != null) { //Add files, if it returns a valid file list
            addSelectedFiles(_.map(newFiles, 'file'));
          }
        }
      }

      resetFileInput();
    }

    const chooseFile = function(){
      fileInput.value.removeAttribute('capture');
      fileInput.value.click();
    }

    const captureFile = function(){
      if (inputParameters.value.usesCamera) {
        openCamera().then((newFiles) => {
          if (newFiles != null) { //Add files, if it returns a valid file list
            addSelectedFiles(_.map(newFiles, 'file'));
          }
        });
      } else {
        fileInput.value.setAttribute('capture', true);
        fileInput.value.click();
      }
    }

    const deleteFileObject = function(fileObject) {
      if (fileObject == null) return;
      //Set file list, difference - without the element to be removed
      computedValue.value = _.differenceWith(computedValue.value, [fileObject], compareFileObjects);
    }

    const openGalleryModal = function(event, index, openDeleteConfirmation = false) {
      let offset;
      let elementHeight;
      if (event != null && event.target != null){
        let element = event.target;
        //Search for the parentElement that is the preview container to use it. Stops at the root.
        while (!element.classList.contains('preview') && (element = element.parentElement));

        //If we found the container, animate the modal from there
        if (element != null) {
          let elementBounds = element.getBoundingClientRect();

          offset = {
            x: (elementBounds.left + elementBounds.right) / 2,
            y: (elementBounds.top + elementBounds.bottom) / 2
          };

          elementHeight = elementBounds.height;
        }
      }

      openGallery(galleryModalComponent, joinedColorCodedFiles.value, index, {
        allowDelete: true,
        allowEdit: true,
        openDeleteConfirmation,
        deleteCallback: (fileIndex, fileObject) => deleteFileObject(fileObject), //Prefer fileObject here, because index could be wrong in between instances!
        editCallback: (fileIndex, fileObject) => modifySingleFile(fileObject) //Prefer fileObject here, because index could be wrong in between instances!
      },
      offset,
      elementHeight);
    }

    // Watch for file changes and revoke blobURLs of newFiles that have been removed, i.e. are not in the new list anymore but were in the old file list
    watch(computedValue, (newFiles, oldFiles) => {
      const changedFiles = _.differenceWith(oldFiles, newFiles, compareFileObjects); //Get previous files without the new files. It just includes all the removed files.
      if (Array.isArray(changedFiles) && changedFiles.length > 0) {
        //Go through all files that have been removed somehow - including when removed through cancelling! Revoke the blobURL of all removed media, if it is a newFile. If the URL is not a objectURL it fails silently.
        for (let file of changedFiles) {
          if (file.newFile === true && file.blobURL != null) {
            URL.revokeObjectURL(file.blobURL);
            delete file.blobURL;
          }
        }
      }
    });

    //Set initial model info from the data on mount. Watchers look for changes and update them accordingly.
    onMounted(() => {
      setTimeout(() => updateFileModels(computedValue.value, processedPresetValue.value), 200); //Defer update to the view to initialize the slider only when visible. Necessary with width: max-content as width changes after initialization! Timeout is optional but ensures some waiting time!
    });
    
    return { STATE_COLORS, i18n, inputParameters, slideOpts, fileInput, computedValue, separatedCompletedFiles, joinedColorCodedFiles, addSelectedFiles, editSelectedFiles, chooseFile, captureFile, openGalleryModal, handleSpacebar, folderOpen, faMinus, faPlus };
  }
});

export default FilesInput;
</script>

<style scoped>
#input-buttons {
  position: relative;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  display: inline-flex;
  margin: 0px;
  align-items: flex-start;
  --button-size: var(--custom-button-size, 42px);
  --button-padding: var(--custom-button-padding, 0px);
  --button-spacing: var(--custom-button-spacing, 8px);
}

#input-buttons > ion-button {
  height: var(--button-size);
  max-height: var(--button-size);
  width: var(--button-size);
  max-width: var(--button-size);
  margin-top: 0px;
  margin-bottom: 0px;
  --padding-start: var(--button-padding);
  --padding-end: var(--button-padding);
  --padding-top: var(--button-padding);
  --padding-bottom: var(--button-padding);
  font-size: 0.9em;
}

#input-buttons > *:not(:first-child) {
  margin-left: var(--button-spacing);
}

.invisible-input {
  /* Avoid reflow of other elements */
  position: absolute;
  /* Show any invalidity popups in the center of the element */
  left: 50%;
  top: 50%;
  /* Hide any possibly visible parts of the input */
  background: var(--ion-background-color, white);
  border: none;
  opacity: 0;
  z-index: -10;
  /* Prevent user clicks on element, that would open the dialog twice */
  pointer-events: none;
  /* Make the element as small as possible, so it still can show the validity popup */
  width: 1px;
  height: 1px;
}

.file-status-counts {
  position: absolute;
  right: 2px;
  bottom: 10px;
}

.file-status-counts > ion-badge {
  margin-left: 10px;
  height: 1.5em;
}

.delete-button {
  width: 2em;
  height: 2em;
  margin: 0px;
  position: absolute;
  top: 0em;
  right: 0em;
  --padding-start: 0px;
  --padding-end: 0px;
  z-index: 100;
}

.preview-slides-container {
  width: 100%;
  position: relative;
}

.preview-slides {
  position: relative;
  max-height: 100%;
  max-width: 100%;
}

.preview-slides ion-slide {
  height: 100%;
  width: max-content; /* Adapts the width to the preview content --> Always maximum height in ion-slides container */
  min-width: 3em;
  max-width: 10em;
}

.preview {
  position: relative;
  padding: 0.75em 0.75em 15px 0px;
  display: block;
}

.preview-media {
  --height: 4em;
  --outer-border-width: 2px;
  --border-radius: 6px;
}

.preview-placeholder {
  opacity: var(--placeholder-opacity, 0.5);
  margin-top: 10px;
  margin-bottom: 15px;
  /* Prevent text selection in placeholder */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.preview-placeholder.disabled {
  opacity: 0.3;
}

.preview.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.preview.disabled > * {
  pointer-events: none;
}

.capture-add-icon-addition, .choose-add-icon-addition {
  position: absolute;
  font-size: 14px;
  border-radius: 50%;
  background: var(--background);
}

.capture-add-icon-addition {
  margin-bottom: 24px;
  margin-left: 24px;
}

.choose-add-icon-addition {
  margin-bottom: 24px;
  margin-left: 24px;
}

</style>