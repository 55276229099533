import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '@/store'
import Tabs from '@/views/Tabs.vue'
import TrackInfect from '@/views/TrackInfect.vue'

import { INVERSE_QR_URL_PATH_MAPPING } from '@/components/composables/QR';

import _ from 'lodash';

//Define all used side menu components here
import Tracking from '@/views/Tracking.vue'
import SkeletonPointSelect from '@/views/Tools/SkeletonPointSelect.vue'
import InfectionMap from '@/views/TrackInfect/Map.vue'

const smallScreenQuery = window.matchMedia("(max-width: 992px)");

const routes = [
  {
    path: '/',
    redirect: '/health'
  },
  {
    path: '/login',
    name: 'login',
    meta: { noAuthenticationRequired: true, hideWhenAuthenticated: true },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: { noAuthenticationRequired: true, hideWhenAuthenticated: true },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: { noAuthenticationRequired: true, hideWhenAuthenticated: true },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/set-password',
    name: 'set-password',
    meta: { noAuthenticationRequired: true, hideWhenAuthenticated: true },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/health/',
    component: Tabs,
    meta: { sideMenuComponent: Tracking, sideMenuMediaQuery: smallScreenQuery }, //Only needed in parent route, is inherited
    children: [
      {
        path: '',
        redirect: '/health/animals'
      },
      {
        path: 'animals',
        name: 'subject-overview',
        component: () => import('@/views/AnimalManagement.vue'),
        beforeEnter: (to, from, next) => {
          let selectedAnimal = store.getters['horses/getSelectedHorse'];

          //If an animal was before selected to analyse/view, select it again, if coming back from another tab
          if (selectedAnimal != null) {
            //Check if we came from the same tab, then reset the selected horse
            if (_.startsWith(from.fullPath, '/health/animals')) {
              store.dispatch('horses/updateSelectedHorse', null);
            } else { //From another tab not matching this route, we move to the animal view with the selected id
              next({ name: 'view-animal', params: { 'animalId': selectedAnimal } });
              return;
            }
          }
          //If nothing matches, just continue
          next();
        }
      },
      {
        path: 'animals/:animalId',
        name: 'view-animal',
        component: () => import('@/views/Analysis.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          //If we enter this view, set the selected animal to this id in store
          store.dispatch('horses/updateSelectedHorse', to.params['animalId']);
          next();
        }
      },
      {
        path: 'analysis',
        name: 'tracking',
        component: Tracking,
        beforeEnter: (to, from, next) => {
          if (!smallScreenQuery.matches) next({path: '/health/animals'}); //Do not allow navigation on big screen, because it is shown on the left
          else next();
        }
      },
      {
        path: 'analysis/report/:id',
        name: 'view-report',
        component: () => import('@/views/Analysis/ViewReport.vue'),
        props: true
      },
      {
        path: 'analysis/reports/:connection_id',
        name: 'view-connected-reports',
        component: () => import('@/views/Analysis/ViewReport.vue'),
        props: true
      },
      {
        path: 'analysis/report/create/:types',
        name: 'create-report',
        meta: { sideMenuComponent: undefined, sideMenuMediaQuery: undefined, hideTabNavigation: true },
        component: () => import('@/views/Analysis/CreateReport.vue'),
        props: true
      },
      {
        path: 'analysis/report/update/:id',
        name: 'update-report',
        meta: { sideMenuComponent: undefined, sideMenuMediaQuery: undefined, hideTabNavigation: true },
        component: () => import('@/views/Analysis/CreateReport.vue'),
        props: true
      },
      {
        path: 'analysis/reports/update/:connection_id',
        name: 'update-connected-reports',
        meta: { sideMenuComponent: undefined, sideMenuMediaQuery: undefined, hideTabNavigation: true },
        component: () => import('@/views/Analysis/CreateReport.vue'),
        props: true
      },
      {
        path: 'encyclopedia',
        name: 'encyclopedia',
        component: () => import('@/views/Encyclopedia.vue'),
        props: true
      }
    ]
  },
  {//FIXME Greift irgendwie schon der Parent auf die Kamera zu, wenn wir in ai-eye drin waren
    path: '/analysis/ai-eye', //TODO Nur die Navigation erlauben, wenn registriert, damit AGB angenommen werden können? Dann Analyse auch nur zulassen, wenn Anfrage von registriertem Nutzer kommt
    //TODO Oder bei jeder Analyse annehmen der AGB und der Nutzungsrechte und Verständniserklärung, dass keine Diagnose ist fordern. Für registrierte Nutzer nicht, da die das einmal festlegen und dann gespiechert wird.
    //TODO Könnte man lösen indem man einen Guest Zugang macht, der Limited Access auf die API und Co. hat aber trotzdem als authenticated zählt. Zusätzliches Property irgendwie einfügen.
    //TODO Oder doch anzeigen, wenn man nicht authenticated ist. Könnte aber für angemeldete Nutzer verwirrend sein, die in dem Moment rausfliegen, wenn sie auf so eine Seite gehen.
    component: () => import('@/views/Analysis/AIEye.vue')
  },
  {
    path: '/tools',
    name: 'tools',
    meta: { needsPermission: 'Tools' },
    component: () => import('@/views/Tools.vue')    
  },
  {
    path: '/tools/label-skeleton',
    meta: { sideMenuComponent: SkeletonPointSelect, needsPermission: 'Horse_Pose_Tools' },
    component: () => import('@/views/Tools/LabelSkeleton.vue')
  },
  {
    path: '/tools/analyse-skeleton',
    meta: { needsPermission: 'Horse_Pose_Tools' },
    component: () => import('@/views/Tools/AnalyseSkeleton.vue')
  },
  {
    path: '/tools/design-report',
    meta: { needsPermission: 'Can_Design_Entry_Types' },
    component: () => import('@/views/Tools/DesignReport.vue')
  },
  {
    path: '/tools/upload-reports',
    meta: { needsPermission: 'Can_Edit_Entry_Types' },
    component: () => import('@/views/Tools/UploadReports.vue')
  },
  {
    path: '/tools/upload-encyclopedia',
    meta: { needsPermission: 'Content_Editor' },
    component: () => import('@/views/Tools/UploadEncyclopedia.vue')
  },
  {
    path: '/general-feedback',
    name: 'general-feedback',
    component: () => import('@/views/GeneralFeedback.vue')
  },
  {
    path: '/link/:type/:id',
    name: 'link',
    beforeEnter: (to, from, next) => {
      //TODO Could always forward the code to a modal to handle unassigned codes!
      if (to != null && to.params != null && to.params.type != null && to.params.id != null) {
        let type = INVERSE_QR_URL_PATH_MAPPING[to.params.type];
        if (type != null) {
          switch (type) {
            case 'animal': {
              let animal = store.getters['horses/getHorseById'](to.params.id);
              
              if (animal != null) {
                next({ name: 'view-animal', params: { 'animalId': animal.id } });
                return;
              }
              break;
            }
            
            case 'location': //Handle same way - Fallthrough
              store.commit('horses/setGroupByStatusState', false);
              next({path: '/health/animals', hash: `#${to.params.id}`});
              return;
            case 'case-status':
              store.commit('horses/setGroupByStatusState', true);
              next({path: '/health/animals', hash: `#${to.params.id}`});
              return;

            default:
              break;
          }
        }
      }
      
      //TODO Check If from is empty, maybe go to health, otherwise return false and go back?
      next({path: '/health/animals'}); //Never allow routing, always move somewhere else if not caught before!
    }
  },
  {
    path: '/track-infect',
    component: TrackInfect,
    meta: { sideMenuComponent: InfectionMap, sideMenuMediaQuery: smallScreenQuery },
    children: [
      {
        path: '',
        redirect: '/track-infect/map'
      },
      {
        path: 'map',
        name: 'infectionMap',
        component: InfectionMap,
        beforeEnter: (to, from, next) => {
          if (!smallScreenQuery.matches) next({path: '/track-infect/info'}); //Do not allow navigation on big screen, because it is shown on the left
          else next();
        }
      },
      {
        path: 'info',
        component: () => import('@/views/TrackInfect/Info.vue')
      },
      {
        path: 'discussion',
        component: () => import('@/views/TrackInfect/Discussion.vue')
      }
    ]
  },
  { //Catch all other routes, 404, redirect to homepage
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters['auth/isAuthenticated'] === true){
    //Catch the case where an authenticated user gets redirected to e.g. login or register without a redirect
    if (to.meta.hideWhenAuthenticated){
      next('/');
    } else if (to.meta.needsPermission != null) {
      if (store.getters['auth/hasPermission'](to.meta.needsPermission)) {
        next();
      } else {
        next('/');
      }
    } else {
      next();
    }
  } else { //User is not authenticated
    if (to.meta.noAuthenticationRequired) { //Correct navigation to login after authentication change i.e. logout or a page where no authentication is needed, do not save where they logged out for a fresh start on login
      next();  
    } else { //Tried to navigate without permission - Prevent further navigation if not logged in
      next({ name: 'login', query: { 'redirect': to.fullPath } }); //TODO Do not set redirect if it equals the full path (match) of home
    }
  }
});

export default router
