import axios from 'axios';
import { Drivers, Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
//memoryDriver is Fallback option to save to RAM in case the memory in other drivers is not enough. Gets deleted on app restart or when browser needs memory!
import memoryDriver from 'localforage-memoryStorageDriver';

import { default as dayjs } from '@/utils/dayjs';
import { decipherApiError } from '@/utils/error';
import { mergeWithArraysOfValuesUnionKeyOrEquals } from '@/utils/report';

//To create reactive references that vue can use for listening to effects
import { ref, watch } from 'vue';

import _ from 'lodash';

export const apiTimeout = process.env.VUE_APP_BACKEND_API_TIMEOUT;
export const apiBaseURL = process.env.VUE_APP_BACKEND_API_URL;

const API_OK_STATUS_CODES = [200];

const FINISHED_WITHOUT_ID_INDICATOR = 'FINISHED_WITHOUT_ID';

//Instance is always the same for every import!
const apiInstance = axios.create({ 
  baseURL: apiBaseURL,
  timeout: apiTimeout
});

//Promise chain to not load multiple drivers at once
var driverLoadPromise = Promise.resolve();
const definedDrivers = [];

const calculateFileUploadTimeout = (totalFileSize) => {
  //Calculate fileSize in MB and assume a Minimum of 0,05MB/s = 20000ms/MB + add the regular timeout for text 
  return (Math.round((totalFileSize / (1024 * 1024))) * 20000) + parseInt(apiTimeout);
};

export const createStorage = function(config, requiredDrivers = [], changeListener = null) {
  //Return null on empty config - Fallback should never occur!
  if (config == null) return {};

  //Create storage instance inside of an object to add custom 
  const storageObject = {
    config,
    instance: new Storage(config),
    //Call each function through a proxy function that checks and waits until the instance is ready. Each operation that modifies the storage calls changeListener, if it exists.
    set: function(key, value) {
      return this.ready.then((readyInstance) => readyInstance.set(key, value)).then((result) => {
        if (changeListener != null) changeListener('set', key, value);
        return result;
      });
    },
    get: function(key) {
      return this.ready.then((readyInstance) => readyInstance.get(key));
    },
    remove: function(key) {
      return this.ready.then((readyInstance) => readyInstance.remove(key)).then((result) => {
        if (changeListener != null) changeListener('remove', key);
        return result;
      });
    },
    clear: function() {
      return this.ready.then((readyInstance) => readyInstance.clear()).then((result) => {
        if (changeListener != null) changeListener('clear');
        return result;
      });
    },
    length: function() {
      return this.ready.then((readyInstance) => readyInstance.length());
    },
    keys: function() {
      return this.ready.then((readyInstance) => readyInstance.keys());
    },
    forEach: function(iterator) {
      return this.ready.then((readyInstance) => readyInstance.forEach(iterator));
    }
  };

  //Define the drivers that need to be added, before initializing the storage, after all previous drivers were loaded
  let driverPromise = driverLoadPromise.then(async () => {
    for (let driver of requiredDrivers) {
      let driverName = driver._driver;
      //Check with the driver name to add each driver just once
      if (!(definedDrivers.includes(driverName))) {
        await storageObject.instance.defineDriver(driver);
        definedDrivers.push(driverName);
      }
    }
  });

  driverLoadPromise = driverPromise;

  //Initialize instance (create DB) and get the used driver once initialization is finished
  storageObject.ready = driverPromise.then(() => storageObject.instance.create()).then(() => {
    storageObject.driver = storageObject.instance.driver;
    //Call initial load to trigger any reactive effects, if changeListener is set
    if (changeListener != null) changeListener('load');
    return storageObject.instance;
  }).catch((error) => {
    storageObject.driver = null;
    throw `Error loading storage driver for ${config.name}: ${error}`;
  });

  return storageObject;
} //TODO Save the storage drivers too when creating LOG!

//TODO Are caches emptied when personal data is deleted? Should they be emptied or is it already handled by calling functions (logout, etc...)?

// Defines a list of all properties that are necessary for the status. They get extracted from the queued request data.
const STATUS_STORAGE_PROPERTIES = ['url', 'method'];
// Defines a list of additional data that gets added to the status and has to be extracted again when loading
const STATUS_STATE_PROPERTIES = ['stoppedRetry', 'dataLost', 'finishedID'];
// Defines a list of metadata that is only needed for status and gets stripped before uplaod
const STATUS_METADATA = ['dependencies', 'user', 'id', 'fetchNewerForAnyPath', 'detail'];
const STATUS_KEY_SEPARATOR = 'U'; //Separates user id and index for that user

/**
 * Stores metadata for sepcific routes, like e.g. last fetch time
 */
const routeMetadata = {
  storage: createStorage({ 
      name: '__routemetadata',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB, CordovaSQLiteDriver._driver, memoryDriver._driver] //Prefer localStorage for quick access!
    },
    [CordovaSQLiteDriver, memoryDriver]
  ),
  get: async function(route) {
    if (route == null) return null;
    return this.storage.get(route).catch(() => null);
  },
  getMetadataAttribute: async function(route, attribute) {
    let metadata = await this.get(route);
    if (metadata != null && attribute != null) {
      
      return metadata[attribute];
    }
  },
  //update only changed attributes in metadata
  update: async function(route, newMetadata) {
    if (route == null || newMetadata == null) return null;

    let previousMetadata = await this.storage.get(route).catch(() => null);

    return await this.storage.set(route, _.assign(previousMetadata || {}, newMetadata));
  },
  clear: async function(forSpecificRoutes) {
    if (Array.isArray(forSpecificRoutes)) {
      for (let route of forSpecificRoutes) {
        await this.storage.remove(route).catch(() => null);
      }
    }
    return this.storage.clear();
  }
}

/**
 * Stores the upload status and the queued data in two separate storages and abstracts them to add and remove from both, as well as get status and the data separately on request
 */
const upload = {
  //Create a chain of promises as a mechanism to asynchronously upload all in order
  uploadPromiseChain: Promise.resolve(), //Create a resolved promise as a start for the chain
  //Create a promise as a concurrency mechanism to not start a new upload chain, while one is already running
  currentUploadPromise: Promise.resolve(), //Create a resolved promise as an initial check
  //Create a promise as a concurrency mechanism to not modify the status or queue while another one is still updating
  statusModificationPromise: Promise.resolve(),

  /**
   * All requests are queued together with their file under the same key as the uploadStatus.
   * When loading keep in mind to only load the values when needed, as they might contain large files.
   * May in rare cases contain orphans, when the status is already removed. In this case they are reloaded and retried on the next app start.
   * Uploading files takes priority to never loose data, even if it might have already been uploaded and just not fully cleared from the queue!
   */
  queueStorage: createStorage({ 
      name: '__uploadqueue',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage, memoryDriver._driver] //SQLite does not support blob storage very well! Use IndexedDB for now! //TODO Probably need to save them in separate columns!
    },
    [CordovaSQLiteDriver, memoryDriver]
  ), //TODO Warn user that reports are only uploaded, if the App stays open until completed, if it is a memoryDriver. Make flag in store that can be checked on upload and raise a warning!

  //Store the in memory version of the status in a reactive object to propagate changes
  status: ref(),

  computedSortedStatusGroupedByUserAndRoute: ref(),

  computedPromise: null,

  /** 
   * Status is kept in storage and memory, inferred from the request data that is saved in the queue.
   * Storage is for reloading when closed and only contains the parameters that don't change. Additionally to queueStorage to ensure faster loading!
   * Operations, promises, etc. are handled in memory during runtime.
   */
  statusStorage: createStorage({
      name: '__uploadstatus',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage] //SQLite does not support blob storage very well! Use IndexedDB for now! //TODO Probably need to save them in separate columns!
    },
    [CordovaSQLiteDriver]
  ),
  

  /* Helper function to strip metadata fields and separate files from request */
  stripMetadataAndSplitFiles: function(requestConfig) {
    if (requestConfig == null) return null;

    let files = requestConfig.files;
    let metadata = _.pick(requestConfig, STATUS_METADATA);

    //Strip all the metadata fields from the request and give back files and metadata separately
    return {request: _.omit(requestConfig, ['files'].concat(STATUS_METADATA)), files, ...metadata};
  },
  /* Helper function to convert queue request config to FormData (only if files are present - otherwise leaves request data untouched) */
  convertToFormData: function(requestWithFilesAndMetadata) {
    if (requestWithFilesAndMetadata == null || requestWithFilesAndMetadata.request == null || requestWithFilesAndMetadata.request.data == null) throw 'Invalid request cannot be converted to FormData';
    //Copy the request to potentially modify it
    let newRequest = {...requestWithFilesAndMetadata.request};

    let totalFileSize = 0;

    //If files exist, convert everthing to FormData
    if (requestWithFilesAndMetadata.files != null && Object.keys(requestWithFilesAndMetadata.files).length > 0) {
      let newData = new FormData();

      newData.append('data', JSON.stringify(newRequest.data));

      //Go through each file and add it to FormData
      for (let [path, files] of Object.entries(requestWithFilesAndMetadata.files)) {
        //If multiple files are uploaded, multiple appends with the same name key are performed
        //Always make an array out of files, and append each one of them (If it is a single file, just one append is performed)
        let fileArray = ((Array.isArray(files)) ? files : [files]);

        for (let file of fileArray) {
          newData.append(`files.${path}`, file, file.name);
          totalFileSize += file.size;
        }
      }

      newRequest.data = newData;
    }
    //If no files, just leave data as is, newRequest is not modified!

    return {request: newRequest, totalFileSize};
  },
  /* Helper function to convert queue request config to object as if it was a response from API, files are applied in correct places */
  convertToResponse: function(requestWithFilesAndMetadata) {
    if (requestWithFilesAndMetadata == null || requestWithFilesAndMetadata.request == null || requestWithFilesAndMetadata.request.data == null) throw 'Invalid request cannot be converted to response';
    //Copy the data to convert it
    let data = _.cloneDeep(requestWithFilesAndMetadata.request.data);

    //If files exist, apply them to the data
    if (requestWithFilesAndMetadata.files != null && Object.keys(requestWithFilesAndMetadata.files).length > 0) {
      //Go through each file and add it to data at the correct path
      for (let [path, files] of Object.entries(requestWithFilesAndMetadata.files)) { //TODO Check how it behaves with multiple files!!!!!
        //Save flag if it was multiple files to set either array or single value in resulting object!
        let multipleFiles = Array.isArray(files);
        //Always make an array out of files for processing
        let fileArray = ((Array.isArray(files)) ? files : [files]);

        fileArray = _.map(fileArray, (file) => {
          return {
            name: file.name,
            mime: file.type,
            blobURL: URL.createObjectURL(file) //TODO Leaks memory, if never removed again?
            //TODO Load thumbnail for videos here too?
          };
        })

        _.set(data, path, (multipleFiles ? fileArray : fileArray[0]));
      }
    }
    //If no files, just leave data as is

    return data;
  },

  /* Get the data from the queue for the given key */
  get: async function(key) {
    return this.queueStorage.get(key).then((requestConfig) => {
      if (requestConfig == null) {
        throw 'Data for upload not found';
      }

      return this.stripMetadataAndSplitFiles(requestConfig);
    });
  },

  /* Helper function to append all the promises and state info that changes to the in memory copy of the status */
  appendInMemoryInfoToStatus: function(statusObject, initialProgress = 0) {
    //Move completion function that resolves the completion promise outside
    let completeUpload;
    //Add a completion promise that can be listened for. Resolves once the upload is fully complete with the new id!
    let completionPromise = new Promise((resolve) => {
      completeUpload = function(finishedID, completeResponse) {
        resolve({
          ...completeResponse,
          id: finishedID
        });
      };
    })
    return {
      ...statusObject,
      progress: initialProgress,
      completionPromise,
      completeUpload
    }
  },
  /* Helper function to load all items and extract only the required properties for status from the status storage or the queue storage */
  loadStatusProperties: async function(statusOrQueueStorage, omitKeys = []) {
    let newStatus = {};
    let storageKeys = await statusOrQueueStorage.keys();

    //Remove all keys that should be omitted
    _.pullAll(storageKeys, omitKeys);

    //Loop through all keys that remain
    for (let key of storageKeys) {
      //Load every status or queue object into a temporary object and continue processing it, if not null. On error for this key, skip it and try the next one.
      let value = await statusOrQueueStorage.get(key).catch(() => null);
      //Only pick the status properties that are needed - initial progress is -1 for all the uploads that have been loaded after app restart (100 if it is completed) - Catches edge case where app restarts during upload
      if (value != null) newStatus[key] = this.appendInMemoryInfoToStatus(_.pick(value, _.concat(STATUS_STORAGE_PROPERTIES, STATUS_STATE_PROPERTIES, STATUS_METADATA)), (value.finishedID != null) ? 100 : -1);
    }

    return newStatus;
  },
  /* Removes all status objects from memory, storage and queue, if they are completed and not dependencies of any other uplaods. If called from outside initialization, the calling function needs to wait for initialization */
  removeCompletedStatus: async function() {
    if (this.status.value != null) {
      try {
        //Create objects to store finished and unfinished uploads separately
        let finishedDependencies = {};
        let unfinishedDependencies = {};

        for (let [key, statusObject] of Object.entries(this.status.value)) {
          //For every status create a list of (potentially non-unique) dependencies without the paths 
          let dependencyArray = (statusObject.dependencies != null) ? Object.values(statusObject.dependencies) : [];
          
          //Add the dependencies to the corresponding object based on if they have a finishedID set or not
          if (statusObject.finishedID != null) {
            finishedDependencies[key] = dependencyArray;
          } else {
            unfinishedDependencies[key] = dependencyArray;
          }
        }

        //Get all the dependencies from uploads that have not finished yet in a single flat array
        let allUnfinishedDependencies = _.uniq(_.flatten(Object.values(unfinishedDependencies)));

        //Get all dependencies that are not required by any unfinished uploads (All finished keys not included in allUnfinishedDependencies) - Those are safe to remove
        let finishedUploadsToRemove = _.difference(Object.keys(finishedDependencies), allUnfinishedDependencies);

        for (let keyToRemove of finishedUploadsToRemove) {
          //First remove it from memory
          delete this.status.value[keyToRemove];
          //Then also delete it from storage - Catch errors and ignore
          await this.statusStorage.remove(keyToRemove).catch(() => null);
          //And finally remove it from the queue (in case it has not been deleted yet) - Catch errors and ignore
          await this.queueStorage.remove(keyToRemove).catch(() => null);
        }
      } catch { //If an error occurs, just continue with the existing status (some might have been already removed, but all of them correctly!)
      }

      this.status.value = {...this.status.value}; //Create a new status object with the same values set, to retrigger the watcher!
      return this.status.value;
    }
  },
  /* Load status from storage, if it was not yet loaded. Or create empty one! Needs to be called before any interaction with status. Always resolves with a status set! */
  initializeStatus: async function() { //Because status initialization is always awaited, no interactions with status can occur before it is ready.
    //Use a promise to never initialize twice at the same time and always resolve immediately, if promise exists 
    if (this.initializationPromise == null){
      //If promise doesn't exist yet, create it by creating and running an anonymous async function
      this.initializationPromise = (async () => {
        //Initialize memory copy of status only if not yet created
        if (this.status.value == null) {
          //Try to load it from the status storage, if error occurs, return empty object
          let newStatus = await this.loadStatusProperties(this.statusStorage).catch(() => ({}));
          //Try to load from the queue, any that are not included in the statusStorage. To achieve this, omit those already loaded from the statusStorage!
          let alreadyLoadedKeys = Object.keys(newStatus);
          _.assign(newStatus, (await this.loadStatusProperties(this.queueStorage, alreadyLoadedKeys).catch(() => ({})))); //Empty object if loading fails
          //Set any of the results as the new status, might be empty if errors occurred
          this.status.value = newStatus;
          //Remove all the completed uploads, that are not dependencies anymore when loading for the first time
          await this.removeCompletedStatus();
        }
      })();
    }

    return this.initializationPromise;
  },
  /* Get the status of the upload for the given key */
  getStatus: async function(key, userId) {
    await this.initializeStatus();

    //Wait for all previous modifcations to finish - Catch all previous errors to not throw an error in this function
    this.statusModificationPromise = this.statusModificationPromise.catch(() => {}).then((async () => {
      //Only return status if it exists
      if (this.status.value != null) {
        //If userId is given, check if it matches user ID in key
        if (userId != null) {
          //Split key to get user in the beginning
          let user = key.split(STATUS_KEY_SEPARATOR)[0];
          //If user is null or doesn't match return without anything
          if (user == null || String(user) !== String(userId)) return;
        }

        //If user is correct or didn't need to be checked, return status
        return this.status.value[key];
      }
    }));

    return this.statusModificationPromise;
  },
  /* Get status for all keys that belong to this user sorted by their index ascending */
  getAllSortedStatusForUser: async function(userId) {
    await this.initializeStatus();

    //Wait for all previous modifcations to finish - Catch all previous errors to not throw an error in this function
    this.statusModificationPromise = this.statusModificationPromise.catch(() => {}).then((async () => {
      try {
        if (userId != null && this.status.value != null) {
          //Split all keys to user and index and create an array of objects with all those parameters set
          let statusObjects = _.map(this.status.value, (value, key) => {
            let [user, index] = key.split(STATUS_KEY_SEPARATOR);

            return { key, status: value, user, index: parseInt(index)};
          });

          //Filter out all valid ones that belong to this user
          statusObjects = _.filter(statusObjects, (statusObject) => {
            return (!(isNaN(statusObject.index)) && statusObject.user != null && String(statusObject.user) == String(userId));
          })

          //Return a sorted array by the index
          return _.sortBy(statusObjects, ['index']);
        }
      } catch {
        //On error return empty array!
        return [];
      }

      //Return empty status array, if not all requirements are satisfied
      return [];
    }));

    return this.statusModificationPromise;
  },
  //Creates a computed status index that is grouped by user and url as route
  createComputedSortedStatusGroupedByUserAndRoute: function() {
    //If it has not been set yet initialize the computed and set it
    if (this.computedPromise == null) {
      //Initialize status that is used in the computed, only once and then create the watcher that calculates the computed value
      this.computedPromise = this.initializeStatus().then(() => {
        watch(this.status, (newValue) => {
          console.log("Watcher triggered!"); //TODO Test how often it gets triggered!
          //Split all keys to user and index and create an array of objects with all those parameters set
          let statusObjects = _.map(newValue, (value, key) => {
            let [user, index] = key.split(STATUS_KEY_SEPARATOR);
      
            return { key, status: value, user, index: parseInt(index)};
          });
      
          //Filter out all valid ones
          statusObjects = _.filter(statusObjects, (statusObject) => {
            return (!(isNaN(statusObject.index)) && statusObject.user != null);
          });
      
          //Sort the array by the index
          statusObjects = _.sortBy(statusObjects, ['index']);
      
          //Return an object grouped by user
          statusObjects = _.groupBy(statusObjects, 'user');
      
          //For every user group by url as route
          this.computedSortedStatusGroupedByUserAndRoute.value = _.mapValues(statusObjects, (sortedStatus) => {
            let mappedStatus = _.map(sortedStatus, (statusObject) => {
              //Add a baseURL to status to also find updates or deletes in the same index
              let status = _.get(statusObject, ['status']);
              let route;
              let updates;
              let deletes;
              if (status != null && status.url != null && status.method != null) {
                //Split the URL to modify single parts of it
                let pathSegments = status.url.split('/');

                //If it is a put or delete request, remove the trailing id information
                if (['put', 'delete'].includes(status.method)) {
                  let updatesOrDeletes = (pathSegments.pop() || pathSegments.pop()); //Handle potential trailing slash (if first pop returns empty string (non truthy value) it is run again)
                  switch (status.method) {
                    case 'put':
                      updates = updatesOrDeletes;
                      break;
                    case 'delete':
                      deletes = updatesOrDeletes;
                      break;
                  
                    default:
                      break;
                  }
                }

                //Set the remaning url segments as a string
                route = pathSegments.join('/');
              }

              //Copy and set an additional route entry
              return {
                ...statusObject,
                route,
                updates,
                deletes
              }
            });

            return {
              all: mappedStatus,
              byRoute: _.groupBy(mappedStatus, 'route')
            }
          });
        }, {immediate: true});
      });
    }

    return this.computedSortedStatusGroupedByUserAndRoute;
  },
  /* Add the request to the queue and create a status object to save in storage and in memory with additional info */
  add: async function(requestConfig) { //TODO Tests: Restart app with one in local memory to see the error state, let an upload retry multiple times, Upload on a day with existing reports and on an existing horse.
    //Check if all required parameters are supplied
    if (requestConfig == null || requestConfig.user == null) throw 'Missing parameters for add';
    await this.initializeStatus();

    //Wait for all previous modifcations to finish - Catch all previous errors to not throw an error in this function
    this.statusModificationPromise = this.statusModificationPromise.catch(() => {}).then((async () => {
      //Load metadata detail from data to set in status, if defined in config. And merge with existing ones to overwrite any changed details but keep the unchanged original ones!
      if (requestConfig.statusDetailPaths != null) {
        //Use original metadata, if defined in config. Gets overwritten by current details. Used for updates! Only use the properites defined in statusDetailPaths!
        if (requestConfig.originalStatusDetails != null) {
          requestConfig.detail = _.pick({...requestConfig.originalStatusDetails}, requestConfig.statusDetailPaths);
          delete requestConfig.originalStatusDetails;
        }
        if (requestConfig.data != null) requestConfig.detail = _.merge(requestConfig.detail, _.pick(requestConfig.data, requestConfig.statusDetailPaths));
        delete requestConfig.statusDetailPaths;
      }

      let existingKeys = (this.status.value != null) ? Object.keys(this.status.value) : [];

      //Split all keys to user and index and only return those for the current user
      let userIndizes = _.map(existingKeys, (key) => {
        let [user, index] = key.split(STATUS_KEY_SEPARATOR);

        if (String(user) == String(requestConfig.user)) return parseInt(index);
        else return null;
      });
      //Filter out invalid indizes
      userIndizes = _.filter(userIndizes, (index) => (index != null && !(isNaN(index))));

      //Create a new index with user id and next higher index number
      let nextIndex = userIndizes.length > 0 ? (Math.max(...userIndizes) + 1) : 0;
      //If the next index is not a valid integer number, use the length of all the keys for all users as a fallback with enough distance!
      if (!(_.isFinite(nextIndex))) nextIndex = existingKeys.length * 2;
      let nextUserIndex = [requestConfig.user, nextIndex].join(STATUS_KEY_SEPARATOR);

      //Add request to queue
      await this.queueStorage.set(nextUserIndex, requestConfig);

      //Add the status object in the memory (with additional info) and storage
      let newStatus = {
        ..._.pick(requestConfig, _.concat(STATUS_STORAGE_PROPERTIES, STATUS_METADATA)),
        //Add additional properties to keep track of the status' state
        stoppedRetry: false, //Set to true, if the upload fails because of an issue not related to connection, don't retry automatically
        dataLost: false //Set to true, if the data in the queue is missing on a retry
      };
      await this.statusStorage.set(nextUserIndex, newStatus);
      let inMemoryStatus = this.appendInMemoryInfoToStatus(newStatus);
      if (this.status.value != null) this.status.value = {...this.status.value, [nextUserIndex]: inMemoryStatus}; //Create a new status object with the same values and the new one set, to retrigger the watcher!
      
      //Give back new index and processed requestConfig
      return { key: nextUserIndex, requestWithFilesAndMetadata: this.stripMetadataAndSplitFiles(requestConfig), completionPromise: inMemoryStatus.completionPromise };
    }));

    return this.statusModificationPromise;
  },
  /* Get the status values out of storage and update them by assigning (if they need update), also set them in the in memory status */
  updateStatus: async function(key, newStatus) {
    //Check if all required parameters are supplied
    if (key == null || newStatus == null) throw 'Missing parameters for updateStatus';
    await this.initializeStatus();

    //Wait for all previous modifcations to finish - Catch all previous errors to not throw an error in this function
    this.statusModificationPromise = this.statusModificationPromise.catch(() => {}).then((async () => {
      //Set only new parameters
      if (this.status.value != null && this.status.value[key] != null) _.assign(this.status.value[key], newStatus);
      //Get parameters for the status and set only those in storage, if any of them are included
      let storageStatusStateParameters = _.pick(newStatus, STATUS_STATE_PROPERTIES);
      if(Object.keys(storageStatusStateParameters).length > 0) {
        //Get previousStatus and assign new status values to it
        let previousStatus = await this.statusStorage.get(key);
        if (previousStatus != null){
          await this.statusStorage.set(key, _.assign(previousStatus, storageStatusStateParameters));
        }
      }
    }));

    return this.statusModificationPromise;
  },
  /* Same function as updateStatus, but applying the status to all keys, no matter which user */
  updateStatusForAll: async function(newStatus){
    //Check if all required parameters are supplied
    if (newStatus == null) throw 'Missing parameters for updateStatusForAll';
    await this.initializeStatus();

    //Wait for all previous modifcations to finish - Catch all previous errors to not throw an error in this function
    this.statusModificationPromise = this.statusModificationPromise.catch(() => {}).then((async () => {
      //Set only new parameters
      if (this.status.value != null) {
        //Loop through all keys and set the objects accordingly by assigning new status
        for (let key of Object.keys(this.status.value)) {
          _.assign(this.status.value[key], newStatus);
        }
      }
      //Get parameters for the status and set only those in storage, if any of them are included
      let storageStatusStateParameters = _.pick(newStatus, STATUS_STATE_PROPERTIES);
      if(Object.keys(storageStatusStateParameters).length > 0) {
        //Get all the keys in storage for iterating and modifying without issues
        let storageKeys =  await this.statusStorage.keys();

        //Loop through all keys and set the objects accordingly by assigning new status
        for (let key of storageKeys) {
          //Get previousStatus and assign new status values to it
          let previousStatus = await this.statusStorage.get(key);
          if (previousStatus != null){
            await this.statusStorage.set(key, _.assign(previousStatus, storageStatusStateParameters));
          }
        }
      }
    }));

    return this.statusModificationPromise;
  },
  /* Checks all statuses if dependencies have been resolved and apply them to the object accordingly */
  tryApplyDependencies: async function(key, requestWithFilesAndMetadata){
    //Check if all required parameters are supplied
    if (key == null || requestWithFilesAndMetadata == null || requestWithFilesAndMetadata.request == null || requestWithFilesAndMetadata.request.data == null || requestWithFilesAndMetadata.user == null) throw 'Missing parameters for tryApplyDependencies';
    await this.initializeStatus();

    let userStatus = await this.getAllSortedStatusForUser(requestWithFilesAndMetadata.user);

    //Wait for all previous modifcations to finish - Catch all previous errors to not throw an error in this function
    this.statusModificationPromise = this.statusModificationPromise.catch(() => {}).then((async () => {
      //Dependencies are given as an object with a path string for the key and the dependant ID as the value
      let dependencies = _.cloneDeep(requestWithFilesAndMetadata.dependencies) || {}; //Clone, to safely modify dependencies! If no dependencies present, use empty object.
      //First invert key and value and group by the dependant IDs for better search, use a transform for this task to keep keys as new values in array
      dependencies = _.transform(dependencies, function(result, value, key) {
        (result[value] || (result[value] = [])).push(key); //Either take the array inside the accumulator or create a new one at the key (being the value)
      }, {});

      //Get all status objects for the given user and loop through them, taking the key and the status
      for (const {key: statusKey, status} of userStatus){
        //Check if that upload status is a dependency of the request to be checked
        if (statusKey != null && statusKey in dependencies) {
          //If the dependency has been fulfilled apply the id to all the paths that requested this dependency
          if (status != null && status.finishedID != null) {
            //Go through all the paths in the array for this dependency and set the finishedID as its new value
            for (let dependencyPath of dependencies[statusKey]) {
              _.set(requestWithFilesAndMetadata.request.data, dependencyPath, status.finishedID);
            }
            //Once this dependency is finished, remove it
            delete dependencies[statusKey];
            //And continue with the next dependency
            continue;
          }
          //Otherwise the dependency is incomplete, throw error, no need to check other dependencies
          throw `Dependency was not met for key ${statusKey}`;
        }
      }

      //If any dependencies are leftover that weren't checked, throw an error too, as they are unmet dependencies
      let leftoverDependencies = Object.keys(dependencies);
      if (leftoverDependencies.length > 0) throw `Unmet dependencies for key ${key}: ${leftoverDependencies.join(' ')}`; //TODO How to display missing dependencies in status? Only retry missing manually!

      return requestWithFilesAndMetadata;
    }));

    return this.statusModificationPromise;
  },
  /* Completes the upload and sets all properties accordingly. Status is kept until restart of the app for dependencies! */
  complete: async function(key, finishedID, completeResponse) {
    //Check if all required parameters are supplied
    if (key == null) throw 'Missing parameters for complete';
    await this.initializeStatus();

    //Use a placeholder in case this item does not require ID and does not return it
    if (finishedID == null) finishedID = FINISHED_WITHOUT_ID_INDICATOR;

    //First update id and progress and wait for it to complete
    await this.updateStatus(key, { finishedID, progress: 100 });

    //Only if it completed, remove the upload from the queue. Continue on error, and complete it anyway
    await this.remove(key).catch((error) => console.error(`Could not remove key ${key} from upload queue`, error));

    //Finally resolve the completion promise, if it exists
    let currentStatus = await this.getStatus(key);
    if (currentStatus != null && currentStatus.completeUpload != null) await currentStatus.completeUpload(finishedID, completeResponse);
  },
  /* Remove an upload from the queue. Keep status for dependencies. Dependencies are not cleared! */
  remove: async function(key, removeStatus = false) {
    await this.initializeStatus();

    //Wait for all previous modifcations to finish - Catch all previous errors to not throw an error in this function
    this.statusModificationPromise = this.statusModificationPromise.catch(() => {}).then((async () => {
      //Only remove status when specifically requested. User has been warned!
      if (removeStatus === true) {
        //First remove it from memory
        delete this.status.value[key];
        //Then also delete it from storage
        await this.statusStorage.remove(key);
      }

      await this.queueStorage.remove(key);

      this.status.value = {...this.status.value}; //Create a new status object with the same values set, to retrigger the watcher!
    }));

    return this.statusModificationPromise;
  },
  /* Removes all uploads for a specific user id */
  /*clearForUser: async function(userId) { 
    await this.initializeStatus();

    //Wait for all previous modifcations to finish - Catch all previous errors to not throw an error in this function
    this.statusModificationPromise = this.statusModificationPromise.catch(() => {}).then((async () => {
    
    })();

    return this.statusModificationPromise;
    //TODO Implement?
  }*/
}


//TODO IDEAS TO IMPLEMENT IMPROVED CACHING:
//TODO When return is not text/json we save in filecache otherwise in normal cache to keep them separated
//TODO Is there a way to scan for files and start caching them? Also how to replace with cached URLs? Can we just scan for URL parameter?
//TODO When going through files, we could generate a thumbnail for videos automatically?
//TODO Maybe a lot of the caching can be outsourced to ServiceWorker! Also authentication for files?

//TODO Add option to cache only when requested, so some things never get cached? Reports are immutable but other things are mutable!! Or maybe use for those network first caching strategy? Indicate outdated?


//Create a cache for any requests
/*const cache = createStorage({
    name: '__cache',
    driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB*//*, Drivers.LocalStorage*//*, memoryDriver._driver] //Skip the localStorage to not fill it up
  },
  [CordovaSQLiteDriver, memoryDriver]
);

const fileCache = createStorage({
    name: '__filecache',
    driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
  },
  [CordovaSQLiteDriver]
);*/

/*const serializeFormData = function(data) {
	let obj = {};
	for (let [key, value] of data) {
		if (obj[key] !== undefined) {
			if (!Array.isArray(obj[key])) {
				obj[key] = [obj[key]];
			}
			obj[key].push(value);
		} else {
			obj[key] = value;
		}
	}
	return obj;
}

const unserializeFormData = function(data) {
	let formData = new FormData();
	for (let [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      for (let arrayValue of value) {
        formData.append(key, arrayValue);
      }
    } else {
      formData.append(key, value);
    }
	}
	return formData;
}*/

const NETWORK_ERRORS = [
  502, //Bad Gateway
  503, //Service Unavailable
  504 //Gateway Timeout
];

const executeRequest = async function(key, request, totalFileSize) {
  //Set the state that we are uploading now - Fake 1 to show progress
  try {
    await upload.updateStatus(key, { progress: 1 });
  } catch {
    //Do nothing, status update can be ignored, if failed here!
  }

  //Create a copy of the request and add parameters for the final execution
  let apiConfig = {...request};
  
  apiConfig['onUploadProgress'] = (progressEvent) => upload.updateStatus(key, { progress: (progressEvent.loaded / progressEvent.total) });
  if (totalFileSize) { //If files should be uploaded, set higher timeout for this request only!
    apiConfig['timeout'] = calculateFileUploadTimeout(totalFileSize);
  }

  return apiInstance.request(apiConfig)
    .then(async (uploadResponse) => {
      //Check if upload was successfull by checking if response contains an ID
      if (uploadResponse != null && uploadResponse.data != null && API_OK_STATUS_CODES.includes(uploadResponse.status)) {
        let responseData = uploadResponse.data;
        //TODO Add this repsonse to cache, if cache is implemented, keep files in mind. Result does not contain files. Files are accessed using their property path inside the JSON? Add all the file blobs to the local fileCache anyway.
        //Complete the upload, setting all the status values and return the API response in the end!
        return upload.complete(key, responseData.id, responseData).then(() => responseData);
      }
      //If not, consider this upload not complete and continue with the error handler!
      throw 'No valid response received to complete request';
    })
    .catch(async (error) => {
      let newStatus = { progress: -1 }; //Always set the state that uploading did not succeed
      
      if (error) {
        if (error.response && error.response.status) {
          if (!NETWORK_ERRORS.includes(error.response.status)) { //Not a network related error, don't retry
            //Keep it always manual, even if failed a second time with network error. Original problem still persists!
            newStatus.stoppedRetry = true;
          }
        } //Else it is Offline - no status set - keep retrying
      }

      //Set the new status
      await upload.updateStatus(key, newStatus); 

      //Then rethrow the error to handle it outside!
      throw error;
    });
}

//Upload a single request. Dependencies are always checked and applied on every upload attempt. This way it doesn't have to be monitored and updated immediately.
//Application can use the temporary key (id while uploading) for creating new requests, even after it completed uploading. Status is only cleared on restart of the app, if nothing depends on the respective upload.
const uploadSingleRequest = async function(key, requestWithFilesAndMetadata, ignoreErrors = true) {
  //First check if all dependencies are resolved and apply them to the request - Rejects if at least one dependency is not resolved!
  return upload.tryApplyDependencies(key, requestWithFilesAndMetadata)
  .then((requestWithFilesAndMetadataDependenciesApplied) => { //If dependencies are resolved and applied successfully, convert it to FormData
    return upload.convertToFormData(requestWithFilesAndMetadataDependenciesApplied);
  })
  .catch(async (error) => { //Caught separately here to set state, later done by executeRequest!
    await upload.updateStatus(key, { progress: -1 }); //Set the state that uploading did not succeed 
    throw error; //Rethrow error to go to next catch
  })
  .then(({request, totalFileSize}) => {
    return executeRequest(key, request, totalFileSize);
  })
  .catch((error) => {
    let decipheredError = decipherApiError(error);
    console.error(`Upload for key ${key} failed`, decipheredError);
    if (!ignoreErrors) throw decipheredError; //Rethrow error if not ignored to handle outside
  });
}

const convertFileToBlob = async function(fileObject){
  //If it is already a file object, return it
  if (fileObject instanceof Blob) {
    return fileObject;
  } else if (fileObject != null && fileObject.blobURL != null) { //If it has a blobURL, fetch it for sending and convert it to a file
    return fetch(fileObject.blobURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error fetching the file for upload: ${fileObject}`);
        }
        return response.blob();
      })
      .then((blobFile) => {
        //Convert to file and only set type if mime is valid!
        return new File([blobFile], (fileObject.name || 'file'), (fileObject.mime != null) ? { type: fileObject.mime } : undefined) 
      });
  }
  //If it can't be converted, throw an error
  throw new Error(`Invalid file for upload: ${fileObject}`);
}

const addToUploadAndStartFirstAttempt = async function(url, data, config = {}, method) {
  let requestConfig = {
    ...config, //May contain files and dependencies (to other uploads). Get stripped before upload!
    url,
    data,
    method
  }

  let files = {};
  if (config.files != null) {
    //Convert each file and set it in the new object with the same path as the key
    for (let [filePath, fileObject] of Object.entries(config.files)) {
      //If it is multiple files, convert them all separately
      if (Array.isArray(fileObject)) {
        let convertedFileArray = [];
        for (let file of fileObject) {
          convertedFileArray.push(await convertFileToBlob(file));
        }
        files[filePath] = convertedFileArray;
      } else {
        files[filePath] = await convertFileToBlob(fileObject);
      }
    }
  }

  requestConfig.files = files;

  let uploadRequestInfo = await upload.add(requestConfig);

  //Wait for any previous uploads to finish - Catch all previous errors to even continue if the one before in the chain failed!
  upload.uploadPromiseChain = upload.uploadPromiseChain.catch(() => {}).then(() => {
    //Start the next upload, ignore any errors by default and continue with the next upload
    return uploadSingleRequest(uploadRequestInfo.key, uploadRequestInfo.requestWithFilesAndMetadata);
  })

  //Return the key of the new upload and the promise for completion
  return _.pick(uploadRequestInfo, ['key', 'completionPromise']);
}

upload.createComputedSortedStatusGroupedByUserAndRoute();

//Export custom api functions, with extensions beyond axios capabilities
export const api = {
  /**
   * Tries to load from upload queue first, if it exists there, otherwise calls API.
   * If it is finished in the upload status, return the API response with the finishedID instead.
   * Returns an object on success with response always set. Status is set, if it is an upload with its current status!
   */
  get: async function(url, config) { //TODO Needs to include user in config to get from the queue!
    //Get the last part of the urls path to search for in uploads (equals to the temporary key)
    let urlPath = (new URL(url, apiBaseURL).pathname);
    let targetURL = urlPath; //Save again to overwrite in case it is fetched from API after finishing upload
    let pathSegments = urlPath.split('/');
    let id = pathSegments.pop() || pathSegments.pop(); //Handle potential trailing slash (if first pop returns empty string (non truthy value) it is run again)
    
    //Convert config to extract user and metadata information
    let getRequestWithFilesAndMetadata = upload.stripMetadataAndSplitFiles(config);

    //Always have at least empty config for adding something
    let requestConfig = {};

    //If only newer results are requested, this will be set with the current time at request
    let currentTimestampForFetch = null;
    
    //Contains filter parameters for get request
    let filter = {};

    //Only try to get from uploadStatus, if a user id is set to only give back uploads for the current user!
    if (getRequestWithFilesAndMetadata != null) {
      if (getRequestWithFilesAndMetadata.user != null) {
        //Check if it exists as a status for this user
        let statusForId = await upload.getStatus(id, getRequestWithFilesAndMetadata.user);

        if (statusForId != null) {
          if (statusForId.finishedID != null) {
            //If it finished, replace the ID to load with the actual finished id and continue to load it from the API
            id = statusForId.finishedID;
            //pathSegments do not have ID anymore, concat to add the modified id and then join to become URL again
            targetURL = _.concat(pathSegments, [id]).join('/');
          } else { //Otherwise it is still in the queue and should be loaded from there
            try {
              //Try to load from the uploadqueue
              let uploadingResponse = await upload.get(id).then(async (requestWithFilesAndMetadata) => {
                //If it exists, convert it to a response object
                let response = upload.convertToResponse(requestWithFilesAndMetadata);

                //If it is a PUT request, merge the new modified data with the old one
                if (requestWithFilesAndMetadata != null && requestWithFilesAndMetadata.request != null && requestWithFilesAndMetadata.request.method === 'put' && requestWithFilesAndMetadata.id != null) {
                  //First get the old data from the API, by adding the old ID to the url path segments
                  let apiResponse = await apiInstance.get(_.concat(pathSegments, [requestWithFilesAndMetadata.id]).join('/'), requestConfig);
                  //Then merge the new one, by assigning all properties. Each property that gets modified is set and thus overwrites it. Arrays get unioned together.
                  if (apiResponse != null && apiResponse.data != null) return _.assignWith({}, apiResponse.data, response, mergeWithArraysOfValuesUnionKeyOrEquals);
                }

                return response;
              });
              
              
              return {
                data: uploadingResponse,
                uploadStatus: {key: id, ...statusForId} //Add status if it is from an ongoing upload
              }
            } catch (error) {
              //If an error occured, or we didn't return continue by getting it through an API call
              console.error('Could not load uploading response', error);
            }
          }
        }
      }

      //If an array is set with paths of at least one that should be newer than the last fetch, try to get last fetch time from metadata and add filters for each!
      if (Array.isArray(getRequestWithFilesAndMetadata.fetchNewerForAnyPath)) {
        let lastFetchFilters = {};
        try {
          let lastFetchTime = await routeMetadata.getMetadataAttribute(targetURL, 'lastFetchTime');

          if (lastFetchTime != null) {
            for (let path of getRequestWithFilesAndMetadata.fetchNewerForAnyPath) {
              lastFetchFilters[`${path}_gte`] = lastFetchTime; //Greater than or equal to the last fetch time for each requested path
            }
          }

          currentTimestampForFetch = dayjs().utc().toISOString();
        } catch (error) {
          console.error(`Could not get lastFetchTime for route ${targetURL}`, error);
          //If an error occurred, continue request without filters
          currentTimestampForFetch = null;
          lastFetchFilters = {};
        }

        filter = _.assign(filter, lastFetchFilters);
      }

      requestConfig = getRequestWithFilesAndMetadata.request;
    }

    //Assign filters to the parameters for API call, if any filters are to be set
    if (Object.keys(filter).length) requestConfig.params = _.assign((requestConfig.params || {}), filter);

    //Try to load it with a regular API call, if not loaded before from somewhere else
    let result = _.omit(await apiInstance.get(targetURL, requestConfig), 'uploadStatus'); //uploadStatus is not present, when coming from the API (or its cache) - Remove just in case

    //Update the last fetch time with the set current time - always dated before the fetch to not miss any new ones - might result in duplicates on the next call!
    if (currentTimestampForFetch != null) routeMetadata.update(targetURL, { lastFetchTime: currentTimestampForFetch }); //FIXME If the request was not successful, do not save! Otherwise the user gets never any entry_types after first login! When is it considered not sucessful?

    return result;
  },
  delete: function(url, config) { //TODO Implement delete using uploadQueue too!
    //TODO Check if it is an uploading one, then check if it is uploading, then deletion is not possible. Check if it is a dependency of something else, if yes also trigger an error, otherwise allow removal.
    //TODO If it is finished uploading and finishedID is set, also delete finishedID.
    //TODO StoppedRetry, Offline and DataLost are the only reasons to delete - and finished!
    return apiInstance.delete(url, config);
    //TODO Only applies for reports. Move there?
    /*//If an array of IDs is returned, remove them from cache
    for (let relatedId of response.data) {
      cache.remove(`/reports/${relatedId}`);
    }*/
  },
  head: function(url, config) {
    return apiInstance.head(url, config);
  },
  post: async function(url, data, config) { //TODO Needs to include user in config!
    //If configured to skip, just start a regular API call and return the completion promise
    if (config != null && config.skipQueue) {
      delete config.skipQueue; //Remove skip parameter from config
      return { completionPromise: apiInstance.post(url, data, config) };
    }
    return addToUploadAndStartFirstAttempt(url, data, config, 'post');
  },
  put: async function(url, data, config) { //TODO Needs to include user in config!
    //If configured to skip, just start a regular API call and return the completion promise
    if (config != null && config.skipQueue) {
      delete config.skipQueue; //Remove skip parameter from config
      return { completionPromise: apiInstance.put(url, data, config) };
    }
    return addToUploadAndStartFirstAttempt(url, data, config, 'put'); //TODO In the future a dependency could also be in the URL to update something that was not uploaded yet!
  },

  //UPLOAD AND STATE FUNCTIONS:

  retryUploads: async function(user, manualRetry = false) {
    //Wait for all previous uploads to complete before getting the current status and retrying again - Catch all previous errors to not throw an error in this function
    upload.currentUploadPromise = upload.currentUploadPromise.catch(() => {}).then((async () => { //Create an async function to create a promise that can use async
      let failedUploads = {}; //Save the keys of failed uploads as feedback to calling function

      for (const {key, status} of await upload.getAllSortedStatusForUser(user)){
        //If it is not currently uploading, not finished already, not stopped because of an error unrelated to connection (if automatic)
        //and data is not missing (allow to try again manually, as error might be temporary for loading from storage), retry
        if (status.progress === -1 && status.finishedID == null && (!status.stoppedRetry || manualRetry) && (!status.dataLost || manualRetry)) {
          //Set all that can be uploaded to the uploading status to indicate retry immediately
          await upload.updateStatus(key, { progress: 0 });
          
          //Chain all uploads in a promise chain to wait for each other - Catch all previous errors to even continue if the one before in the chain failed!
          upload.uploadPromiseChain = upload.uploadPromiseChain.catch(() => {}).then(() => {
            //Try to get from upload queue
            return upload.get(key);
          })
          .catch(async () => { //Value can't be found. Consider the data lost.
            await upload.updateStatus(key, { dataLost: true });
            throw 'Data lost';
          })
          .then((requestWithFilesAndMetadata) => uploadSingleRequest(key, requestWithFilesAndMetadata, false)) //Try upload and don't ignore errors, to add them to the list of errors!
          .catch(async (error) => {
            failedUploads[key] = decipherApiError(error); //Save error for feedback
          }); //Continue with next upload, even when this one fails - Dependencies are checked before each upload
        }
      }

      //Wait for all uploads to resolve or reject - Catch all previous errors to not throw an error in this function
      await upload.uploadPromiseChain.catch(() => {});

      //If one failed, reject the whole upload
      if (Object.keys(failedUploads).length >= 1) { 
        throw failedUploads;
      }
      
      return; //Only resolve when the last update has completed
    }));

    return upload.currentUploadPromise;
  },
  removeUpload: async function(key, user) {
    //Check if the user is the correct user to delete this entry
    let [keyUser] = key.split(STATUS_KEY_SEPARATOR);

    if (String(user) == String(keyUser)) {
      //Remove the upload including the status!
      return upload.remove(key, true);
    } else {
      throw 'Authentication of correct user required to delete this upload';
    }
  },
  clearRouteMetadata: function(forSpecificRoutes) { //forSpecificRoutes can be an array of routes to clear, does not clear all unless it is null!
    return routeMetadata.clear(forSpecificRoutes);
  },
  getStorageInfos: async function(){
    let storageObjects = [upload.statusStorage, upload.queueStorage, routeMetadata.storage];

    let storageInfos = [];
    
    for (let storageObject of storageObjects) {
      //Get the keys or empty array, if it fails
      let keys = await storageObject.keys().then((keys) => (keys != null) ? keys.sort() : []).catch(() => []);
      storageInfos.push({
        name: storageObject.config.name,
        driver: storageObject.driver,
        keys 
      });
    }

    return storageInfos;
  },
  
  getUploadIndex: function(){
    return upload.computedSortedStatusGroupedByUserAndRoute;
  },
  //TODO When it is a put request and id is set in the metadata, give back this id, so it can be replaced in the existing index!

  //Store the token separately from everything else to have better access, even in Service Workers!
  tokenKey: 'auth_token',
  localToken: ref(null), //Store it in memory as a ref for reactivity,
  getToken: function(){
    this.localToken.value = localStorage.getItem(this.tokenKey);
    return this.localToken;
  },
  setToken: function(newToken){
    localStorage.setItem(this.tokenKey, newToken);
    this.localToken.value = newToken;
    return this.localToken;
  },
  removeToken: function(){
    localStorage.removeItem(this.tokenKey);
    this.localToken.value = null;
    return this.localToken;
  },
  addTokenToConfig: function(config){
    const token = this.getToken().value;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  
    return config;
  },
  authenticationRelayActive: ref(false), //Store it in memory as a ref for reactivity
  setAuthenticationRelayActive: function(active) {
    this.authenticationRelayActive.value = active;
  },
  getAuthenticationRelayActive: function() {
    return this.authenticationRelayActive;
  },

  instance: apiInstance
}

//Intercept every request to set the authentication header automatically based on the current presence of it
api.instance.interceptors.request.use((config) => api.addTokenToConfig(config));
