import _ from 'lodash';

import { getPlatforms } from '@ionic/vue';

import { download, sanitizeFileOrFolderName } from '@/utils/file_download';

import { useI18n } from "@/utils/i18n";

import { useEditAnimalModal } from '@/components/composables/EditAnimalModal';

import { openCameraModal, default as cameraModalComponent } from '@/components/CameraModal.vue';

import { openCodeScannerSelectModal, default as codeScannerSelectModalComponent } from '@/components/CodeScannerSelectModal.vue';

import { localErrorToast } from '@/utils/error';

import QRCodeStyling from 'qr-code-styling';

import { checkForSameOrigin } from '@/authentication-caching-utils';

const BASE_URL = process.env.VUE_APP_QR_BASE_URL;

const WEBSITE_URL = new URL(process.env.VUE_APP_WEBSITE_URL);

export const UNASSIGNED_ID = 'unassigned';

const URL_FONT_SIZE = 24;

const URL_MARGIN = 6;

const URL_HEIGHT = (URL_FONT_SIZE + (2 * URL_MARGIN));

const QR_CODE_SIZE = 300;

export const QR_URL_PATH_MAPPING = {
  'animal': 'a',
  'location': 'l',
  'case-status': 'c'
}

export const INVERSE_QR_URL_PATH_MAPPING = _.invert(QR_URL_PATH_MAPPING);

export const QR_ATTRIBUTES = {
  'animal': [
    { path: 'name', translationPrefix: 'general', showAsIdentifier: true, shortKey: 'n' },
    { path: 'unique_identifier', translationPrefix: 'general', shortKey: 'u' },
    { path: 'additional.race', shortKey: 'r' },
    { path: 'additional.gender', shortKey: 'g' },
    { path: 'additional.birthday', shortKey: 'b' },
    { path: 'additional.birthyear', shortKey: 'y' },
    { path: 'additional.microchip_number', shortKey: 'm' },
    { path: 'additional.slaughter', shortKey: 's' },
    { path: 'additional.color', shortKey: 'c' }
  ],
  'location': [
    { path: 'location', showAsIdentifier: true, shortKey: 'l' },
    { path: 'spot', showAsIdentifier: true, shortKey: 's' },
  ],
  'case-status': [
    { path: 'status', showAsIdentifier: true, shortKey: 's' },
  ]
};

export function useQR() {
  const i18n = useI18n();

  const { translateAttribute: translateAnimalAttribute } = useEditAnimalModal();

  const createSVGNode = function(type, content, attributes = {}) {
    let newNode = document.createElementNS("http://www.w3.org/2000/svg", type);
    let modifiedAttributes = {...attributes}; //Make a copy for modification
    //Set default font-family
    if (type === 'text') {
      modifiedAttributes['font-family'] = "'InterDisplay', Roboto, 'Helvetica Neue', sans-serif";
    }

    if (content != null) newNode.textContent = content;
    _.forEach(modifiedAttributes, (attribute, key) => {
      if (attribute != null && key != null) newNode.setAttribute(key, attribute);
    });

    return newNode;
  }

  const fitTextIntoWidth = function(textNode, text, x, width, lineHeight) {
    let words = text.split(/\s+/).reverse();
    let word;
    let line = [];
    let tspan = createSVGNode('tspan', '', { 'x': x, 'dy': 0, 'dominant-baseline': 'hanging' });
    textNode.append(tspan);
    let spanCount = 1;

    while ((word = words.pop()) != null) {
      line.push(word);
      tspan.textContent = line.join(' ');
      if (tspan.getComputedTextLength() > width && line.length > 1) //Only start a new line if it is more than 1 word!
      {
        line.pop();
        tspan.textContent = line.join(' ');
        line = [word];
        tspan = createSVGNode('tspan', word, { 'x': x, 'dy': lineHeight, 'dominant-baseline': 'hanging' });
        textNode.append(tspan);
        spanCount++;
      }
    }

    return spanCount;
  }

  const bboxWithinMargins = function(bbox, width, height) {
    if (bbox == null) return true;
    if (bbox.width <= width && bbox.height <= height) return true;
    return false;
  }

  const localizeAttributes = function(type, targetObject) {
    let translationPath;
    let translationFunction = (() => undefined); //A fallback function to trigger taking the original value without translation

    switch (type) {
      case 'animal': {
        translationPath = 'animal.attributes';
        translationFunction = (attribute, displayValue) => {
          return translateAnimalAttribute.value(displayValue, _.last(_.split(attribute.path, '.')));
        }

        break;
      }

      case 'location': {
        translationPath = 'location.display_attributes';

        break;
      }

      case 'case-status': {
        translationPath = 'case-status.display_attributes';

        break;
      }

      default:
        break;
    }

    return _.map(_.filter((QR_ATTRIBUTES[type] || []), (attribute) => _.get(targetObject, attribute.path) != null), (attribute) => {
      let displayValue = _.get(targetObject, attribute.path);
      if (displayValue === true || displayValue === false) { //Convert booleans to text
        displayValue = i18n.$t(`default_interaction.${(displayValue) ? 'yes' : 'no'}`);
      } else {
        //Try to translate or take the original value
        displayValue = translationFunction(attribute, displayValue) || displayValue;
      }

      return {
        ...attribute,
        displayTitle: i18n.$t(`${translationPath}${attribute.translationPrefix ? ('.' + attribute.translationPrefix) : ''}.${attribute.path}`),
        displayValue
      }
    });
  }

  const getQRData = function (type, targetObject, id) {
    let url = `/${QR_URL_PATH_MAPPING[type]}/`;
    let params = {};

    _.forEach((QR_ATTRIBUTES[type] || []), ({path, shortKey}) => {
      let value = _.get(targetObject, path);
      if (value != null) {
        if (value === true) {//Use empty string for boolean to save space if true!
          params[shortKey] = '';   
        } else if (value === false) { //Or not existing if false
          delete params[shortKey];
        } else {
          params[shortKey] = value;
        }
      }
    });

    let displayParams = localizeAttributes(type, targetObject);

    let identifierString = _.join(_.compact(_.map(displayParams, (attribute) => {
      if (attribute.showAsIdentifier) return _.get(targetObject, attribute.path);
      return undefined;
    })), ' | ');

    let fullUrl;
    if (id != null && url != null) {
      let searchParams = new URLSearchParams(params);

      let outputURL = new URL(`${url}${id}`, BASE_URL);
      outputURL.search = searchParams.toString();

      fullUrl = outputURL.toString();
    }

    return {
      url,
      id,
      fullUrl,
      params,
      displayParams,
      identifierString
    }
  }

  const generateQR = function(data, settings = {}, color = 'primary') {
    let textPosition = settings.textPosition;

    let width = (textPosition == 'left' || textPosition == 'right') ? (QR_CODE_SIZE * 2) : QR_CODE_SIZE;
    let height = (((textPosition == 'top' || textPosition == 'bottom') ? (QR_CODE_SIZE * 2) : QR_CODE_SIZE));

    let highlightColor = getComputedStyle(document.body).getPropertyValue(`--ion-color-${color || 'primary'}-shade`);
    let logoColor = getComputedStyle(document.body).getPropertyValue('--ion-color-primary-shade'); //Logo and URL are always part of the identity

    let urlHeight = (settings.includeURL) ? URL_HEIGHT : 0;

    let modifiedWidth = width;
    let modifiedHeight = height + urlHeight;

    let qrCodeWidth = QR_CODE_SIZE;
    let qrCodeHeight = QR_CODE_SIZE + urlHeight;

    let extension = function(svg/* , options */) {
      let xOffset = 0;
      let yOffset = 0;
      let textX = 0;
      let textY = 0;
      let urlX = QR_CODE_SIZE - URL_MARGIN;
      let urlY = QR_CODE_SIZE + URL_MARGIN;
      switch (textPosition) {
        case 'top':
          yOffset -= (QR_CODE_SIZE/2);
          textY -= (QR_CODE_SIZE/2);
          urlY += (QR_CODE_SIZE/2);
          break;
        case 'bottom':
          yOffset += (QR_CODE_SIZE/2);
          textY += ((QR_CODE_SIZE*1.5) + urlHeight);
          urlY += (QR_CODE_SIZE/2);
          break;
        case 'left':
          xOffset -= (QR_CODE_SIZE/2);
          textX -= (QR_CODE_SIZE/2);
          urlX += (QR_CODE_SIZE/2);
          break;
        case 'right':
          xOffset += (QR_CODE_SIZE/2);
          textX += (QR_CODE_SIZE*1.5);
          urlX += (QR_CODE_SIZE/2);
          break;
      
        default:
          break;
      }
      svg.setAttribute('viewBox', `${xOffset} ${yOffset} ${modifiedWidth} ${modifiedHeight}`);
      svg.removeAttribute('width');
      svg.removeAttribute('height');

      svg.setAttribute('class', 'generated-qr-code');
      let uniqueID = `generated-qr-code-${data.id}`

      svg.setAttribute('id', uniqueID);

      //Fix for clipPaths so the ID is unique, otherwise the QR codes are all identical!
      let clipPaths = svg.getElementsByTagName('clipPath');
      clipPaths.forEach((path) => {
        let currentID = path.getAttribute('id');
        path.setAttribute('id', `${uniqueID}-${currentID}`);
      });
      let rects = svg.getElementsByTagName('rect');
      rects.forEach((rect) => {
        let currentPathUrl = rect.getAttribute('clip-path');
        if (currentPathUrl != null) rect.setAttribute('clip-path', currentPathUrl.replace(/'#(.+)'/i, `'#${uniqueID}-$1'`));
      });

      //Modify background to fill completely and give ID for modifications
      svg.childNodes.forEach((child) => {
        if (child.getAttribute('width') == width && child.getAttribute('height') == height && child.getAttribute('fill') == '#ffffff') {
          child.setAttribute('x', xOffset);
          child.setAttribute('y', yOffset);
          child.setAttribute('width', modifiedWidth);
          child.setAttribute('height', modifiedHeight);
          child.setAttribute('id', 'background');
          if (settings.hideBackground) child.setAttribute('fill-opacity', '0');
        }
      });

      let textAttributes = {};
      if (textPosition != null) {
        textAttributes = _.filter(_.get(data, ['displayParams']), (attribute) => (settings.filterParams == null || settings.filterParams[attribute.shortKey] === true));
      }

      let informationFontSize = 24;
      let minimumFontSize = 18;
      let informationMargin = 10;

      textX += informationMargin;

      let textAreaWidth = ((textPosition == 'left' || textPosition == 'right') ? (modifiedWidth - qrCodeWidth) : modifiedWidth) - (informationMargin * 2);
      let textAreaHeight = ((textPosition == 'top' || textPosition == 'bottom') ? (modifiedHeight - qrCodeHeight) : modifiedHeight) - (informationMargin * 2);

      let group = createSVGNode('g');
      svg.appendChild(group);

      //Run in a loop while it is not fitting - For first iteration add the font size change
      informationFontSize++;
      do {
        let currentTextY = textY + informationMargin;
        informationFontSize--;
        group.innerHTML = ''; //Remove all children from possible previous runs
        let informationGap = Math.round(informationFontSize/5);
        let lineHeight = informationFontSize + informationGap;

        _.forEach(textAttributes, (attribute) => {
          //If titles should be shown always reserve the space
          if (settings.showTitles) {
            //But only draw if actually exists
            if (attribute.displayTitle != null) {
              let title = createSVGNode('text', undefined, {
                'font-weight': 'bold',
                'font-size': informationFontSize,
                'fill': ((settings.color) ? highlightColor : undefined),
                'text-anchor': ((settings.centerX) ? 'middle' : undefined),
                'x': ((settings.centerX) ? (textX + Math.floor(textAreaWidth / 2)) : textX),
                'y': currentTextY,
                'dominant-baseline': 'hanging'
              });
              group.appendChild(title);
              let lineCount = fitTextIntoWidth(title, attribute.displayTitle, title.getAttribute('x'), textAreaWidth, lineHeight);
              currentTextY += (lineHeight * (lineCount - 1));
            }
            //If the title got shown, move one line more down
            currentTextY += lineHeight;
          }

          let text = createSVGNode('text', undefined, {
            'font-size': informationFontSize,
            'text-anchor': ((settings.centerX) ? 'middle' : undefined),
            'x': ((settings.centerX) ? (textX + Math.floor(textAreaWidth / 2)) : textX),
            'y': currentTextY,
            'dominant-baseline': 'hanging'
          });
          group.appendChild(text);
          let lineCount = fitTextIntoWidth(text, attribute.displayValue, text.getAttribute('x'), textAreaWidth, lineHeight);
          currentTextY += (lineHeight * (lineCount - 1));

          currentTextY += lineHeight + informationGap;
        });
      } while((informationFontSize > minimumFontSize) && (!(bboxWithinMargins(group.getBBox(), textAreaWidth, textAreaHeight)))); //Reduce the font size until it fits or we reached the minimum

      if (settings.centerY) {
        let currentPosition = group.getBBox();
        if (currentPosition != null && currentPosition.height > 0) {
          let difference = textAreaHeight - currentPosition.height;
          let adjust = Math.floor(difference / 2);
          group.querySelectorAll('text, tspan').forEach((textNode) => {
            let currentY = textNode.getAttribute('y');
            if (currentY != null) {
              currentY = parseInt(currentY);
              textNode.setAttribute('y', (currentY + adjust));
            }
          });
        }
      }

      if (settings.includeURL) {
        let url = createSVGNode('text', WEBSITE_URL.hostname, {
          'font-weight': 'bold',
          'font-size': URL_FONT_SIZE,
          'fill': ((settings.color) ? logoColor : undefined),
          'dominant-baseline': 'hanging',
          'text-anchor': 'end',
          'x': urlX,
          'y': urlY
        });
        svg.appendChild(url);
      }
    };

    let qrCodeOptions = {
      width,
      height,
      type: 'svg',
      data: data.fullUrl,
      image: `/assets/qr/logo_rounded_corners${ !(settings.color) ? '_transparent' : `_${color}` }.png`,
      qrOptions: {
        errorCorrectionLevel: 'M'
      },
      dotsOptions: {
          color: 'black',
          type: 'square'
      },
      cornersSquareOptions: {
        color: 'black',
        type: 'extra-rounded'
      },
      cornersDotOptions: {
        color: 'black', //Do not change color of dots. Interferes with scanning!
        type: 'dot'
      },
      backgroundOptions: {
        color: '#ffffff'
      },
      imageOptions: {
        crossOrigin: "anonymous",
        imageSize: 0.5,
        margin: 5
      }
    };

    let newQRCode = new QRCodeStyling(qrCodeOptions);
    newQRCode.applyExtension(extension);

    let downloadProxy = async function(name, asSVG = true) {
      let imageBlob = await newQRCode.getRawData('svg');
      let blobURL;
      let extension = (asSVG) ? 'svg' : 'png';

      //If as a PNG, draw the image onto a canvas and save it in the correct format
      if (!asSVG) {
        let exportCanvas = document.createElement('canvas');
        exportCanvas.width = modifiedWidth;
        exportCanvas.height = modifiedHeight;

        let image = new Image();
        blobURL = URL.createObjectURL(imageBlob);

        await (new Promise((resolve) => {
          image.onload = function() {
            exportCanvas.getContext('2d')?.drawImage(image, 0, 0);
            resolve();
          }

          image.src = blobURL;
        }));

        imageBlob = await (new Promise((resolve) => exportCanvas.toBlob(resolve, `image/${extension}`, 1)));
      }

      await download(i18n, sanitizeFileOrFolderName(`${name}.${extension}`, '_'), imageBlob, imageBlob.type, getPlatforms(), false);

      if (blobURL != null) URL.revokeObjectURL(blobURL);
    }

    return { download: downloadProxy, append: (container) => newQRCode.append(container) };
  }

  const processCode = function(codeObject) {
    let processedCode = {
      processedValue: undefined,
      isInternalCode: false
    }
    
    //TODO Lebensnummern processen
    if (codeObject != null) {
      processedCode.processedValue = codeObject.rawValue;
      if (codeObject.format === 'qr_code' && processedCode.processedValue != null) {
        try {
          //Returns the URL instance, if the origin matches
          let urlInstance = checkForSameOrigin(processedCode.processedValue, BASE_URL, undefined, true);
          if (urlInstance != null) {
            let params = {};

            for (const [key, value] of urlInstance.searchParams.entries()) {
              params[key] = value;
            }

            let newValue = { path: urlInstance.pathname, params };

            let pathComponents = _.compact(_.split(newValue.path, '/'));

            //Valid QR Code with type and value
            if (pathComponents.length != 2) throw 'Invalid code data';

            let [shortType, pathValue] = pathComponents;

            let type = INVERSE_QR_URL_PATH_MAPPING[shortType];

            if (type != null) {
              newValue.shortType = shortType;
              newValue.type = type;

              switch (type) {
                case 'animal':
                  newValue.id = pathValue;
                  break;

                case 'location':
                  newValue.uuid = pathValue;
                  break;

                case 'case-status':
                  newValue.uuid = pathValue;
                  break;

                default:
                  throw 'No valid type';
              }

              let attributeOptions = QR_ATTRIBUTES[type];

              //Map all the attributes with their full paths (hierarchical object)
              if (attributeOptions != null) {
                let newAttributes = {};
                _.forEach(attributeOptions, (attribute) => {
                  if (attribute != null && attribute.path != null && attribute.shortKey != null && attribute.shortKey in newValue.params) {
                    let attributeValue = newValue.params[attribute.shortKey];
                    if (_.isString(attributeValue) && attributeValue.length <= 0) attributeValue = true; //Booleans are saved as empty parameters
                    _.setWith(newAttributes, attribute.path, attributeValue, Object);
                  }
                });

                newValue.attributes = newAttributes;

                newValue.displayParams = localizeAttributes(type, newAttributes);
              }
            }

            processedCode.processedValue = newValue;
            processedCode.isInternalCode = true;
          }
        } catch (error) { //On error reset everything again
          console.error('Error processing QR code', error);
          processedCode.processedValue = codeObject.rawValue;
          processedCode.isInternalCode = false;
        }
      }
    }

    return { ...codeObject, ...processedCode };
  }

  const scanCode = async function(onlyAllowInternalType, onlyAllowInternalCodes = false) {
    return openCameraModal(cameraModalComponent, {
      'automaticCaptureType': 'barcodeScanner',
      'automaticCaptureUseFullsize': false,
      'enableManualCapture': false,
      'enableEditing': false,
      'width': 1080,
      'height': 1080,
      'customTitle': i18n.$t('qr.scan-codes.title'),
      'captureType': 'barcode'
    },
    false,
    false,
    true //Return before dismiss and start processing, not after animation, data is already set!
    ).then((resultData) => {
      if (resultData != null && resultData.data != null) {
        let codes = _.get(resultData.data, ['metadata', 'codes'], []);
        if (codes.length <= 0) return null;

        //Transfer all codes to show details here already
        codes = _.map(codes, (code) => processCode(code));
        if (codes.length == 1) return _.first(codes);
        else {
          return openCodeScannerSelectModal(codeScannerSelectModalComponent, resultData.data.url, codes, _.get(resultData.data, ['metadata', 'scale']))
            .then((scannedCodeResult) => _.get(scannedCodeResult, ['data']));
        }
      }
    })
    .then((scannedCode) => {        
      if (scannedCode != null) {
        let { processedValue, isInternalCode } = scannedCode;
        if (onlyAllowInternalCodes && !(isInternalCode)) {
          console.log('Invalid code scanned. Use internal anirec codes!');
          localErrorToast(i18n, i18n.$t('qr.scan-codes.no_valid_internal_code'));
        } else if (onlyAllowInternalType != null && (processedValue == null || processedValue.type != onlyAllowInternalType)) {
          console.log(`Invalid code type scanned. Expected ${onlyAllowInternalType}, got ${processedValue.type}!`);
          localErrorToast(i18n, i18n.$t('qr.scan-codes.wrong_type.title'),
            `${i18n.$t('qr.scan-codes.wrong_type.scanned')}: ${i18n.$t('qr.type.' + processedValue.type)} | ${i18n.$t('qr.scan-codes.wrong_type.expected')}: ${i18n.$t('qr.type.' + onlyAllowInternalType)}`);
        }
        else return scannedCode;
      }
    }).catch((error) => {
      console.error('Error processing scanned codes', error);
      return null;
    });
    
  }

  return { getQRData, generateQR, processCode, scanCode }
}