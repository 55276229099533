import MediaInfo from 'mediainfo.js';

import mediaInfoLibrary from 'mediainfo.js/dist/MediaInfoModule.wasm';
import { generateRandomHexString } from './algorithms';

import { hashDataToHexString } from '@/utils/algorithms';

import scanFrameIcon from '@/assets/icons/scan-frame-animated.svg';

const ERROR_TIMEOUT = 1500;
// Note from: https://github.com/diachedelic/capacitor-blob-writer
// By choosing a chunk size which is a multiple of 3, we avoid a bug in
// Filesystem.appendFile, only on the web platform, which corrupts files by
// inserting Base64 padding characters within the file. See
// https://github.com/ionic-team/capacitor-plugins/issues/649.
const CHUNK_SIZE = 3 * 128 * 1024; // bytes

//Simple 1x1 transparent PNG
export const TRANSPARENT_PNG = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAAnAAAAJwEqCZFPAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAAtJREFUCJljYAACAAAFAAFiVTKIAAAAAElFTkSuQmCC';

//Always resolves, never should throw an error!
export function getFirstFrameFromVideo(blobURL, mime){
  return new Promise((resolve) => {
    let video = document.createElement('video');
    video.controls = true;
    video.playsInline = true;
    video.muted = true;
    video.crossOrigin = 'anonymous';

    video.onloadeddata = () => {
      video.currentTime = 0.1; //Seek to the beginning
    };
    video.onseeked = () => setTimeout(() => {
      let canvas = document.createElement('canvas');
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        if (blob != null) {
          resolve(URL.createObjectURL(blob));
        } else {
          resolve(null);
        }
      }, 'image/png');
    }, 0);
    video.onerror = () => {
      resolve(null);
    };

    //Timeout to catch other unforeseen incompatibilities
    setTimeout(() => resolve(null), ERROR_TIMEOUT);

    if (mime != null && mime.length > 0 && !video.canPlayType(mime)){
      resolve(null);
    }

    let source = document.createElement('source');

    source.src = blobURL;
    if (mime != null && mime.length > 0) source.type = mime;

    video.appendChild(source);
    video.load();
  });
}

export function splitDataURL(dataUrl) {
  let components = dataUrl.split(',');
  let mimeAndFormat = components[0].match(/:(.*?);(.*?)/);
  return {mime: mimeAndFormat[1], format: mimeAndFormat[2], data: components[1]};
}

/* Creates a base64 encoded version of the blob and calls callback with it */
export function blobToBase64(blob, callback) {
  var reader = new FileReader();
  reader.onload = function() {
      var dataUrl = reader.result;
      var base64 = dataUrl;
      callback(base64);
  };
  reader.readAsDataURL(blob);
}

/**
 * 
 * Based on https://github.com/diachedelic/capacitor-blob-writer
 * 
 */
export function arrayBufferToBase64(buffer) {
  const bytes = new Uint8Array(buffer);
  let binaryString = '';
  for (let byteIndex = 0; byteIndex < bytes.byteLength; byteIndex++) {
    binaryString += String.fromCharCode(bytes[byteIndex]);
  }
  return window.btoa(binaryString);
}

/**
 * 
 * Based on https://github.com/diachedelic/capacitor-blob-writer
 * 
 * Recursive function. Promise is resolved, once all data has been processed.
 * Reads the blob in chunks and returns base64 data in the callback.
 * Callback can return promise that this function will wait for before the next chunk is processed.
 */
export function blobToBase64Chunks(blob, callback) {
  if (blob.size === 0) {
      return Promise.resolve();
  }

  const chunkBlob = blob.slice(0, CHUNK_SIZE);

  // Read the Blob as an ArrayBuffer
  return new window.Response(chunkBlob).arrayBuffer().then(
    function readBufferChunk(buffer) {
      return callback(arrayBufferToBase64(buffer)) //Callback can be a promise
    }
  ).then(function writeRemaining() {
      return blobToBase64Chunks(blob.slice(CHUNK_SIZE), callback);
  });
}

export function rawBinaryToBlob(byteCharacters, contentType) {
  contentType = contentType || '';
  var sliceSize = 1024;
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function base64ToBlob(base64Data, contentType) {
  var byteCharacters = atob(base64Data);
  return rawBinaryToBlob(byteCharacters, contentType);
}

export function getVideoMetadata(videoFile){
  if (videoFile == null) {
    return new Promise((resolve, reject) => reject('No video file provided!'));
  }
  const getSize = () => videoFile.size;
  const readChunk = (chunkSize, offset) =>
    new Promise((resolve, reject) => {
      let blob = videoFile.slice(offset, offset + chunkSize);
      if (blob == null) reject();
      new window.Response(blob).arrayBuffer()
        .catch((error) => reject(error))
        .then((result) => resolve(new Uint8Array(result)));
    }
  );

    return new Promise((resolve, reject) => {
      MediaInfo({locateFile: () => mediaInfoLibrary}).then((mediainfo) => {
        mediainfo
          .analyzeData(getSize, readChunk)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          })
      });
    });      
}

export function getFrameInfoFromMetadata(metadata){
  if (metadata != null && metadata.media != null && Array.isArray(metadata.media.track)){
    for (let track of metadata.media.track){
      if (track.FrameRate != null && track.FrameCount != null) {
        return {
          FrameRate: track.FrameRate,
          FrameCount: track.FrameCount
        };
      }
    }
  }
  return null;
}

export async function processSingleFile(fileToProcess, apiModel, id, filePath, fileToken, multiFileIndex = null) {
  //Don't process invalid files
  if (fileToProcess == null) return null;
  //Create a copy to modify
  let processedFile = {...fileToProcess};
  //Remove unsupported mime types
  if (processedFile.mime != null && processedFile.mime.includes('octet-stream')) processedFile.mime = '';
  //Take either the filename (hash) or a random number to invalidate the cache on change!
  let fileCacheIdentifier = fileToProcess.name || generateRandomHexString(16);

  //If we get a blob provided, use it, otherwise get it from the URL
  if (processedFile.blob) {
    processedFile.blobURL = URL.createObjectURL(processedFile.blob);
    delete processedFile.blob;
  } else if (processedFile.blobURL == null) { //Load only if not loaded already (e.g. from uploadStorage)
    let fileID = `${id}/${encodeURIComponent(filePath)}`;
    //If multiple files, add the index to the path
    if (multiFileIndex != null) {
      let fileIndexPostfix = encodeURIComponent(`[${multiFileIndex}]`);
      fileID = `${fileID}${fileIndexPostfix}`;
    }

    try {
      //Return the file API URL with the given fileToken, if no fileToken is given create URL without
      let fileAPIUrl = new URL(`${apiModel}/file/${fileID}`, process.env.VUE_APP_BACKEND_API_URL);
      if (fileCacheIdentifier != null) fileAPIUrl.searchParams.append('name', fileCacheIdentifier);
      if (fileToken != null) fileAPIUrl.searchParams.append('token', fileToken);
      fileAPIUrl.searchParams.sort();
        //TODO Could sync all reports in background slowly? Then could use this processing function there?

        //Fetch in background and save it to cache //TODO Move to ServiceWorker to work for all files? But need to get token, better here!
        //const cacheKey = fileID;
        /*fetch(fileAPIUrl) //FIXME Fetch does not go into fault when a 404 happens. Do files even need to get loaded here. Only if synced in background maybe?
          .then(fetchResult => fetchResult.blob())
          //.then(blobFile => fileCache.set(cacheKey, blobFile))
          .catch((error) => {
            console.error(error);
          });*/
        
        //Return API url to get access to the file immediately
      processedFile.blobURL = fileAPIUrl.toString();
      //});
    } catch (error) {
      console.error('Could not create media file URL', error);
    }
  }
  //Generate thumbnail for video
  if (processedFile.mime && processedFile.blobURL && processedFile.mime.includes('video')) {
    processedFile.thumbnail = await getFirstFrameFromVideo(processedFile.blobURL, processedFile.mime); 
  }

  return processedFile;
}

/*
* Tries to hash the given blob, resolves with undefined on error
*/
export function hashBlob(blob){
  return new Promise((resolve) => {
    if (blob instanceof Blob) {
      blob.arrayBuffer()
      .then((data) => hashDataToHexString(data))
      .then((hashString) => {
        resolve(hashString);
      })
      .catch(() => resolve(undefined))
    } else {
      resolve(undefined);
    }
  })
}

export async function hashString(textString) {
  const encoder = new TextEncoder();
  const data = encoder.encode(textString)

  return await hashDataToHexString(data).catch(() => undefined);
}

//Takes any element and its size separately
async function elementToScaledBlobOrCanvas(element, scalingOptions = {}, width = 0, height = 0, returnCanvas = false) {
  let { targetWidth, targetHeight, targetScale, minTargetSize, maxTargetSize, keepTransparency } = scalingOptions;

  let scale = 1;

  if (width > 0 && height > 0) {
    //Only take one scaling attribute
    if (targetScale != null) {
      scale = targetScale;
    } else if (targetWidth != null) {
      scale = (targetWidth / width);
    } else if (targetHeight != null) {
      scale = (targetHeight / height);
    } else if (minTargetSize != null) { //Resize the smaller axis
      if (width < height) {
        scale = (minTargetSize / width);
      } else {
        scale = (minTargetSize / height);
      }
    } else if (maxTargetSize != null) { //Resize the bigger axis
      if (width > height) {
        scale = (maxTargetSize / width);
      } else {
        scale = (maxTargetSize / height);
      }
    }
  }

  let scaleCanvas;
  //Use OffscreenCanvas if supported otherwise fallback to regular canvas element
  if (typeof OffscreenCanvas !== 'undefined') {
    scaleCanvas = new OffscreenCanvas(width * scale, height * scale);
  } else {
    scaleCanvas = document.createElement('canvas');
    scaleCanvas.width = (width * scale);
    scaleCanvas.height = (height * scale);
  }

  let context = scaleCanvas.getContext('2d');
  if (!keepTransparency) {
    //First draw a white background for transparency to have no effect
    context.fillStyle = 'white';
    context.fillRect(0, 0, scaleCanvas.width, scaleCanvas.height);
  }

  context.drawImage(element, 0, 0, scaleCanvas.width, scaleCanvas.height);

  if (returnCanvas) {
    return { canvas: scaleCanvas, scale };
  } else { //Else convert to blob
    return new Promise((resolve, reject) => {
      //Add a timeout for waiting for a blob to be returned that rejects this promise
      let blobWaitTimeout = setTimeout(() => {
        reject('No blob received in time');
      }, 5000);
        
      if (typeof OffscreenCanvas !== 'undefined' && scaleCanvas instanceof OffscreenCanvas) {
        scaleCanvas.convertToBlob({ type: 'image/png' }).then((blob) => {
          clearTimeout(blobWaitTimeout);
          resolve({blob, scale});
        }).catch(() => reject('Invalid canvas'));
      } else if (scaleCanvas instanceof HTMLCanvasElement) {
        scaleCanvas.toBlob((blob) => {
          clearTimeout(blobWaitTimeout);
          resolve({blob, scale});
        }, 'image/png');
      }
    });
  }
}

export async function videoElementToScaledBlobOrCanvas(videoElement, scalingOptions, returnCanvas = false) {
  if (videoElement == null) throw 'Missing video element';

  return elementToScaledBlobOrCanvas(videoElement, scalingOptions, videoElement.videoWidth, videoElement.videoHeight, returnCanvas);
}

export async function imageElementToScaledBlobOrCanvas(imageElement, scalingOptions, returnCanvas = false) {
  if (imageElement == null) throw 'Missing image element';

  return elementToScaledBlobOrCanvas(imageElement, scalingOptions, imageElement.width, imageElement.height, returnCanvas);
}

export async function rescaleImageElement(imageElement, scalingOptions){
  return new Promise((resolve, reject) => {

    imageElement.addEventListener('load', () => {
      imageElementToScaledBlobOrCanvas(imageElement, scalingOptions).then((blob) => resolve(blob));
    });
    imageElement.addEventListener('error', (error) => reject(error));
  });
}

export async function rescaleBlobImage(imageBlob, scalingOptions){
  let imageBlobURL = URL.createObjectURL(imageBlob);
  let image = new Image();
  image.crossOrigin = 'Anonymous';

  let rescalePromise = rescaleImageElement(image, scalingOptions);
  
  image.src = imageBlobURL;
  rescalePromise.finally(() => {
    if (imageBlobURL != null) URL.revokeObjectURL(imageBlobURL);
  });

  return rescalePromise;
}


const BASE_SRC = '/assets/camera_templates/';

export function getCameraTemplate(type) {
  switch (type) {
    case 'eye':
      return {
        src: `${BASE_SRC}/eye_template.svg`,
        preferLandscape: false //TODO Set to true once the editing and selection of still frames can be rotated as well
      };
    case 'barcode':
      return {
        icon: scanFrameIcon,
        color: 'primary'
      }
  }

  return null;
}