import { api, createStorage } from '@/utils/api';
import { default as dayjs, getTimespanListInRange } from '@/utils/dayjs';

import { Drivers } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
//memoryDriver is Fallback option to save to RAM in case the memory in other drivers is not enough. Gets deleted on app restart or when browser needs memory!
import memoryDriver from 'localforage-memoryStorageDriver';

import { processSingleFile } from '@/utils/media';

import { isReportTypeVisibleForExistingReports, MULTIPLE_ANIMALS_IN_CONNECTED_SEPARATOR, METADATA_SHORT_KEY_MAPPING, REPORT_INDEX_SHORT_KEY_MAPPING, ANALYSIS_FILE_ATTRIBUTES, mergeWithArrays, mergeWithArraysUnion, generateIdentifiersForReportType } from '@/utils/report';

import _ from 'lodash';

const reportIndexStorage = createStorage({
    name: '__report_index',
    driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, /* Drivers.LocalStorage, */ memoryDriver._driver] //Skip localStorage, as index grows too big!
  },
  [CordovaSQLiteDriver, memoryDriver]
);

const REPORT_INDEX_FETCH_ROUTE = '/reports';

const REPORT_DETAIL_PATHS = ['type', 'timestamp', 'horse'];
const CONNECTION_DETAIL_PATHS = ['reports'];

const clearReportIndex = function() {
  return reportIndexStorage.clear()
  .then(() => api.clearRouteMetadata(REPORT_INDEX_FETCH_ROUTE)); //Clear route metadata for reports, to fetch them freshly again!
}

const MODULE_VERSION = 14; //UPDATE VERSION WITH EVERY BREAKING CHANGE OR ATTRIBUTE REMOVAL

const verifyDay = (newDay) => {
  let date = dayjs.utc(newDay, 'DD.MM.YYYY');
  //If date is invalid set to current day instead
  if (date == null || !(date.isValid())) {
    date = dayjs.utc();
  }
  return date.format('DD.MM.YYYY');
}

const createMonthObject = (newMonth) => {
  let date = dayjs.utc(newMonth, 'MM.YYYY');
  //If date is invalid set to current month instead
  if (date == null || !(date.isValid())) {
    date = dayjs.utc();
  }
  return {
    month: date.format('MM.YYYY'),
    start: date.startOf('month').format('DD.MM.YYYY'),
    end: date.endOf('month').format('DD.MM.YYYY')
  }
}

//Apply a filter for a single report - id can be null, then searchedIDs won't be checked (e.g. for currently uploading reports) - searchedIDs is an object with search score as a value
const applyReportFilters = function(id, report, filters, searchedIDs = null) {
  //When e.g. a search was performed, check if this report is included in the object of searchedIDs - return false if not, otherwise continue with other checks!
  if (searchedIDs != null) {
    if (id != null && !(id in searchedIDs)) return false;
  }

  //Check all filters - if one is not matched, return false
  for (let [attribute, filter] of Object.entries(filters)) {
    if (report != null && filter != null) {
      let value;

      //Handle special cases
      switch (attribute) {
        case 'category':
          if (report.type != null) {
            value = report.type.main_category;
          }
          break;
        case 'stage':
          if (report.type != null) {
            if (report.type.examination_stage != null) {
              value = report.type.examination_stage.descriptor;
            } else { //Not set examination_stage -> null stage
              value = null;
            }
          }
          break;

        default:
          value = report[attribute];
          break;
      }

      //Convert to string if null to handle this special case
      if (value === null) value = 'null';

      if (Array.isArray(filter)) {
        if (!(_.flattenDeep(filter).includes(value))) return false;
      } else {
        if (value != filter) return false;
      }
    }
  }
  return true;
}

const processSingleReportFile = async function(file, reportId, componentKey, fileToken, componentFileIndex = null) {
  //If index is not null, we have multiple files, so add a different attribute at the end
  const filePath = `fields.${componentKey}.${(componentFileIndex != null) ? 'files' : 'file'}`;
  return await processSingleFile(file, 'reports', reportId, filePath, fileToken, componentFileIndex);
}

const processSingleAnalysisFile = async function(file, analysisId, resultIndex, fileAttribute, fileToken, multiFileIndex = null) {
  //If index is not null, we have multiple files, so add a different attribute at the end
  const filePath = `result[${resultIndex}].${fileAttribute}`;
  return await processSingleFile(file, 'analyses', analysisId, filePath, fileToken, multiFileIndex);
}

const getDefaultState = () => {
  return {
    version: undefined,
    selectedDay: verifyDay(), //Default is today
    selectedTimespanDay: null, //By default only a single day is selected
    selectedMonth: createMonthObject(), //Default is this month
    //Report index is outsourced as it grows too big. Just metadata about the reports in the index is stored here!
    mostRecentReportUpdate: null, //TODO Use this to always update the index below, on any change! Refer to it in the getter to recompute, where it is fetched from the storage. Or maybe not needed, when it does not affect the index! Should be though, to get new reports! Now it is referenced somewhere else, could be referenced later. Because metadata beforehand always gets checked for updates! Only storage does not update automatically.
    reportMetadataIndex: {},

    filters: {},

    reportTypeIndex: {}, //TODO When we only give the most current version of each type, should we remove older versions here and from cache?
    mainCategories: {}
  }
};

export default {
  namespaced: true,

  state: getDefaultState,
  getters: {
    getLocationValues () {
      return ['clinic', 'stable'];
    },
    getUploadStatus(state, getters, rootState, rootGetters) {
      let user = rootGetters['auth/getUser'];
      return _.get(api.getUploadIndex().value, [_.get(user, 'id'), 'byRoute', '/reports']);
    },
    getConnectionUploadStatus(state, getters, rootState, rootGetters) {
      let user = rootGetters['auth/getUser'];
      return _.get(api.getUploadIndex().value, [_.get(user, 'id'), 'byRoute', '/reports/connection']);
    },
    //Getters for the report index are computed in muliple steps to load as little as possible, especially from storage!
    //Creates a local index of all reportIDs sorted by month and day in the month based on the users timezone (Days are just with the day number, month is inferred by parent object in hierarchy)
    getLocalizedReportTimestampIndex (state, getters) {//TODO Check that timezone change is detected and correctly adjusted at the borders of months!
      let reportIDs = Object.keys(state.reportMetadataIndex);
      console.log('Recalculating INDEX!!'); //FIXME Called even when just opening and closing the calendar!

      //If no reports, give back empty index. Referencing the most recent update also enables recalculating the index when reports should change.
      if (state.mostRecentReportUpdate == null || reportIDs.length <= 0) return {};

      let reportIndexByLocalDate = {};

      let uploadingConnections = getters['getConnectionUploadStatus'];
      let uploadingConnectionsByKey = _.keyBy(uploadingConnections, 'key');
      //Invert the array of uploadingConnections to get a mapping function from report upload key to connection upload key (Each element in the array of connected reports becomes a key and the upload status key is set as the value)
      let uploadingConnectionsMapper = _.transform(uploadingConnections, function(result, uploadingConnection) {
        if (uploadingConnection != null && uploadingConnection.key != null) {
          let connectedReports = _.get(uploadingConnection, ['status', 'detail', 'reports']);
          if (Array.isArray(connectedReports)) {
            for (let connectedReport of connectedReports) {
              (result[connectedReport] || (result[connectedReport] = [])).push(uploadingConnection.key); //Either take the array inside the accumulator or create a new one at the key (being the value)
            }
          }
        }
      }, {});

      let uploadingIndex = getters['getUploadStatus'];
      let mappedUploadingIndex = [];
      let updatedOrDeletedIDs = {};
      if (uploadingIndex != null) {
        //Convert all status index objects to objects with metadata
        _.forEach(uploadingIndex, (statusObject) => {
          let uploadKey = _.get(statusObject, 'key');
          //TODO Filter out invisible types, either if type is set, get it with the ID, or if it is an update and no type is set, use the hidden attribute there
          let metadata = _.get(statusObject, ['status', 'detail']); //Never save status here. The changes of each variable are watched and it causes the index to recalculate!
          
          let updatesOrDeletes = _.get(statusObject, 'updates') || _.get(statusObject, 'deletes'); //Take the first non null ID to set
          if (updatesOrDeletes != null) updatedOrDeletedIDs[updatesOrDeletes] = uploadKey;

          let finishedID = _.get(statusObject, ['status', 'finishedID']);
          //Exclude if it finished uploading and has been added to the index, to show the newest status and not show it twice!
          if (finishedID != null && finishedID in state.reportMetadataIndex) return; //TODO Show status in Tracking page still, even after upload

          let timestamp = _.get(metadata, ['timestamp']);
          //Timestamp has to be set, otherwise it can't be added to the index
          if (timestamp == null) return;
          mappedUploadingIndex.push({
            timestamp,
            //Try to get connections, always as an array. If it is inside the upload mapping it is returned, otherwise it is just undefined
            connections: _.get(uploadingConnectionsMapper, _.get(statusObject, 'key'), undefined), //TODO Also applies to existing reports that the connection changes! TEST that it does!
            //Convert utc timestamp to local time object
            localtime: dayjs((new Date(timestamp))), //TODO Check that it changes on timezone change!
            //The value that will be set in the index. For reports that are uploading it always is the uploading metadata as an object. This way it will be detected by the following functions
            indexValue: {
              id: _.get(statusObject, 'key'),
              ...metadata,
              uploadStatus: _.omit(statusObject, 'status')
            }
          });
        });
      }

      let reportMetadataObjects = _.map(reportIDs, (reportID) => {
        //Filter reports by all attributes that excludes them. Return null, if excluded!
        //Exclude hidden
        if (state.reportMetadataIndex[reportID][METADATA_SHORT_KEY_MAPPING['hidden']] != null) return null;
        //Exclude updated
        if (state.reportMetadataIndex[reportID][METADATA_SHORT_KEY_MAPPING['updated']] != null) return null;
        //Exclude if currently being deleted or updated
        if (reportID in updatedOrDeletedIDs) return null;

        let timestamp = state.reportMetadataIndex[reportID][METADATA_SHORT_KEY_MAPPING['timestamp']];
        return {
          timestamp,
          //Get connections, always as an array 
          connections: _.castArray(state.reportMetadataIndex[reportID][METADATA_SHORT_KEY_MAPPING['connections']]),
          //Convert utc timestamp to local time object
          localtime: dayjs((new Date(timestamp))), //TODO Check that it changes on timezone change!
          //The value that will be set in the index. For reports that have to be loaded it is the reportID
          indexValue: reportID
        }
      });

      reportMetadataObjects = _.concat(reportMetadataObjects, mappedUploadingIndex);

      //Remove invalid objects
      reportMetadataObjects = _.compact(reportMetadataObjects);

      for (let reportMetadataObject of reportMetadataObjects) {
        //Skip invalid entries or entries that are not present anymore
        if (reportMetadataObject.timestamp == null) continue;

        let month = reportMetadataObject.localtime.format('MM.YYYY');
        if (!(month in reportIndexByLocalDate)) { //Add an empty object (bucket), if no one exists for this month
          reportIndexByLocalDate[month] = {};
        }
        
        let day = reportMetadataObject.localtime.format('DD');
        if (!(day in reportIndexByLocalDate[month])) { //Add an empty object for the single reports and connections, if no one exists for this day
          reportIndexByLocalDate[month][day] = {
            [REPORT_INDEX_SHORT_KEY_MAPPING['connections']]: {}, //TODO Currently uploading ones also need to be treated as connected, if they will be connected. So get the connecting index from API too!
            [REPORT_INDEX_SHORT_KEY_MAPPING['reports']]: []
          };
        }

        //Only use connections with at least one valid connection ID
        reportMetadataObject.connections = _.filter(reportMetadataObject.connections, (connectionID) => (_.isNumber(connectionID) || (_.isString(connectionID) && connectionID.length >= 1)));

        //Add to connections if there is at least one valid one
        if (reportMetadataObject.connections.length >= 1) {
          //Go through all connections, and add each one once
          for (let connectionID of reportMetadataObject.connections) {
            //If the connections does not exist for this day yet, add an empty array for the reports in this connection
            if (!(connectionID in reportIndexByLocalDate[month][day][REPORT_INDEX_SHORT_KEY_MAPPING['connections']])) {
              reportIndexByLocalDate[month][day][REPORT_INDEX_SHORT_KEY_MAPPING['connections']][connectionID] = { [REPORT_INDEX_SHORT_KEY_MAPPING['reports']]: [], [REPORT_INDEX_SHORT_KEY_MAPPING['uploadStatus']]: _.get(uploadingConnectionsByKey, connectionID, undefined)};
            }
            reportIndexByLocalDate[month][day][REPORT_INDEX_SHORT_KEY_MAPPING['connections']][connectionID][REPORT_INDEX_SHORT_KEY_MAPPING['reports']].push(reportMetadataObject.indexValue);
          }
        } else { //Otherwise add it to the array of unconnected reports
          reportIndexByLocalDate[month][day][REPORT_INDEX_SHORT_KEY_MAPPING['reports']].push(reportMetadataObject.indexValue);
        }
      }

      return reportIndexByLocalDate;
    },
    //Returns all days of the current month in an object as keys (in the format DD.MM.YYYY) with the report counts as values
    getLocalizedReportCountsForCurrentSelectedMonth (state, getters) {
      //Get the prepared index of reports using a getter
      let localizedReportsByDate = getters['getLocalizedReportTimestampIndex']; //TODO Make another getter for currently uploading ones (including already all the metadata). And merge them after selectedMonth has been filtered
      let currentSelectedMonth = (state.selectedMonth != null) ? state.selectedMonth.month : null;
      //If the selected month should be invalid or is not in the report index, return an empty index
      if (currentSelectedMonth == null || !(currentSelectedMonth in localizedReportsByDate)) return {};

      //Only take the report count for each key. Add the unconnected reports and connections together (Each connection is counted as one report!)
      let mappedDays = _.mapValues(localizedReportsByDate[currentSelectedMonth], (reports) => (reports[REPORT_INDEX_SHORT_KEY_MAPPING['reports']].length) + (Object.keys(reports[REPORT_INDEX_SHORT_KEY_MAPPING['connections']])).length)
      //Merge month and day in the keys and return the result
      return _.mapKeys(mappedDays, (value, day) => `${day}.${currentSelectedMonth}`); //Adds DD and MM.YYYY together
    },
    getReportIDsForSelectedTimespan (state, getters) { //Recalculated when the index or upload index changes
      //Get the prepared index of reports using a getter
      let localizedReportsByDate = getters['getLocalizedReportTimestampIndex'];

      let newIndex = {};

      if (state.selectedDay != null){
        let timeRange = {
          start: dayjs(state.selectedDay, 'DD.MM.YYYY'),
          end: (state.selectedTimespanDay != null) ? dayjs(state.selectedTimespanDay, 'DD.MM.YYYY') : null
        }

        timeRange.startMonth = timeRange.start.format('MM.YYYY');
        timeRange.startDay = timeRange.start.format('DD');
        timeRange.startDayInt = parseInt(timeRange.startDay);

        //If no end is set for the range, just get the reports for the specified month and day
        if (timeRange.end == null) {
          //Get the reports for this day, if none exist, don't set in the new index
          let reports = _.get(localizedReportsByDate, [timeRange.startMonth, timeRange.startDay]);
          if (reports != null) _.setWith(newIndex, [timeRange.startMonth, timeRange.startDay], reports, Object); //TODO Day could be adjusted when setting to output format 'DD.MM.YYYY', by combining them, if needed
        } 
        //If an end is set, create array of all months and filter quickly based on included months
        else {
          timeRange.endMonth = timeRange.end.format('MM.YYYY');
          timeRange.endDay = timeRange.end.format('DD');
          timeRange.endDayInt = parseInt(timeRange.endDay);

          //Get all months as an array of strings in the specified range
          timeRange.months = getTimespanListInRange(timeRange.start, timeRange.end, 'month', 'MM.YYYY', '[]');

          //Filter the object to only include the months (keys as MM.YYYY), if they are in between the selected range (included in the months array)
          for (let [month, days] of Object.entries(_.pick(localizedReportsByDate, timeRange.months))) {
            //If it is the first or last month, or both, only select days after and the start day / before the end day. Including the start and end days.
            if (month === timeRange.startMonth || month === timeRange.endMonth) {
              for (let [day, reports] of Object.entries(days)) {
                let dayInt = parseInt(day);
                //Check for start and end separately if they are included, might be both applicable, when just one month is selected
                //Include either if the corresponding filter doesn't apply (either not start or end month) or if it is inside that filtered range
                let includeByStart = (month !== timeRange.startMonth || dayInt >= timeRange.startDayInt);
                let includeByEnd = (month !== timeRange.endMonth || dayInt <= timeRange.endDayInt);
                //Compare each day by the numerical value to avoid complex date. Both inclusion checks need to be true!
                if (includeByStart && includeByEnd) _.setWith(newIndex, [month, day], reports, Object); //TODO Day could be adjusted when setting to output format 'DD.MM.YYYY', by combining them, if needed
              }
            }
            //If it is neither start nor end month, it is in between and we do not need to filter days by date and can include them all
            else {
              _.setWith(newIndex, [month], days, Object); //TODO Day could be adjusted when setting to output format 'DD.MM.YYYY', by combining them, if needed. Would have to be a loop to map all the days!
            }
          }
        }
      }

      return newIndex;
    },
    getFilters (state) {
      return state.filters;
    },
    getReportIndexFilterAsyncFunction (state, getters, rootState, rootGetters) { //Recalculated when the filtered IDs change or the filters change
      //Get the prepared index of report IDs in the selected timespan using a getter
      const reportIdIndex = getters['getReportIDsForSelectedTimespan'];
      const horseToHorseInfoIDMapping = rootGetters['horses/getHorses'];

      //Return a async function to get all the reports from store asynchronously and filter them
      return async (filters, searchedIDs) => {
        let newIndex = {};
        let usedReportTypeMetadata = {};

        //Create a local function that has access to the new indizes to filter multiple arrays of reports with the same logic
        const filterReportArray = async function(reports) {
          let reportsByHorse = {};
          let reportTypeMetadata = { 'byHierarchy': {}, 'mainCategories': [] };

          if (Array.isArray(reports)) {
            for (let report of reports) {
              let reportObject;
              //If it is a number or string, it is considered an ID, try to fetch from storage
              if (_.isNumber(report) || _.isString(report)) {
                reportObject = await reportIndexStorage.get(report); //TODO Possibly move gettings of the reports from storage to its own getter in the chain before it, if too expensive on every filter change! Now it saves on memory, but redoes it every time (might be better to avoid crashes!)!
              }
              //Otherwise it already is a full report Object and can be treated as such
              else {
                reportObject = report;
              }

              //Don't process invalid reports further
              if (reportObject == null) continue;

              //If the horse exists and is set, try to map it to the personal horse ID
              let personalHorseID = null;
              if (reportObject.horse != null && reportObject.horse in horseToHorseInfoIDMapping) {
                personalHorseID = horseToHorseInfoIDMapping[reportObject.horse];
              }

              if (applyReportFilters(reportObject.id, reportObject, filters, searchedIDs)) {
                //TODO Only save as much as needed, not the whole report object
                //Add an empty array if it does not exist yet for this horse to push the reports
                if (!(personalHorseID in reportsByHorse)) {
                  reportsByHorse[personalHorseID] = [];
                }
                //Add reports per horse as an array to not get any overlap 
                reportsByHorse[personalHorseID].push(reportObject);

                //Add type identifier for metadata if it is valid
                if (reportObject != null && reportObject.type != null && reportObject.type.descriptor != null) {
                  //First initialize the examination_stage with the order property
                  let examinationStage = reportObject.type.examination_stage;
                  let examinationStageDescriptor = (examinationStage != null) ? examinationStage.descriptor || 'null' : 'null';
                  if (!(examinationStageDescriptor in reportTypeMetadata['byHierarchy'])) {
                    reportTypeMetadata['byHierarchy'][examinationStageDescriptor] = {
                      'order': (examinationStage != null) ? examinationStage.order : undefined,
                      'categories': {}
                    }
                  }

                  //Then initialize the sub_category inside the examination stage with the order property
                  let subCategory = reportObject.type.sub_category;
                  let subCategoryDescriptor = (subCategory != null) ? subCategory.descriptor || 'null' : 'null';
                  if (!(subCategoryDescriptor in reportTypeMetadata['byHierarchy'][examinationStageDescriptor]['categories'])) {
                    reportTypeMetadata['byHierarchy'][examinationStageDescriptor]['categories'][subCategoryDescriptor] = {
                      'order': (subCategory != null) ? subCategory.order : undefined,
                      'types': {}
                    }
                  }

                  _.updateWith(reportTypeMetadata, [
                      'byHierarchy', 
                      examinationStageDescriptor,
                      'categories',
                      subCategoryDescriptor,
                      'types',
                      reportObject.type.descriptor
                    ], (types) => {
                    return {
                      order: reportObject.type.order,
                      identifiers: _.union((types != null) ? types['identifiers'] : [], [reportObject.type.identifier])
                    }
                  }, Object); //TODO What if the report type is moved to a different category. Should be not possible, because immutable?
                  //TODO Check if same name, different versions show as different or same!

                  if (reportObject.type.main_category) reportTypeMetadata['mainCategories'] = _.union(reportTypeMetadata['mainCategories'], [reportObject.type.main_category]);
                }
              }
            }
          }

          return { reports: reportsByHorse, metadata: reportTypeMetadata };
        }

        for (let [month, days] of Object.entries(reportIdIndex)) {
          for (let [day, reportIndizes] of Object.entries(days)) {
            let reportsByHorsesForCurrentDay = {};

            //First filter and set all unconnected reports and set the horses, merging into existing ones by concatenating the arrays.
            let filteredUnconnectedReports = await filterReportArray(reportIndizes[REPORT_INDEX_SHORT_KEY_MAPPING['reports']]);
            _.mergeWith(reportsByHorsesForCurrentDay, filteredUnconnectedReports.reports, mergeWithArrays);
            _.mergeWith(usedReportTypeMetadata, filteredUnconnectedReports.metadata, mergeWithArraysUnion);

            //Then filter and set all the reports inside the connections, merging into existing ones by concatenating the arrays.
            for (let [connectionID, connection] of Object.entries(reportIndizes[REPORT_INDEX_SHORT_KEY_MAPPING['connections']])) {
              let connectedReports = connection[REPORT_INDEX_SHORT_KEY_MAPPING['reports']];
              let uploadStatus = connection[REPORT_INDEX_SHORT_KEY_MAPPING['uploadStatus']];
              let filteredConnectedReports = await filterReportArray(connectedReports);

              _.mergeWith(usedReportTypeMetadata, filteredConnectedReports.metadata, mergeWithArraysUnion);
              //Only continue processing this connection, if any of the reports in it remain.
              if (Object.keys(filteredConnectedReports.reports).length >= 1) {
                //Create a string of all horses in this connection (Should always be just one, in this case, just returns the one ID).
                //If multiple horse exist in one connection, this ensures a unique ID
                let horseIdentifier = _.join(Object.keys(filteredConnectedReports.reports), MULTIPLE_ANIMALS_IN_CONNECTED_SEPARATOR);

                //Get all reports independent of the horse as a flat array to map it to timestamps
                let allReports = _.flatten(Object.values(filteredConnectedReports.reports));

                //Create a unique list of only the timestamps, sorted
                let timestamps = _.uniq(_.map(allReports, 'timestamp')).sort();
            
                //Set this connection with its ID in an array under this horseIdentifier as the key, to correctly merge with existing horses
                _.mergeWith(reportsByHorsesForCurrentDay, {
                  [horseIdentifier]: [
                    {
                      'id': connectionID,
                      'timestamps': timestamps, //Give back all the encountered unique timestamps as an array for sorting
                      'reports': filteredConnectedReports.reports,
                      'connection': true, //Set flag to separate connections from unconnected reports,
                      'uploadStatus': (uploadStatus != null) ? uploadStatus : undefined //Contains the uploadStatus if set or undefined
                    }
                  ]
                }, mergeWithArrays);
              }
            }

            //Set the reports from both connected and unconnected processing, only if any reports remain. Otherwise this day stays empty, if none got added.
            if (Object.keys(reportsByHorsesForCurrentDay).length >= 1) {
              _.setWith(newIndex, [month, day, 'horses'], reportsByHorsesForCurrentDay, Object);
            }
            
            //Count all reports and connections (Each connection counts as one, even if multiple reports are part of it)
            let reportCountInDay = _.sumBy(Object.values(_.get(newIndex, [month, day, 'horses'], {})), (reportsForHorse) => {
              return Object.keys(reportsForHorse).length;
            });

            //Only add if there is at least one report, otherwise this day is never added
            if (reportCountInDay > 0) {
              _.setWith(newIndex, [month, day, 'count'], reportCountInDay, Object);
            }
          }
        }

        return { index: newIndex, reportTypes: usedReportTypeMetadata};
      };

      
      //TODO Make list in UI infinite list to improve scrolling with many reports!

    },
    getReportConnections (state, getters) {
      //Save an array of report IDs for each connection ID
      let connections = {};
      
      //Go through all reports
      for (let [reportID, metadata] of Object.entries(state.reportMetadataIndex)) {
        //If thje connections property is valid, process all possible connections
        if (Array.isArray(metadata[METADATA_SHORT_KEY_MAPPING['connections']])) {
          for (let connectionID of metadata[METADATA_SHORT_KEY_MAPPING['connections']]) {
            if (connectionID != null) {
              //If this connection ID is not present yet, create an array for it
              if (!(connectionID in connections)) connections[connectionID] = { reports: [] };
              //Add this report ID to this connection ID
              connections[connectionID].reports.push(reportID);
            }
          }
        }
      }

      let uploadingConnections = getters['getConnectionUploadStatus'];
      if (uploadingConnections != null) {
        //Go through currently uploading connections and add them too
        for (let uploadingConnection of uploadingConnections) {
          if (uploadingConnection != null && uploadingConnection.key != null) {
            let connectedReports = _.get(uploadingConnection, ['status', 'detail', 'reports']);
            if (Array.isArray(connectedReports)) connections[uploadingConnection.key] = { reports: connectedReports, status: uploadingConnection.status };
          }
        }
      } 

      return connections;
    },
    getReportIDsInConnection (state, getters) {
      return function(connectionID) {
        //Try to fetch it from the object of all connections
        let connectedReports = getters['getReportConnections'][connectionID];
        //Return the array of reports or an empty array if not found
        return connectedReports || { reports: [] };
      }
    },
    getRelatedReportList (state, getters) { //Get the report chain the given one updates or null if none is updated by this one
      //Get the uploading index and map it to an object by key to look for any relations
      let uploadingIndex = _.keyBy(_.map(getters['getUploadStatus'], (uploadStatus) => {
        //Only take the properties necessary for this lookup
        return {
          key: uploadStatus.key,
          deletes: uploadStatus.deletes,
          [METADATA_SHORT_KEY_MAPPING['updates']]: uploadStatus.updates //Map updates to the short key for further processing
        }
      }), 'key');
      //Also create objects for all the IDs that are currently either being updated and should be looked up or deleted and should not be included!
      let currentUpdatingIDs = _.keyBy(_.filter(uploadingIndex, (uploadStatus) => uploadStatus[[METADATA_SHORT_KEY_MAPPING['updates']]] != null), METADATA_SHORT_KEY_MAPPING['updates']);
      let currentDeletingIDs = _.keyBy(_.filter(uploadingIndex, (uploadStatus) => uploadStatus.deletes != null), 'deletes');

      return function(id) {
        let searchedId = id;
        let foundReport = null;
        let currentFoundId = null;
        let reportUpdateChain = [];

        let moveUp = true; //Move first up through the update chain to get the most recent report

        do {
          if (searchedId != null) {
            foundReport = state.reportMetadataIndex[searchedId];
            //If it can't be found in the normal metadata index, check the uploading index
            if (foundReport == null) {
              foundReport = uploadingIndex[searchedId]; //Never has updated set, only updates or deletes
              if (foundReport != null && foundReport.deletes != null) foundReport = null; //If it is a current deletion, remove this report
            }
          } else {
            foundReport = null;
          }

          if (foundReport != null) {
            //If found, save this ID
            currentFoundId = searchedId;
            if (moveUp) {
              searchedId = foundReport[METADATA_SHORT_KEY_MAPPING['updated']];
              //If updated is not set, also check if it is currently being updated by an upload, then we still need to move up to that report as it should be part of the chain
              if (searchedId == null && currentFoundId in currentUpdatingIDs) {
                searchedId = currentUpdatingIDs[currentFoundId].key;
              }
              if (searchedId == null || searchedId in currentDeletingIDs) { //If we have reached the top of the chain, set this to be next one to search for and move down. If the next one to look for will be deleted, treat current one as top
                searchedId = currentFoundId;
                moveUp = false;
              }
            } else { 
              reportUpdateChain.push({id: currentFoundId, ...foundReport});
              searchedId = foundReport[METADATA_SHORT_KEY_MAPPING['updates']];
            }
          }
        } while (foundReport != null);

        if (reportUpdateChain.length <= 1) return null; //If the only report in the list is the given one, we also have no related reports - might happen because of deleted ones
        return reportUpdateChain;
      }
    },
    getReportMetadataById (state) {
      return function(id) {
        return state.reportMetadataIndex[id];
      }
    },
    getFullReportMetadataById (state) {
      //If no reports or not in index, give back undefined. Referencing the most recent update also enables reactive effects when reports should change.
      if (state.mostRecentReportUpdate == null || state.reportMetadataIndex.length <= 0) return async function() { return undefined };

      return async function(id) {
        if (!(id in state.reportMetadataIndex)) return undefined;
        return await reportIndexStorage.get(id);
      }
    },
    getReportTypeIndex (state) {
      return state.reportTypeIndex;
    },
    getHierarchicalReportTypeIndex (state) {
      let hierarchicalIndex = {};
      if (state.reportTypeIndex != null) {
        //Go through the index object and add every type that has a category path defined to set its hierarchy
        for (let [key, type] of Object.entries(state.reportTypeIndex)) {
          if (Array.isArray(type.category_hierarchy)) {
            let currentHierarchyLevel = hierarchicalIndex;
            //Go through each level of the current hierarchy and set it
            for(let pathComponent of type.category_hierarchy) {
              //Create for each level in the hierarchy if it does not exist yet an object with an empty entries object for further levels, so it can also hold name and other metadata about each level
              if (!(pathComponent.descriptor in currentHierarchyLevel)) {
                currentHierarchyLevel[pathComponent.descriptor] = {
                  entries: {}
                };
              }

              //Set an optional name component for a display name, if it is set in the pathComponent
              if (pathComponent.name != null) currentHierarchyLevel[pathComponent.descriptor]['name'] = pathComponent.name;

              //Set the entries of this level as the base level for the next loop
              currentHierarchyLevel = currentHierarchyLevel[pathComponent.descriptor].entries;
            }

            //In the last level (key) set the type
            currentHierarchyLevel[key] = type;
          }
        }
      }
      return hierarchicalIndex;
    },
    getReportTypesByIdentifiers (state) {
      //Parameter can be any single identifier or a list of identifiers
      return function(identifiers) {
        let identifiersToFind = identifiers;
        let foundIdentifiers = {};
        //If parameter is not an array of identifiers, convert to one for ease of use
        if (!Array.isArray(identifiers)) identifiersToFind = [identifiers];
        //Convert identifiers to string for more reliable comparison!
        identifiersToFind = _.map(identifiersToFind, (identifier) => String(identifier));
        if (state.reportTypeIndex != null) {
          for (let type of Object.values(state.reportTypeIndex)) {
            //If the identifier was found add it to the object with the identifier that was used to find it being the key
            let matchingIdentifiers = _.intersection(Object.values(type.identifiers), identifiersToFind);
            for (let matchingIdentifier of matchingIdentifiers) {
              foundIdentifiers[matchingIdentifier] = type;
            }
          }
        }

        //If it wasn't an array in the parameters, just return the found one!
        return (!Array.isArray(identifiers)) ? foundIdentifiers[identifiers] : foundIdentifiers;
      }
    },
    getSelectedDay (state) {
      return state.selectedDay;
    },
    getSelectedTimespanDay (state) {
      return state.selectedTimespanDay;
    },
    getSelectedMonth (state) {
      return state.selectedMonth;
    },
    getMainCategories (state) {
      return state.mainCategories;
    },
    getMainCategoryNamesById (state) {
      return function(id) {
        if (id != null && state.mainCategories[id] != null){
          return state.mainCategories[id].name;
        } else {
          return null;
        }
      }
    },
    getMainCategoryNamesOfReportTypeId (state, getters) {
      return function(id) {
        let reportType = getters['getReportTypesByIdentifiers'](id);
        if (reportType != null) return getters['getMainCategoryNamesById'](reportType['main_category']);
        return null;
      }
    },
    getStorageInfos () {
      let storageInfo = reportIndexStorage.keys().then((keys) => (keys != null) ? keys.sort() : []).catch(() => [])
        .then((keys) => {
          return {
            name: reportIndexStorage.config.name,
            driver: reportIndexStorage.driver,
            keys 
          };
        });

      return storageInfo;
    }
    /*getReportUploadStatus (state) {
      return state.reportUploadStatus;
    },
    getReportUploadStatusUnfinished (state) {
      return ((state.reportUploadStatus != null) ? _.pickBy(state.reportUploadStatus, (value) => (value.finishedID == null)) : state.reportUploadStatus);
    },
    getReportsBeingUpdated (state) {
      if (state.reportUploadStatus == null) {
        return [];
      } else {
        //Create an array of all the ids that are in the upload status
        return Object.values(state.reportUploadStatus).map((status) => {
          return status.id;
        }).filter((id) => (id != null));
      }
    },
    */
  },
  mutations: {
    setSelectedDay (state, newDay) {
      state.selectedDay = verifyDay(newDay);
    },
    setSelectedTimespanDay (state, newDay) {
      if (newDay != null) {
        let newDateObject = dayjs.utc(newDay, 'DD.MM.YYYY');
        let selectedDayObject = dayjs.utc(state.selectedDay, 'DD.MM.YYYY');
        if (newDateObject != null && state.selectedDay != null && selectedDayObject.isSameOrBefore(newDateObject, 'day')) 
          state.selectedTimespanDay = newDay;
      } else {
        state.selectedTimespanDay = null;
      }
    },
    setSelectedMonth (state, newMonth) {
      state.selectedMonth = createMonthObject(newMonth);
    },
    setFilters (state, filters) {
      state.filters = filters;
    },
    addReportIndexMetadata (state, newReportEntries) {
      let mostRecentUpdate = state.mostRecentReportUpdate;
      //Save temporarily all new metadata and add all of them at once in the end!
      let newReportMetadataForIndex = {};
      //Go through each report entry and add the metadata to the object above
      for (let newReportEntry of newReportEntries) {
        //Create a copy for modifications
        let reportEntry = {...newReportEntry};
        //Update metadata information about the index in different storage, if changed or not set yet
        if (reportEntry['updated_at'] != null && (mostRecentUpdate == null || ((new Date(reportEntry['updated_at'])) > (new Date(mostRecentUpdate)))) ) {
          mostRecentUpdate = reportEntry['updated_at'];
        }

        //TODO When deleted_at, remove from index. So far hidden gets set anyway, because the report type is not defined in the next step!

        //Check if report should be hidden and create new local attribute for that
        //If this report is visible by its type, remove hidden attribute by setting it to undefined 
        if (isReportTypeVisibleForExistingReports(reportEntry.type)) { //TODO Those need to be populated / calculated from the uploading reports as well
          reportEntry['hidden'] = undefined;
        } else { //Set hidden to 1 (true value) to not include it in calculated indizes
          reportEntry['hidden'] = 1;
        }

        //Pick only the metadata that should be saved. Exclude invalid metadata to reduce space usage.
        let newMetadata = _.pickBy(reportEntry, (value, key) => {
          //If it is a valid metadata key, check if valid
          if (key in METADATA_SHORT_KEY_MAPPING) {
            //If it is not null or not an empty array (Either not array or more that one element), include it
            if (value != null && ((!Array.isArray(value)) || value.length >= 1)) return true;
          }
          return false; //Otherwise it is a not used metadata key or it is empty, so don't include it to save space
        });
        //Set in the metadata index with all necessary information. Convert keys to short keys, or keep long key, as a fallback.
        newReportMetadataForIndex[reportEntry['id']] = _.mapKeys(newMetadata, (value, key) => METADATA_SHORT_KEY_MAPPING[key] || key);
      }

      //Update the most recent update timestamp if it changed
      if (mostRecentUpdate != null && (state.mostRecentReportUpdate == null || ((new Date(mostRecentUpdate)) > (new Date(state.mostRecentReportUpdate)))) ) {
        state.mostRecentReportUpdate = mostRecentUpdate;
      }
      //Add all the new objects to the metadata index once
      _.assign(state.reportMetadataIndex, newReportMetadataForIndex);
    },
    removeReportsFromIndexAndRelations (state, ids) {
      if (Array.isArray(ids)){
        //Remove all IDs from the metadata index at once
        state.reportMetadataIndex = _.omit(state.reportMetadataIndex, ids);
        //Create a deletion chain
        let deletePromise = Promise.resolve();
        //Then remove each id from the index storage, one after another
        for (let id of ids) {
          //Start the next deleton after the current one and ignore errors
          deletePromise = deletePromise.then(() => reportIndexStorage.remove(id)).catch(() => {});
        }
      }
    },
    //Add a report type to the index, if there doesn't exist a newer version. Each type exists once. If it gets updated and disabled, remove it from the index!
    addToReportTypeIndex (state, {key, type}) { //TODO Remember to update the report type index with removed ones from access permission changes!!! Remove locally from the user object change!!
      if (type.descriptor == null || type.version == null) return;

      //If there is already this key in the index, check if the new one modifies it
      if (state.reportTypeIndex[key] != null) {
        //Do not include if there is already a higher version in the index
        if (state.reportTypeIndex[key].version > type.version) return;

        //If it is a newer or same version that is disabled, delete the existing one and don't include the new one
        if (type.disabled_for_new) {
          delete state.reportTypeIndex[key];
          return;
        }
      }
      
      //If we didn't skip it, add it!
      state.reportTypeIndex[key] = _.pick(type, 
        ['id', 'main_category', 'descriptor', 'version', 'order', 'just_for_ai', 'needs_permission', 'category_hierarchy', 'section', 'identifiers', 'updated_at']
      );
    },
    //If a report type gets updated (readded to the index), this attribute is missing -> Not cached!
    setReportTypeCached (state, key) {
      if (state.reportTypeIndex[key] != null) state.reportTypeIndex[key].cached = true;
    },
    setMainCategories (state, newCategories) {
      state.mainCategories = newCategories;
    },
    checkVersion (state) { //Checks the version of this module and sets the state to default, if version is different
      //Used to reset state on breaking changes and to remove attributes that are no longer needed
      if (state.version !== MODULE_VERSION) {
        let defaultState = getDefaultState();
        Object.assign(state, defaultState); //Use setters to not impact any reactive functionality
        //Search for unused attributes and set them undefined, to remove them from the persisted state
        for (let key of Object.keys(state)){
          if (!(key in defaultState)){
            state[key] = undefined;
          }
        }
        clearReportIndex();
        state.version = MODULE_VERSION;
      }
    },
    clearPersonalData (state) {
      let defaultState = {};
      Object.assign(defaultState, getDefaultState());
      state.reportTypeIndex = defaultState.reportTypeIndex;
      state.mostRecentReportUpdate = defaultState.mostRecentReportUpdate;
      state.reportMetadataIndex = defaultState.reportMetadataIndex;
      state.mainCategories = defaultState.mainCategories;
      state.filters = defaultState.filters;
      clearReportIndex();
    }
  },
  actions: {
    updateSelectedDay (context, newDay) {
      context.commit('setSelectedDay', newDay);
    },
    updateSelectedTimespanDay (context, newDay) {
      context.commit('setSelectedTimespanDay', newDay);
    },
    updateSelectedMonth (context, newMonth) {
      context.commit('setSelectedMonth', newMonth);
    },
    fetchReportIndex (context) {      
      return new Promise((resolve, reject) => {
        api
        .get(REPORT_INDEX_FETCH_ROUTE, { fetchNewerForAnyPath: ['updated_at'] })
        .then(async response => {
          for (let newEntry of response.data){
            let reportEntry = {...newEntry};

            //Delete unnecessary fields, just in case
            delete reportEntry.fields;
            delete reportEntry.shares;

            //Try to generate a shortIdentifier for the report type, or use undefined on error
            try {
              let { shortIdentifier } = await generateIdentifiersForReportType(reportEntry.type);
              if (reportEntry.type != null) {
                reportEntry.type.identifier = shortIdentifier;
              }
            } catch {
              //Continue normally on error without setting an identifier for this type
            }

            await reportIndexStorage.set(reportEntry.id, reportEntry);
          }

          context.commit('addReportIndexMetadata', response.data);
          
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    fetchReportTypeIndex (context) {
      return new Promise((resolve, reject) => {
        api
        .get('/entry-types', { fetchNewerForAnyPath: ['updated_at'] }) //TODO It saves the newest fetch, but what if they are not saved sucessfully in the next step? Can we reset the timestamps on error? Even if API was successful, we might not add here!
        .then(async response => { //Use id as key in one object and Group ids by category in a separate object         
          for (let type of response.data){
            try {
              /**
               * Add additional metadata for finding report types (identifiers)
               */
              let { shortIdentifier, fullSlug, slugHash, shortHash, categoryAndSectionArray } = await generateIdentifiersForReportType(type);

              //Save all non-null identifiers to be searchable as strings
              type.identifiers = _.mapValues(_.pickBy({id: type.id, shortIdentifier, fullSlug, slugHash, shortHash}, (identifier) => identifier != null), (identifier) => String(identifier));

              //Add categories as path array and not separate attributes to save space
              type.category_hierarchy = categoryAndSectionArray;

              context.commit('addToReportTypeIndex', {key: shortIdentifier, type});
            } catch (error) {
              console.error('Could not add entry type to the index', error);
              continue; //Continue on error with next one
            }
          }

          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    fetchReportType (context, id) {
      return new Promise((resolve, reject) => {
        let user = context.rootGetters['auth/getUser'];
        let reportTypeMetadata = context.getters['getReportTypesByIdentifiers'](id); //Apply a version indicator to all changing API responses that are cached       
        api
        .get(`/entry-types/${id}`, {
          user: _.get(user, 'id'),
          params: {
            'updated_at': (reportTypeMetadata != null) ? reportTypeMetadata['updated_at'] : undefined
          }
        })
        .then(response => { //Use id as key in one object and Group ids by category in a separate object
          let newReportType = response.data; 

          //If the old format (object, not array) is used, convert it to the new format
          if (newReportType.definition && !Array.isArray(newReportType.definition)){
            let newDefinition = [];

            for (const [name, category] of Object.entries(newReportType.definition)) {
              //Add name to category
              let newCategory = {
                name,
                ...category
              }
              newDefinition.push(newCategory);
            }

            newReportType.definition = newDefinition;
          }
          
          return resolve(newReportType);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    fetchMainCategories (context) {
      return new Promise((resolve, reject) => {
        api
        .get('/main-categories')
        .then(response => { //Use id as key in one object
          let newMainCategories = {};
          for (let category of response.data){
            newMainCategories[category.id] = category;
          }
          context.commit('setMainCategories', newMainCategories);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    //Used only for getting the structure of Examination Stages for Uploads!
    fetchExaminationStages () {
      return api.get('/examination-stages')
        .then(response => {
          return response.data;
        });
    },
    //TODO Add parameter to API functions to not cache the uploaded object (e.g. when creating new entry types)
    createNewReport (context, {report, files, dependencies}) {
      let user = context.rootGetters['auth/getUser'];
      return api.post('/reports', report, {
        files,
        user: _.get(user, 'id'),
        dependencies,
        statusDetailPaths: REPORT_DETAIL_PATHS
      });
    },
    connectReports (context, {reports, reportDependencyArray}) {
      //Create a dependency object with the key being the path to each array item and the value being the statusKey from the upload
      let dependencies = _.reduce(reportDependencyArray, function(result, statusKey, arrayIndex) {
          result[`reports[${arrayIndex}]`] = statusKey;
          return result;
        }, {});
      let user = context.rootGetters['auth/getUser'];
      return api.post('/reports/connection', { reports }, {
        user: _.get(user, 'id'),
        dependencies,
        statusDetailPaths: CONNECTION_DETAIL_PATHS
      });
    }, //TODO update connections on EDIT? Which reports in connection to keep and which are dependencies?
    updateReport (context, {id, report, files, dependencies}) {
      let user = context.rootGetters['auth/getUser'];

      //As it it is an update to an existing report, first load all the information for that report. Only set if the information can be found!
      return reportIndexStorage.get(id).catch(() => null).then((originalDetails) => {
        if (originalDetails != null) {
          return originalDetails;
        }
      }).then((originalDetails) => {
        return api.put(`/reports/${id}`, report, {
          files,
          user: _.get(user, 'id'),
          dependencies,
          originalStatusDetails: originalDetails, //Set first and then overwritten by the existing details
          statusDetailPaths: REPORT_DETAIL_PATHS,
          id
        });
      })
    },
    processReport (context, reportToProcess) {
      //Shorthand async function call to get a promise and use await
      return (async () => {
        let report = reportToProcess;

        let fileToken = null;

        if (report.fields == null) return null;
        
        //Process each component
        for (const component of report.fields) {
          if (component.key != null) {
            //Modify the type of the component to only include necessary information //TODO How does it work when loading for editing, which type do we need?
            if (component.__component != null){
              let componentType = component.__component.split('.');
              component.type = componentType[componentType.length - 1];
            }

            //If we have either a single file or multiple files, get the files token, if not already set
            if ((component.file != null || component.files != null) && fileToken == null) fileToken = await context.dispatch('getReportFileToken', report.id).catch(() => null);

            //Preprocess single file
            if (component.file != null) {
              component.file = await processSingleReportFile(component.file, report.id, component.key, fileToken);
            }
            //Preprocess multiple files
            if (component.files != null && Array.isArray(component.files)) {
              //Map each file to a processing promise and return the resulting array with Promise.all
              component.files = await Promise.all(_.map(component.files, (componentFile, componentFileIndex) => processSingleReportFile(componentFile, report.id, component.key, fileToken, componentFileIndex)));
            }

            //Delete unnecessary attributes
            delete component.__component;
            delete component.id;
          }
        }

        return report;
      })();
    },
    getReportFileToken (context, id) {
      return new Promise((resolve, reject) => {
        api
        .get(`/reports/token/${id}`)
        .then(response => {
          if (response.data){
            resolve(response.data);
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    fetchReport (context, id) {
      return new Promise((resolve, reject) => {
        let user = context.rootGetters['auth/getUser'];
        let reportMetadata = context.getters['getReportMetadataById'](id); //Apply a version indicator to all changing API responses that are cached
        api
        .get(`/reports/${id}`, {
          user: _.get(user, 'id'),
          params: {
            'updated_at': (reportMetadata != null) ? reportMetadata[METADATA_SHORT_KEY_MAPPING['updated_at']] : undefined
          }
        })
        .then(async (response) => {
          let processedResponse = null;
          if (response.data) {
            //Process report and return it with all additional info (like uploadStatus)
            processedResponse = {
              data: (await context.dispatch('processReport', response.data)), //TODO We do not need to save analyses and relations when caching. They change!!! Maybe cache with created_at because they are immutable by design!!!! Also type can theoreticaly change!
              ...response
            }
            
          }
          return resolve(processedResponse);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    analyseReport (context, id) {
      return new Promise((resolve, reject) => {
        api
        .get(`/reports/analyse/${id}`)
        .then(response => {
          if (response.data && response.data.analyses){
            resolve(response.data.analyses); //TODO Attach to cached report!
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    getAnalysesStatus (context, id) {
      return new Promise((resolve, reject) => {
        api
        .get(`/reports/analyses/${id}`)
        .then(response => {
          if (response.data){
            resolve(response.data);
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    getReportAnalysisFileToken (context, {reportId, analysisId}) {
      return new Promise((resolve, reject) => {
        api
        .get(`/reports/analysis/token/${reportId}/${analysisId}`)
        .then(response => {
          if (response.data){
            resolve(response.data);
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    processAnalysis (context, {analysisToProcess, reportId}) {
      //Shorthand async function call to get a promise and use await
      return (async () => {
        let analysis = analysisToProcess;

        let fileToken = null;
        
        if (Array.isArray(analysis.result)) {
          //Process each result
          for (const [resultIndex, result] of analysis.result.entries()) {
            for (const fileAttribute of ANALYSIS_FILE_ATTRIBUTES) {
              if (fileAttribute in result && result[fileAttribute] != null) {
                
                //If we have valid file(s), get the files token, if not already set
                if (fileToken == null) fileToken = await context.dispatch('getReportAnalysisFileToken', {reportId, analysisId: analysis.id}).catch((error) => {
                  console.error('Could not fetch file token: ', error.message)
                  return null;
                });

                //Preprocess multiple files
                if (Array.isArray(result[fileAttribute])) {
                  //Map each file to a processing promise and return the resulting array with Promise.all
                  result[fileAttribute] = await Promise.all(_.map(result[fileAttribute], (file, fileIndex) => processSingleAnalysisFile(file, analysis.id, resultIndex, fileAttribute, fileToken, fileIndex)));
                }
                //Preprocess single file
                else {
                  result[fileAttribute] = await processSingleAnalysisFile(result[fileAttribute], analysis.id, resultIndex, fileAttribute, fileToken);
                }
              }
            }
          }
        }

        return analysis;
      })();
    },
    getAnalysis (context, {id, analysis}) {
      return new Promise((resolve, reject) => {
        api
        .get(`/reports/analysis/${id}/${analysis}`)
        .then(async response => {
          if (response.data){
            resolve(await context.dispatch('processAnalysis', {analysisToProcess: response.data, reportId: id}));
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    deleteReport (context, id) {
      return new Promise((resolve, reject) => {
        let url = `/reports/${id}`;
        api
        .delete(url)
        .then(response => {
          if (response.data){
            if (Array.isArray(response.data)){
              //Remove the deleted reports from the index as a new fetch of the index does not include the deleted ones //TODO Implement that deleted reports are removed on the next fetch of the index! OR that they are ignored!
              context.commit('removeReportsFromIndexAndRelations', response.data); //TODO Also remove report from cache and all its files from the file cache
            }
            resolve(response.data);
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    createNewReportType (context, newReportType) {
      let user = context.rootGetters['auth/getUser'];
      return api.post('/entry-types', newReportType, { user: _.get(user, 'id') })
        .then(response => response.completionPromise)
        .then(response => { 
          return response.data;
        });
    },
    hideReportType (context, id) {
      let user = context.rootGetters['auth/getUser'];
      return api.put(`/entry-types/${id}`, { 'disabled_for_new': true }, { user: _.get(user, 'id') })
        .then(response => response.completionPromise)
        .then(() => true);
    },
    searchReports (context, searchTerm) { //TODO Do not cache a search??
      //Apply selected day filters for improved performance
      let minDay = context.getters.getSelectedDay;
      let maxDay = context.getters.getSelectedTimespanDay;

      let params = {
        '_s': searchTerm
      };

      if (minDay != null) {
        params['timestamp_gte'] = dayjs(minDay, 'DD.MM.YYYY').utc().toISOString();
      }

      if (maxDay != null) {
        params['timestamp_lte'] = dayjs(maxDay, 'DD.MM.YYYY').utc().toISOString();
      }

      return new Promise((resolve, reject) => {
        api
        .get('/reports/search', { params })
        .then(response => {
          if (response.data && Array.isArray(response.data)){
            //Convert array to indexed object
            let searchIndex = {};
            for (let searchResult of response.data) {
              if (searchResult.id != null) {
                searchIndex[searchResult.id] = searchResult.score || null;
              }
            }
            resolve(searchIndex);
          }
          resolve({});
        })
        .catch(error => {
          reject(error);
        });
      });
    }
  },
}