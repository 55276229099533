import { api } from '@/utils/api';
import { sendMessageToActiveServiceWorkers, sendMessageToAllServiceWorkers } from '@/utils/service_workers';

const MODULE_VERSION = 2; //UPDATE VERSION WITH EVERY BREAKING CHANGE OR ATTRIBUTE REMOVAL

const getDefaultState = () => {
  return {
    version: undefined, //TODO Add Loading animations while api is queried
    user: null
  }
};

const messageChannel = new MessageChannel();

//Initialize communication port once service workers have become active
//Send the communication port to the active service workers
sendMessageToActiveServiceWorkers({type: 'AUTH_TOKEN_PORT_INITIALIZATION'}, [messageChannel.port2]);


//Listen to messages and respond with the auth token if requested through this port only (Secure channel)
messageChannel.port1.onmessage = (event) => {
  if (event.data && event.data.action === 'getAuthToken') {
    messageChannel.port1.postMessage({
      type: 'AUTH_TOKEN',
      authToken: api.getToken().value
    });
  }
  if (event.data && event.data.action === 'receivedAuthToken') {
    api.setAuthenticationRelayActive(true);
  }
};

export default {
  namespaced: true,

  state: getDefaultState,
  getters: {
    getToken () {
      return api.getToken().value;
    },
    isAuthenticationRelayActive () {
      return api.getAuthenticationRelayActive().value;
    },
    getEmail (state) {
      if (state.user) {
        return state.user.email;
      } else {
        return null;
      }
    },
    getUser (state) {
      return state.user; //TODO Fetch user regularly (Probably already done) And then when the role changes or the permissions trigger a reload! E.g. encyclopedia is different!
    },
    isUserPresent (state) {
      return (
        api.getToken().value != null && 
        state.user != null 
      )
    },
    isAuthenticated (state) {
      return (
        api.getToken().value != null && 
        state.user != null &&
        state.user.confirmed === true &&
        state.user.blocked !== true &&
        state.user.force_password_reset_on_next_login !== true
      )
    },
    isConfirmed (state) {
      return (state.user != null && state.user.confirmed === true);
    },
    isBlocked (state) {
      return (state.user != null && state.user.blocked === true);
    },
    passwordResetEnforced (state) {
      return (state.user != null && state.user.force_password_reset_on_next_login === true);
    },
    unlockUserNeeded (state) {
      return (
        api.getToken().value && 
        state.user &&
        (
        state.user.confirmed !== true ||
        state.user.blocked === true ||
        state.user.force_password_reset_on_next_login === true
        )
      )
    },
    hasPermission (state) {
      return function(permission) {
        if (state.user != null && state.user.additional_permissions != null) {
          for (let userPermission of state.user.additional_permissions) {
            if (userPermission != null && userPermission == permission) {
              return true;
            }
          }
        }
        return false;
      }
    },
    isVeterinarian (state) {
      return (state.user != null && state.user.role === "veterinarian")
    }
  },
  mutations: {
    setNewToken (state, newToken) {
      api.setToken(newToken);
    },
    setNewUser (state, newUser) {
      state.user = newUser;
    },
    removeTokenAndUser (state) {
      api.removeToken();
      state.user = null;
    },
    checkVersion (state) { //Checks the version of this module and sets the state to default, if version is different
      //Used to reset state on breaking changes and to remove attributes that are no longer needed
      if (state.version !== MODULE_VERSION) {
        let defaultState = getDefaultState();
        Object.assign(state, defaultState); //Use setters to not impact any reactive functionality
        //Search for unused attributes and set them undefined, to remove them from the persisted state
        for (let key of Object.keys(state)){
          if (!(key in defaultState)){
            state[key] = undefined;
          }
        }
        state.version = MODULE_VERSION;
      }
    },
    clearPersonalData (state) {
      let defaultState = {};
      Object.assign(defaultState, getDefaultState());
      api.removeToken();
      //Clear all personal data in the caches, once the service worker is available
      sendMessageToAllServiceWorkers({type: 'CLEAR_PERSONAL_DATA'});
      state.user = defaultState.user;
    }
  },
  actions: {
    login (context, { user, password }) { //TODO DELETE USER CREDENTIALS IMEDIATELY
      return new Promise((resolve, reject) => {
        api
        .post('/auth/local', {
          identifier: user,
          password: password
        }, {skipQueue: true}) //Skip upload queue
        .then(response => response.completionPromise)
        .then(response => {
          context.commit('setNewToken', response.data.jwt);
          context.dispatch('fetchUser').catch((error) => reject(error));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    register (context, { user, password }) { //TODO DELETE USER CREDENTIALS IMEDIATELY
      return new Promise((resolve, reject) => {
        api
        .post('/auth/local/register', {
          email: user,
          password: password
        }, {skipQueue: true}) //Skip upload queue
        .then(response => response.completionPromise)
        .then(response => {
          context.commit('setNewToken', response.data.jwt);
          context.dispatch('fetchUser').catch((error) => reject(error));
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    forgotPassword (context, user) { //TODO DELETE USER CREDENTIALS IMEDIATELY
      return new Promise((resolve, reject) => {
        api
        .post('/auth/forgot-password', {
          email: user
        }, {skipQueue: true}) //Skip upload queue
        .then(response => response.completionPromise)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    resetPassword (context, { code, password, passwordConfirmation }) { //TODO DELETE USER CREDENTIALS IMEDIATELY
      return new Promise((resolve, reject) => {
        api
        .post('/auth/reset-password', {
          code: code,
          password: password,
          passwordConfirmation: passwordConfirmation
        }, {skipQueue: true}) //Skip upload queue
        .then(response => response.completionPromise)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    sendConfirmation () {
      return new Promise((resolve, reject) => {
        api
        .post('/auth/send-email-confirmation', undefined, {skipQueue: true}) //Skip upload queue
        .then(response => response.completionPromise)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    logout (context) {
      return new Promise((resolve, reject) => {
        context.dispatch('fetchUser') //TODO Call API to invalidate user token instead of getting user information? Or as a separate call to logout on all devices? Logged out on all devices when password changes? - For now detects blocked users
        .catch((error) => reject(error))
        .then(() => resolve())
        .finally(() => {
          context.dispatch('clearAllPersonalData', null, { root: true });
        });
      });
    },
    clearNonPrivateCaches () {
      //Clear all data in the public caches, once the service worker is available
      sendMessageToAllServiceWorkers({type: 'CLEAR_PUBLIC_CACHES'});
    },
    fetchUser (context) {
      return new Promise((resolve, reject) => {
        api
        .get('/users/me')
        .then(response => {
          context.commit('setNewUser', response.data);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    changeUserWithJWT (context, jwt) {
      return context.dispatch('clearAllPersonalData', null, { root: true })
      .then(() => {
        context.commit('setNewToken', jwt);
        return context.dispatch('fetchUser');
      }); 
    }
  }
}