import { api, createStorage } from '@/utils/api';
//import { generateUniqueColorFromIdentifierHash } from '@/utils/colors';
import _ from 'lodash';

import { Drivers } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
//memoryDriver is Fallback option to save to RAM in case the memory in other drivers is not enough. Gets deleted on app restart or when browser needs memory!
import memoryDriver from 'localforage-memoryStorageDriver';

import { STATUS_METADATA_SHORT_KEY_MAPPING } from '@/utils/animals';

const MODULE_VERSION = 4; //UPDATE VERSION WITH EVERY BREAKING CHANGE OR ATTRIBUTE REMOVAL

const STATUS_FETCH_ROUTE = '/statuses'

const statusIndexStorage = createStorage({
    name: '__status_index',
    driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, /* Drivers.LocalStorage, */ memoryDriver._driver] //Skip localStorage, as index grows too big!
  },
  [CordovaSQLiteDriver, memoryDriver]
);

const clearStatusIndex = function() {
  return statusIndexStorage.clear()
  .then(() => api.clearRouteMetadata(STATUS_FETCH_ROUTE)); //Clear route metadata for reports, to fetch them freshly again!
}

const HORSE_DETAIL_PATHS = ['name', 'unique_identifier', 'postcode_stable', 'additional', 'personal_data', 'personal_data_vet'];

const STATUS_DETAIL_PATHS = ['timestamp', 'type', 'details'];

const getDefaultState = () => {
  return {
    version: undefined,
    horses: {},
    personalInfos: {},
    selectedHorse: null,
    groupByStatus: false,
    mostRecentStatusUpdate: null,
    statusMetadataIndex: {},
  }
};

export default {
  namespaced: true,

  state: getDefaultState,
  getters: {
    getHorses (state) {
      return state.horses;
    },
    getHorseInfos (state) {
      return state.personalInfos;
    },
    getHorseToPersonalInfoMapping (state, getters) {
      let horses = {};

      _.forEach(getters['getHorseInfos'], (horseInfo, horseInfoId) => {
        if (horseInfo.horses != null) {
          _.forEach(horseInfo.horses, (horse) => {
            if (horse != null && horse.id != null) {
              horses[horse.id] = horseInfoId;
            }
          });
        }
      });

      return horses;
    },
    getStatusesGroupedByHorseInfos (state, getters) {
      let horses = {};

      let mapping = getters['getHorseToPersonalInfoMapping'];

      //Use mostRecentUpdate to always trigger changes!
      if (state.mostRecentStatusUpdate != null) {
        _.forEach(state.statusMetadataIndex, (statusMetadataObjects, type) => {
          _.forEach(statusMetadataObjects, (statusMetadata, statusMetadataId) => {
            if (statusMetadata != null) {
              let horseId = statusMetadata[STATUS_METADATA_SHORT_KEY_MAPPING['horse']];
              if (horseId != null && mapping[horseId] != null) {
                _.setWith(horses, [mapping[horseId], type, statusMetadataId], statusMetadata, Object);
              }
            }
          });
        });
      }

      return horses;
    },
    getHorseInfosWithCurrentStatusAsync (state, getters) {
      let groupedStatuses = getters['getStatusesGroupedByHorseInfos'];

      return Promise.all(_.map(getters['getHorseInfos'], async (horseInfo, horseInfoId) => {
        let status = {};

        let statusesForHorseInfo = groupedStatuses[horseInfoId];

        if (statusesForHorseInfo != null) {
          for (let [category, statusesInCategory] of Object.entries(statusesForHorseInfo)) {
            let currentCategoryStatus = {
              latest: null,
              count: 0
            };

            if (statusesInCategory != null) {
              currentCategoryStatus.count = Object.keys(statusesInCategory).length;

              _.forEach(statusesInCategory, (statusMetadata, statusId) => {
                let timestamp = _.get(statusMetadata, [STATUS_METADATA_SHORT_KEY_MAPPING['timestamp']]);
                let updated = _.get(statusMetadata, [STATUS_METADATA_SHORT_KEY_MAPPING['updated_at']]);
                //Add newer status as latest
                if (currentCategoryStatus.latest != null) { //Check if it is already set, otherwise always set it for the first time
                  if (timestamp < currentCategoryStatus.latest.timestamp) return;
                  else if (timestamp == currentCategoryStatus.latest.timestamp) { //If they are equal, compare updated_at to get the newer one
                    if (updated < currentCategoryStatus.latest.updated) return;
                  }
                }

                currentCategoryStatus.latest = { id: statusId, timestamp: timestamp, updated: updated };
              });
            }

            if (currentCategoryStatus.latest != null) currentCategoryStatus.latest = await statusIndexStorage.get(currentCategoryStatus.latest.id); //Returns Promise!

            status[category] = currentCategoryStatus;
          }
        }

        //Return as key value pairs for promise mapping to work
        return [horseInfoId, { ...horseInfo, status }];
      })).then((horseInfosWithCurrentStatus) => {
        return _.fromPairs(horseInfosWithCurrentStatus);
      });
    },
    getStatusesForHorseWithTypeAsync (state, getters) {
      let groupedStatuses = getters['getStatusesGroupedByHorseInfos'];

      return async function(horseInfoId, type) {
        let statusesForHorseInfo = _.get(groupedStatuses, [horseInfoId, type]);

        if (statusesForHorseInfo == null) return [];

        //Sort by timestamp in reverse order, newest first
        let statusIDs = Object.keys(statusesForHorseInfo)
        statusIDs.sort(function(firstStatusId, secondStatusId){
          //Take the timestamp if it exists, or if an array of multiple is given, try to get the first one or set it to null
          let firstTimestamp = _.get(statusesForHorseInfo, [firstStatusId, STATUS_METADATA_SHORT_KEY_MAPPING['timestamp']]);
          let secondTimestamp = _.get(statusesForHorseInfo, [secondStatusId, STATUS_METADATA_SHORT_KEY_MAPPING['timestamp']]);
          if (firstTimestamp != null && secondTimestamp != null) {
            if (firstTimestamp < secondTimestamp) return 1;
            if (firstTimestamp > secondTimestamp) return -1;
            //If they are equal, compare updated_at
            if (firstTimestamp == secondTimestamp) {
              let firstUpdated = _.get(statusesForHorseInfo, [firstStatusId, STATUS_METADATA_SHORT_KEY_MAPPING['updated_at']]);
              let secondUpdated = _.get(statusesForHorseInfo, [secondStatusId, STATUS_METADATA_SHORT_KEY_MAPPING['updated_at']]);
              if (firstUpdated != null && secondUpdated != null) {
                if (firstUpdated < secondUpdated) return 1;
                if (firstUpdated > secondUpdated) return -1;
              }
            }
          }
          return 0;
        });

        return Promise.all(_.map(statusIDs, (statusId) => statusIndexStorage.get(statusId)));
      }
    },
    getHorseById (state, getters) { //TODO Add currently uploading horses to getter functions too!
      return function(id) {
        if (id in state.horses) {
          let personalHorseId = state.horses[id];
          return { id: personalHorseId, ...getters['getHorseInfos'][personalHorseId]}; //TODO Give back all horse info and if it is currently uploading
        } else {
          return null;
        }
      }
    },
    getPersonalInfoById (state, getters) { //TODO Add currently uploading horses to getter functions too!
      return function(id) {
        return getters['getHorseInfos'][id]; //TODO Give back all horse info and if it is currently uploading
      }
    },
    getNewestHorseIdForPersonalInfoId (state, getters) {
      return function(id) {
        let personalInfo = getters['getPersonalInfoById'](id);

        if (personalInfo != null && personalInfo.horses != null && Array.isArray(personalInfo.horses)) {
          //Only get horses that are owned by this user
          let ownHorses = _.filter(personalInfo.horses, (horse) => (horse.own === true));
          //Sort to get the newest one (first one, when sorted in descending order)
          ownHorses.sort((a,b) => (new Date(b.created_at) - new Date(a.created_at)));
          //Get the first one and return its id
          return _.get(_.first(ownHorses), 'id');
        }
      }
    },
    getHorsesByOrderAsync (state, getters, rootState, rootGetters) {
      let animalPromise = getters['getHorseInfosWithCurrentStatusAsync'];

      //Create location indexing object
      let locationOptions = {
        //Not assigned animals!!
        null: {
          spots: {
            null: {
              animals: []
            }
          }
        }
      };
      //Add all known locations with their details
      _.forEach(rootGetters['customization/getLocations'], (location, locationIndex) => {
        if (location.descriptor != null && location.spots != null) {
          let spots = {};
          _.forEach(location.spots, (spot, spotIndex) => {
            if (spot.descriptor != null) spots[spot.descriptor] = {
              ...spot,
              order: spotIndex,
              animals: []
            };
          });
          locationOptions[location.descriptor] = {
            ...location,
            order: locationIndex,
            spots
          };
        }
      });

      //Create status indexing object
      let caseStatusOptions = {
        //Not assigned animals!!
        null: {
          animals: []
        }
      };
      //Add all known statuses with their details
      _.forEach(rootGetters['customization/getStatuses'], (status, statusIndex) => {
        if (status.descriptor != null) {
          caseStatusOptions[status.descriptor] = {
            ...status,
            order: statusIndex,
            animals: []
          };
        }
      });

      //Return anonymous async function that gets called to retrieve the statuses with a promise
      return (async () => {
        //Copy the prefilled status options from above
        let locations = _.cloneDeep(locationOptions);
        let statuses = _.cloneDeep(caseStatusOptions);
        let animals = await animalPromise;
        //Check for each animal if their location exists, otherwise put it into the unassigned spot. Repeat the same for the status
        for (let [id, animal] of Object.entries(animals)) {
          //---------Location---------
          let currentLocationObject = _.get(animal, ['status', 'location', 'latest'], {});
          let currentLocation = _.get(currentLocationObject, ['details', 'location'], null);
          let currentSpot = _.get(currentLocationObject, ['details', 'spot'], null);

          //Initialize objects if they do not exist yet, or complete them
          _.updateWith(locations, [currentLocation], (locationInstance) => {
            //Set defaults that are undefined still - Takes empty object as starting point if it does not exist yet
            return _.defaults((locationInstance || {}), {
              descriptor: currentLocation,
              order: (currentLocation != null) ? 0 : undefined
            });
          }, Object);
          _.updateWith(locations, [currentLocation, 'spots', currentSpot], (spotInstance) => {
            //Set defaults that are undefined still - Takes empty object as starting point if it does not exist yet
            return _.defaults((spotInstance || {}), {
              descriptor: currentSpot,
              order: (currentSpot != null) ? 0 : undefined
            });
          }, Object);
          //Add to spot
          _.updateWith(locations, [currentLocation, 'spots', currentSpot, 'animals'], (spotInstance) => {
            let spotArray = spotInstance || []; //Use empty array, if it does not exist yet
            spotArray.push(id);
            return spotArray;
          }, Object);

          //---------Status---------
          let currentStatusObject = _.get(animal, ['status', 'case_status', 'latest'], {});
          let currentStatus = _.get(currentStatusObject, ['details', 'status'], null);

          //Initialize object if it does not exist yet, or complete it
          _.updateWith(statuses, [currentStatus], (statusInstance) => {
            //Set defaults that are undefined still - Takes empty object as starting point if it does not exist yet
            return _.defaults((statusInstance || {}), {
              descriptor: currentStatus,
              order: (currentStatus != null) ? 0 : undefined
            });
          }, Object);
          //Add to status
          _.updateWith(statuses, [currentStatus, 'animals'], (statusInstance) => {
            let statusArray = statusInstance || []; //Use empty array, if it does not exist yet
            statusArray.push(id);
            return statusArray;
          }, Object);
        }

        return {
          animals,
          locations,
          statuses
        };
      })();
    },
    getSelectedHorse (state) {
      return state.selectedHorse;
    },
    shouldGroupByStatus (state) {
      return state.groupByStatus;
    },
  },
  mutations: {
    addHorseInfo (state, newInfo) {
      state.personalInfos[newInfo.id] = _.omit(newInfo, 'id');
      if (newInfo.horses != null) {
        for (let horse of newInfo.horses) {
          if (horse.id != null) state.horses[horse.id] = newInfo.id;
        }
      }
    },
    setSelectedHorse (state, newHorse) {
      state.selectedHorse = newHorse;
    },
    setGroupByStatusState (state, newState) {
      state.groupByStatus = newState;
    },
    addStatusIndexMetadata (state, newStatusEntries) {
      let mostRecentUpdate = state.mostRecentStatusUpdate;
      //Save temporarily all new metadata and add all of them at once in the end!
      let newStatusMetadataForIndex = {};
      //Go through each status entry and add the metadata to the object above grouped by the type
      for (let newStatusEntry of newStatusEntries) {
        //Create a copy for modifications
        let statusEntry = {...newStatusEntry};
        //Update metadata information about the index in different storage, if changed or not set yet
        if (statusEntry['updated_at'] != null && (mostRecentUpdate == null || ((new Date(statusEntry['updated_at'])) > (new Date(mostRecentUpdate)))) ) {
          mostRecentUpdate = statusEntry['updated_at'];
        }

        //TODO When deleted_at, remove from index.

        //Pick only the metadata that should be saved. Exclude invalid metadata to reduce space usage.
        let newMetadata = _.pickBy(statusEntry, (value, key) => {
          //If it is a valid metadata key, check if valid
          if (key in STATUS_METADATA_SHORT_KEY_MAPPING) {
            //If it is not null or not an empty array (Either not array or more that one element), include it
            if (value != null && ((!Array.isArray(value)) || value.length >= 1)) return true;
          }
          return false; //Otherwise it is a not used metadata key or it is empty, so don't include it to save space
        });
        //Set in the metadata index with all necessary information. Convert keys to short keys, or keep long key, as a fallback.
        _.setWith(newStatusMetadataForIndex, [statusEntry['type'], statusEntry['id']], _.mapKeys(newMetadata, (value, key) => STATUS_METADATA_SHORT_KEY_MAPPING[key] || key), Object);
      }

      //Update the most recent update timestamp if it changed
      if (mostRecentUpdate != null && (state.mostRecentStatusUpdate == null || ((new Date(mostRecentUpdate)) > (new Date(state.mostRecentStatusUpdate)))) ) {
        state.mostRecentStatusUpdate = mostRecentUpdate;
      }
      //Add all the new objects to the metadata index once
      _.assign(state.statusMetadataIndex, newStatusMetadataForIndex);
    },
    checkVersion (state) { //Checks the version of this module and sets the state to default, if version is different
      //Used to reset state on breaking changes and to remove attributes that are no longer needed
      if (state.version !== MODULE_VERSION) {
        let defaultState = getDefaultState();
        Object.assign(state, defaultState); //Use setters to not impact any reactive functionality
        //Search for unused attributes and set them undefined, to remove them from the persisted state
        for (let key of Object.keys(state)){
          if (!(key in defaultState)){
            state[key] = undefined;
          }
        }
        clearStatusIndex();
        state.version = MODULE_VERSION;
      }
    },
    clearPersonalData (state) {
      let defaultState = {};
      Object.assign(defaultState, getDefaultState());
      state.horses = defaultState.horses;
      state.selectedHorse = defaultState.selectedHorse;
      state.personalInfos = defaultState.personalInfos;
      state.mostRecentStatusUpdate = defaultState.mostRecentStatusUpdate;
      state.statusMetadataIndex = defaultState.statusMetadataIndex;
      clearStatusIndex();
    }
  },
  actions: {
    //Add a generated color to the horse, if it has no color set (catch edge case, color should be set on creation)
    processAndAddHorseInfo (context, personalInfo) {
      //Return an async function that is called immediately to always return a promise
      return (async function() {
        //If no color present and id is set, try to generate color. On error just sets null!
        //if (personalInfo.color == null && personalInfo.id != null) personalInfo.color = await generateUniqueColorFromIdentifierHash(personalInfo.id).catch(() => null);
        context.commit('addHorseInfo', personalInfo);
      })();
    },
    fetchHorses (context) { //TODO Change that index to be fetched through personal_horse_info
      return new Promise((resolve, reject) => {
        api
        .get('/personal-horse-infos', {
          //TODO Implement Filter to only fetch ones newer (updated_at) than latest fetch
        })
        .then(response => { //Add to object with id as index
          for (let personalInfo of response.data){
            context.dispatch('processAndAddHorseInfo', personalInfo);
          }
          
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    createNewHorse (context, { animalInfo, files }) {
      let user = context.rootGetters['auth/getUser'];
      let uploadPomise = api.post('/personal-horse-infos', animalInfo, {
        files,
        user: _.get(user, 'id'),
        statusDetailPaths: HORSE_DETAIL_PATHS
      }); 
      //TODO In the future add this horse to the state, no need to load again! Keep files in mind!
      uploadPomise.then((uploadStatus) => uploadStatus.completionPromise).then(() => context.dispatch('fetchHorses'));
      return uploadPomise;
    },
    updateHorse (context, { id, animalInfo, files }) {
      let user = context.rootGetters['auth/getUser'];
      let uploadPomise = api.put(`/personal-horse-infos/${id}`, animalInfo, {
        files,
        user: _.get(user, 'id'),
        statusDetailPaths: HORSE_DETAIL_PATHS
      });
      //TODO In the future add this horse to the state, no need to load again! Keep files in mind!
      uploadPomise.then((uploadStatus) => uploadStatus.completionPromise).then(() => context.dispatch('fetchHorses'));
      return uploadPomise;
    },
    updateSelectedHorse (context, newHorse) {
      context.commit('setSelectedHorse', newHorse);
    },
    fetchStatuses (context) {      
      return new Promise((resolve, reject) => {
        api
        .get(STATUS_FETCH_ROUTE, { fetchNewerForAnyPath: ['updated_at'] })
        .then(async response => {
          for (let newEntry of response.data){
            let statusEntry = {...newEntry};

            await statusIndexStorage.set(statusEntry.id, statusEntry);
          }

          context.commit('addStatusIndexMetadata', response.data);
          
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    addStatus (context, { status, files }) {
      let user = context.rootGetters['auth/getUser'];
      let uploadPomise = api.post('/statuses', status, {
        files,
        user: _.get(user, 'id'),
        statusDetailPaths: STATUS_DETAIL_PATHS
      }); 
      //TODO In the future add this status to the state, no need to load again! Keep files in mind!
      uploadPomise.then((uploadStatus) => uploadStatus.completionPromise).then(() => context.dispatch('fetchStatuses'));
      return uploadPomise;
    },
    updateStatus (context, { id, status, files }) {
      let user = context.rootGetters['auth/getUser'];
      let uploadPomise = api.put(`/statuses/${id}`, status, {
        files,
        user: _.get(user, 'id'),
        statusDetailPaths: STATUS_DETAIL_PATHS
      });
      //TODO In the future add this status to the state, no need to load again! Keep files in mind!
      uploadPomise.then((uploadStatus) => uploadStatus.completionPromise).then(() => context.dispatch('fetchStatuses'));
      return uploadPomise;
    },
  }
}