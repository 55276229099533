<template id="filter-popup">
  <ion-content>
    <ion-header v-if="!hideFilterTitle">
      <ion-item v-if="filterTitleSingleOption">
        <ion-label class="standalone-title" v-if="availableFilters != null && currentSelectedFilter != null && currentSelectedFilter in availableFilters">
          <b>{{ availableFilters[currentSelectedFilter].localizedName || i18n.$t('default_interaction.select') }}</b>
        </ion-label>
      </ion-item>
      <ion-item v-else>
        <ion-label position="stacked">{{ i18n.$t('filter.filter_by') }}</ion-label>
        <ion-select v-model="currentSelectedFilter" :placeholder="i18n.$t('default_interaction.select')" interface="popover" :interfaceOptions="{'cssClass': 'filter-popup-filter-select'}">
          <ion-select-option v-for="availableFilter in Object.keys(availableFilters).sort(createObjectOrderSortFunction(availableFilters))" :key="availableFilter" :value="availableFilter">
            {{ availableFilters[availableFilter].localizedName }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-header>
    <div class="searchbar" v-if="!(hideSearchbar || currentSelectedFilterOptionsCount < minimumSearchbarElements)">
      <ion-searchbar v-if="currentSelectedFilterHasOptions" :placeholder="i18n.$t('default_interaction.search')" @keyup="blurOnEnter" @ionInput="searchFilter = $event.target.value" :value="searchFilter"></ion-searchbar>
    </div>
    <ion-list v-if="currentSelectedFilterHasOptions" class="filter-container">
      <ion-radio-group v-if="currentFilteredFilterHasOptions" allow-empty-selection :value="currentValue" @ionChange="(currentSelectedFilterOptions.allowMultiple || isSelect) ? undefined : setValue($event.target.value)"> <!-- Only use radio group update, if radio buttons are used. When isSelect is false and we only have one option -->
        <ion-item-group v-for="category in Object.keys(currentFilteredFilterOptions).sort(createObjectOrderSortFunction(currentFilteredFilterOptions))" :key="category">
          <ion-item-divider v-if="category != 'null'">
            <ion-label>{{ category }}</ion-label>
          </ion-item-divider>
          <ion-item 
            lines="full"
            v-for="(option) in currentFilteredFilterOptions[category].options.sort(optionSortFunction)"
            :key="option.value"
            :button="isSelect && !currentSelectedFilterOptions.allowMultiple"
            detail="false"
            @click="(!isSelect || currentSelectedFilterOptions.allowMultiple) ? undefined : setValue(option.value, true)"
            :class="(option.background) ? 'custom-background' : undefined"
            :style="[(option.background) ? `--custom-background: ${option.background};` : '', (brightenColorHex(option.background) != null) ? `--custom-background-shade: ${brightenColorHex(option.background)};` : '']"
            > <!-- Make items clickable instead of radio buttons, if it is select and only one option can be selected. -->
            <ion-avatar v-if="option.showAvatar" :class="['avatar', option.avatarGradient ? 'gradient' : '' ]" slot="start" :style="option.avatarColor != null ? `--custom-color: ${option.avatarColor}; --hue: ${hexToHue(option.avatarColor)}` : undefined">
              <AuthenticatedMedia v-if="option.avatarSrc != null" :mediaUrl="option.avatarSrc" type="image"></AuthenticatedMedia>
              <div v-else-if="fallbackAvatarIcon != null" class="avatar-icon" :style="option.avatarColor != null ? `--color: #${option.avatarColor}` : undefined">
                <font-awesome-icon v-if="fallbackAvatarIconIsFontAwesome" :icon="fallbackAvatarIcon" />
                <ion-icon v-else :icon="fallbackAvatarIcon"></ion-icon>
              </div>
            </ion-avatar>
            <ion-label :class="['filter-option-label', (option.italic) ? 'italic' : '', (option.bold) ? 'bold' : '']" :style="[(option.color) ? `--color: var(--ion-color-${option.color});` : '', (option.textOpacity) ? `opacity: ${option.textOpacity};` : '']">
              <h4 v-if="option.indicatorText != null" class="indicator-text">{{ option.indicatorText }}</h4>
              <h2 class="main-text">{{ option.localizedName }}</h2>
              <!-- If it is not hidden for this option: Show this line, if either text or a fallback is defined. Can be empty string just not null. -->
              <h3 v-if="(!option.hideAdditionalText) && (option.additionalText != null || noValuePlaceholder != null)" :class="['additional-text', 'inline-fa-icons', ((option.wrapAdditionalText) ? 'wrapping' : undefined)]">{{ option.additionalText || noValuePlaceholder }}</h3>
            </ion-label>
            <ion-checkbox v-if="currentSelectedFilterOptions.allowMultiple" slot="end" :checked="isValueSet(option.value)" @ionChange="$event.target.checked ? setValue(option.value) : removeValue(option.value)"></ion-checkbox>
            <ion-radio v-else-if="!isSelect" slot="end" :value="option.value"></ion-radio> <!-- If it is a single choice select, we don't need radio buttons, it is applied immediately -->
            <ion-icon v-else :icon="checkmarkSharp" :class="['custom-single-option-checkmark', (isValueSet(option.value)) ? 'visible' : '']"></ion-icon> <!-- Otherwise show a custom checkmark, if it is selected -->
          </ion-item>
        </ion-item-group>
      </ion-radio-group>
      <ion-item v-else lines="none" class="no-results-hint">
        <ion-label>{{ i18n.$t('filter.no_results.' + ((isSelect) ? 'select' : 'filter')) }}</ion-label>
      </ion-item>
    </ion-list>
    <!-- Show that no entries are available for that filter, if a filter has been selected, but it has no options -->
    <ion-item v-else-if="currentSelectedFilter != null" lines="none" class="no-entries-hint">
      <ion-label v-if="customNoEntriesHint != null">{{ customNoEntriesHint }}</ion-label>
      <ion-label v-else>{{ i18n.$t('filter.no_entries.' + ((isSelect) ? 'select' : 'filter')) }}</ion-label>
    </ion-item>
    <ion-footer v-if="!isSelect || currentSelectedFilterOptions.allowMultiple">
      <!-- Only show apply button if it is not a select or if we have multiple options in the select. For single value selects it is chosen immediately -->
      <ion-button expand="full" :disabled="!filterComplete" @click="applyFilters()">{{ i18n.$t('default_interaction.' + ((isSelect) ? 'select' : 'apply')) }}</ion-button>
    </ion-footer>
  </ion-content>
</template>

<script>

import { IonContent, IonItem, IonLabel, IonSelect, IonSelectOption, IonList, IonItemGroup, IonItemDivider, IonButton, IonCheckbox, IonRadioGroup, IonRadio, IonSearchbar, IonHeader, IonFooter, IonAvatar, IonIcon, popoverController } from '@ionic/vue';
import { computed, defineComponent, ref, watch, onMounted, onUnmounted } from 'vue';

import AuthenticatedMedia from '@/components/AuthenticatedMedia.vue';

import { useI18n } from "@/utils/i18n";

import { removeNonAlphaNumCharactersNormalize } from '@/utils/algorithms';

import { checkmarkSharp } from 'ionicons/icons';

import { blurOnEnter } from '@/utils/interaction';

import { brightenColorHex, hexToHue } from '@/utils/colors';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import _ from 'lodash';

const FILTER_POPOVER_CLASS = 'filter-popover';

const FilterPopup = defineComponent({
  name: 'FilterPopup',
  components: { IonContent, IonItem, IonLabel, IonSelect, IonSelectOption, IonList, IonItemGroup, IonItemDivider, IonButton, IonCheckbox, IonRadioGroup, IonRadio, IonSearchbar, IonHeader, IonFooter, IonAvatar, IonIcon, AuthenticatedMedia, FontAwesomeIcon },
  props: {
    'filter': String,
    'value': [String, Number, Array, Boolean], 
    'availableFilters': {
      type: Object,
      default: () => {return {}}
    },
    'hideFilterTitle': Boolean,
    'hideSearchbar': Boolean,
    'minimumSearchbarElements': {
      type: Number,
      default: 15
    },
    'filterTitleSingleOption': Boolean,
    'isSelect': Boolean,
    'fallbackAvatarIcon': [Object, String],
    'fallbackAvatarIconIsFontAwesome': Boolean,
    'noValuePlaceholder': {
      type: String,
      default: null
    },
    'customNoEntriesHint': {
      type: String,
      default: null
    }
  },
  setup(props) {
    const i18n = useI18n();

    const currentSelectedFilter = ref(_.cloneDeep(props.filter));

    const currentValue = ref(_.cloneDeep(props.value));

    const searchFilter = ref(null);

    watch(currentSelectedFilter, (newFilter, oldFilter) => {
      if (oldFilter != newFilter) currentValue.value = null;
    });

    const setValue = function(selectedOption, applyImmediately = false) {
      if (selectedOption != null && selectedOption.length == 0) selectedOption = null;
      if (currentSelectedFilterOptions.value.allowMultiple) {
        if (!Array.isArray(currentValue.value)) currentValue.value = [];
        currentValue.value.push(selectedOption);
      } else {
        currentValue.value = selectedOption;
      }

      if (applyImmediately) {
        applyFilters();
      }
    }

    const removeValue = function(selectedOption) {
      if (Array.isArray(currentValue.value)) _.pullAllWith(currentValue.value, [selectedOption], _.isEqual);
      else currentValue.value = null;
    };

    const isValueSet = function(option) {
      if (Array.isArray(currentValue.value)) return _.some(currentValue.value, (value) => _.isEqual(value, option));
      else return _.isEqual(currentValue.value, option);
    }

    const isPresetValue = function(option) {
      if (Array.isArray(props.value)) return _.some(props.value, (value) => _.isEqual(value, option));
      else return _.isEqual(props.value, option);
    }

    const currentSelectedFilterOptions = computed(() => {
      if (currentSelectedFilter.value != null && currentSelectedFilter.value in props.availableFilters) {
        return props.availableFilters[currentSelectedFilter.value];
      }
      return {}
    });

    const currentSelectedFilterOptionsCount = computed(() => {
      if (currentSelectedFilterOptions.value != null && currentSelectedFilterOptions.value.categories != null) {
        return _.sumBy(Object.values(currentSelectedFilterOptions.value.categories), (category) => {
          if (category != null && category.options != null) return category.options.length;
          return 0;
        });
      }
      return 0;
    });

    const currentSelectedFilterHasOptions = computed(() => {
      return currentSelectedFilterOptionsCount.value > 0;
    });

    const currentFilteredFilterOptions = computed(() => {
      if (currentSelectedFilterOptions.value != null && currentSelectedFilterOptions.value.categories != null) {
        let mappedCategories = _.mapValues(currentSelectedFilterOptions.value.categories, (category) => {
          let filteredOptions = _.filter(category.options, (option) => {
            if (option.unfiltered || isPresetValue(option.value) || isValueSet(option.value)) return true; //Always include manually set options that should be visible all the time or already selected options
            
            //If a searchTerm is present, include it if it is found
            if (searchFilter.value != null && searchFilter.value.length > 0) {
              //Normalize search term to not include spaces
              let searchTerm = removeNonAlphaNumCharactersNormalize(searchFilter.value);

              if (option.localizedName != null && removeNonAlphaNumCharactersNormalize(option.localizedName).includes(searchTerm)) return true;
              //Already normalized!
              if (option.searchMetadata != null && Array.isArray(option.searchMetadata)) {
                for (let metadataValue of option.searchMetadata) {
                  if (metadataValue != null && metadataValue.includes(searchTerm)) return true;
                }
              }
              return false;
            }
            return true;//No search term entered, include this option
          });

          if (filteredOptions.length) {
            return {
              ..._.omit(category, 'options'),
              options: filteredOptions
            };
          } else {
            return undefined;
          }
        });
        return _.pickBy(mappedCategories, (categoryOptions) => categoryOptions != null); //Filter out empty categories
      } else {
        return {};
      }
    });

    const currentFilteredFilterHasOptions = computed(() => {
      return (currentFilteredFilterOptions.value != null && Object.keys(currentFilteredFilterOptions.value).length);
    });

    const sortNegativeOrderFirst = function(orderA, orderB) {
      //Sort negatives always at the end - If at least one of them is negative, reverse the sorting order, so the more negative values are further at the end
      if (orderA < 0 || orderB < 0) {
        if (orderA > orderB) return -1;
        if (orderA < orderB) return 1;
      }

      //If the order differs, sort them accordingly
      if (orderA < orderB) return -1;
      if (orderA > orderB) return 1;

      return 0;
    }

    const createObjectOrderSortFunction = computed(() => function(object) {
      return function(firstKey, secondKey) {
        //Put null categories always at the beginning, if they differ
        if (firstKey != secondKey) {
          if (firstKey == 'null') return -1;
          if (secondKey == 'null') return 1;
        }

        //If order is defined on this category, check if we need to sort it differently, if the orders don't match. Default order property is 0
        if (object != null) {
          //Get order and parse it as a number. Default if undefined or NaN is 0
          let firstKeyOrder = (object[firstKey] != null && object[firstKey].order != null) ? parseInt(object[firstKey].order) : 0;
          let secondKeyOrder = (object[secondKey] != null && object[secondKey].order != null) ? parseInt(object[secondKey].order) : 0;
          if (isNaN(firstKeyOrder)) firstKeyOrder = 0;
          if (isNaN(secondKeyOrder)) secondKeyOrder = 0;

          let orderSort = sortNegativeOrderFirst(firstKeyOrder, secondKeyOrder);
          if (orderSort != 0) return orderSort;
        }

        //If they are in the same order, sort them by alphabetical order
        return firstKey.localeCompare(secondKey, i18n.locale.value);
      }
    });

    const optionSortFunction = computed(() => {
      return function(firstOption, secondOption) {
        if (firstOption != null && secondOption != null) {
          //Get order and parse it as a number. Default if undefined or NaN is 0
          let firstOptionOrder = (firstOption.order != null) ? parseInt(firstOption.order) : 0;
          let secondOptionOrder = (secondOption.order != null) ? parseInt(secondOption.order) : 0;
          if (isNaN(firstOptionOrder)) firstOptionOrder = 0;
          if (isNaN(secondOptionOrder)) secondOptionOrder = 0;

          let orderSort = sortNegativeOrderFirst(firstOptionOrder, secondOptionOrder);
          if (orderSort != 0) return orderSort;

          //If they are in the same order, sort them by alphabetical order
          if (firstOption.localizedName != null && secondOption.localizedName != null) {
            return firstOption.localizedName.localeCompare(secondOption.localizedName, i18n.locale.value);
          }
        }
        return 0;
      }
    });

    const filterComplete = computed(() => {
      if (currentSelectedFilter.value == null || currentValue.value == null) {
        return false;
      }
      if (Array.isArray(currentValue.value) || typeof currentValue.value === 'string' || currentValue.value instanceof String) { //If we have an array or string check that it is not empty
        return currentValue.value.length > 0;
      }
      if (currentValue.value === true || currentValue.value === false) { //If we have a boolean, return the value
        return true;
      }
      return !isNaN(currentValue.value); //Last check can only be a number. Can not be NaN
    });

    const currentSize = ref({width: 0, height: 0});

    const getEntrySize = function(entry) {
      let size = {
        width: 0,
        height: 0
      };

      if (entry.borderBoxSize) {
        const borderBoxSize = entry.borderBoxSize[0];
        size.width = borderBoxSize.inlineSize;
        size.height = borderBoxSize.blockSize;
      } else {
        const contentRect = entry.contentRect;
        size.width = contentRect.width;
        size.height = contentRect.height;
      }

      return size;
    }

    //Observer to resize the popover if the content size changes, so it never goes out of bounds!
    const resizePopoverObserver = new ResizeObserver(function(entries) {
      //Should always just have one, so check the first occurance
      if (entries.length > 0) {
        //Get the new size
        let newSize = getEntrySize(entries[0]);

        //Get the difference and adjust the transform by the difference
        if (newSize != null) {
          //Only apply changes, when the size was not 0 before
          if (currentSize.value.width > 0 && currentSize.value.height > 0) {
            let difference = {
              width: newSize.width - currentSize.value.width,
              height: newSize.height - currentSize.value.height
            }

            //Create a mapping from the value that should get adjusted if it exists and by how much it should get adjusted
            const correctionMap = {
              left: difference.width * (-1), //If width increases, move it to the left (decrease left), otherwise to the right (increase left)
              top: difference.height * (-1), //If height increases, move it to the top (decrease top), otherwise to the bottom (increase top)
              //Bottom and right are never adjusted, because it automatically grows in these directions
            }

            //Those are always included just for comparisons
            const comparisonStyles = ['transform-origin'];

            //Pick the targeted attributes and filter out all invalid values - transform-origin is always included!
            let currentPickedStyles = _.pick(entries[0].target.style, comparisonStyles);
            _.assign(currentPickedStyles, _.pick(getComputedStyle(entries[0].target), Object.keys(correctionMap))); //Use computedStyle to finish all calculations
            let currentStyle = _.mapValues(currentPickedStyles, (value, key) => {
              //If it is included for comparison, always return
              if (comparisonStyles.includes(key)) return value;
              //Otherwise try to parse the value
              if (value != null && value.length) {
                let parsedValue = parseFloat(value);
                if (isNaN(parsedValue)) return null;
                return parsedValue;
              }
              return null;
            });

            //Modify the corresponding position attribute if it exists!
            for (let [attribute, correction] of Object.entries(correctionMap)) {
              if (currentStyle[attribute] != null) { //if it is null, it doesn't exist or parses to NaN
                //Only update if the origin does not depend on this value
                if (currentStyle['transform-origin'] == null || !(currentStyle['transform-origin'].includes(attribute))) {
                  let newValue = currentStyle[attribute] + correction;
                  entries[0].target.style[attribute] = `${newValue}px`;
                }
              }
            }
          }

          //Set the new size to be the current size
          currentSize.value = newSize;
        }
      }
    });

    const handleResize = function(){
      popoverController.dismiss(); //Close popover when resizing or rotating device screen for correct display
    }

    onMounted(() => {
      const popoverContent = document.querySelector(`.${FILTER_POPOVER_CLASS} .popover-content`);

      if (popoverContent != null) {
        //Set the currentSize of the popover element that will be observed
        currentSize.value = _.pick(popoverContent.getBoundingClientRect(), ['width', 'height']);

        resizePopoverObserver.observe(popoverContent);
      }

      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      if (resizePopoverObserver != null) resizePopoverObserver.disconnect();

      window.removeEventListener("resize", handleResize);
    });

    const applyFilters = function(){
        popoverController.dismiss({ filter: currentSelectedFilter.value, value: currentValue.value, searchTerm: searchFilter.value || undefined });
    }

    return { i18n, applyFilters, setValue, removeValue, isValueSet, searchFilter, currentSelectedFilter, currentSelectedFilterOptions, currentSelectedFilterOptionsCount, currentSelectedFilterHasOptions, currentFilteredFilterOptions, currentFilteredFilterHasOptions, currentValue, createObjectOrderSortFunction, optionSortFunction, filterComplete, blurOnEnter, brightenColorHex, hexToHue, checkmarkSharp }
  }
});

export async function openFilterPopup(component, clickEvent, availableFilters, filter, value, options = {}){
  if (availableFilters != null && Object.keys(availableFilters).length) {
    const popup = await popoverController
      .create({
        component,
        event: clickEvent,
        cssClass: FILTER_POPOVER_CLASS,
        componentProps: {
          filter,
          value,
          availableFilters,
          ...options
        },
      })
    popup.present();
    return popup.onWillDismiss(); //Close before dismiss, don't wait for animation to finish. Data is already set.
  }
}

const getBooleanAttribute = function(element, attribute) {
  if (element.hasAttribute(attribute)) {
    let value = element.getAttribute(attribute);

    //If it has a specific boolean value use it
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }

    //Otherwise just use its presence as true
    return true;
  }
  return false;
}

//Split comma separated string
const getCommaSeparatedArrayAttribute = function(element, attribute) {
  if (element.hasAttribute(attribute)) {
    let value = element.getAttribute(attribute);

    //If it is a string, split it
    if (_.isString(value)) {
      return _.split(value, ',');
    }
  }
  return undefined;
}

export const openFilterPopupAsSelectInterface = function(component, event, selectInstance,
  {fallbackAvatarIcon = null, fallbackAvatarIconIsFontAwesome = false, noValuePlaceholder = null, categoryOrder = {}, hideSearchbar = false, allowMultiple = false} = {}) {
  event.preventDefault();
  event.stopImmediatePropagation();

  let options = _.map(selectInstance.getElementsByTagName('ion-select-option'), (option) => {
    return { 
      value: option.value,
      localizedName: option.textContent,
      category: (option.hasAttribute('category')) ? option.getAttribute('category') : null,
      order: (option.hasAttribute('order')) ? option.getAttribute('order') : null,
      italic: getBooleanAttribute(option, 'italic'),
      bold: getBooleanAttribute(option, 'bold'),
      color: (option.hasAttribute('color')) ? option.getAttribute('color') : undefined,
      background: (option.hasAttribute('background')) ? option.getAttribute('background') : undefined,
      textOpacity: (option.hasAttribute('textOpacity')) ? option.getAttribute('textOpacity') : undefined,
      unfiltered: getBooleanAttribute(option, 'unfiltered'),
      additionalText: (option.hasAttribute('additionalText')) ? option.getAttribute('additionalText') : undefined,
      indicatorText: (option.hasAttribute('indicatorText')) ? option.getAttribute('indicatorText') : undefined,
      hideAdditionalText: getBooleanAttribute(option, 'hideAdditionalText'),
      wrapAdditionalText: getBooleanAttribute(option, 'wrapAdditionalText'),
      showAvatar: getBooleanAttribute(option, 'showAvatar'),
      avatarSrc: (option.hasAttribute('avatarSrc')) ? option.getAttribute('avatarSrc') : undefined,
      avatarColor: (option.hasAttribute('avatarColor')) ? option.getAttribute('avatarColor') : undefined,
      avatarGradient: getBooleanAttribute(option, 'avatarGradient'),
      //The metadata should be already normalized to only contain alphanum characters, separated by commas
      searchMetadata: getCommaSeparatedArrayAttribute(option, 'searchMetadata')
    }
  });

  let label = selectInstance.getAttribute('aria-label');
  if (label != null) {
    //Remove the placeholder if present and use it as a label
    let splitPosition = label.indexOf(', ');
    label = label.substring(splitPosition + 1);
  }
  
  return openFilterPopup(component, event, 
    { 
      'select': { 
        localizedName: label,
        allowMultiple,
        categories: _.mapValues(_.groupBy(options, 'category'), (categoryOptions, categoryKey) => {
          return {
            options: categoryOptions,
            order: (categoryOrder != null && categoryKey in categoryOrder) ? categoryOrder[categoryKey] : undefined //If order is defined use it
          }
        })
      }
    },
    'select',
    selectInstance.value,
    {
      hideFilterTitle: true,
      isSelect: true,
      fallbackAvatarIcon,
      fallbackAvatarIconIsFontAwesome,
      noValuePlaceholder,
      hideSearchbar
    })
  .then((data) => {
    if (data != null && data.data != null && data.data['value'] != null) {
      selectInstance.value = data.data['value'];
    }
    return data;
  })
}

export default FilterPopup;
</script>

<style>
.filter-popup-filter-select {
  --width: max-content;
}

.standalone-title b {
  font-size: 1.25em;
}

.filter-popover {
  --width: max-content; /* Adapt width to conent. Items have set a min and max width. Repositioning is done using an observer when width changes! */
  --max-height: 50vh;
  overflow: hidden;
  position: absolute;
}

.filter-popover .popover-viewport {
  position: relative;
  height: 100%;
}
</style>

<style scoped>
ion-content {
  display: flex;
}

ion-list, ion-item {
  --background: var(--ion-background-color);
  background: var(--ion-background-color);
}

ion-item {
  --padding-start: 5px;
  --inner-padding-end: 5px;
  min-width: 150px;
  max-width: min(80vw, 350px);
}

/* Hide border lines on last item */
ion-item:not(.custom-background):last-of-type {
  --border-width: 0 0 0 0;
}

ion-item.custom-background {
  --background: var(--custom-background, var(--ion-background-color));
  --background-hover: var(--custom-background-shade);
  --background-hover-opacity: 1;
  --background-focused: var(--background-hover);
  --background-focused-opacity: 0.8;
  /* Add border around color for better differentiation to background */
  --border-width: 1px 1px 1px 1px;
}

ion-item-divider {
  font-weight: 600;
}

@media (min-height: 500px) {
  .searchbar {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }
}

.searchbar {
  z-index: 100;
  background: var(--ion-background-color, #fff);
  --background: var(--ion-item-background);
  padding: 0px;
}

.searchbar ion-searchbar {
  padding-left: 0px;
  padding-right: 0px;
  min-width: 150px;
  max-width: min(80vw, 350px);
}

.filter-option-label {
  display: flex;
  flex-flow: column;
}

/* Allow for longer texts in select and indent every line but the first using a negative offset on the first line in a wrapped text */
.filter-option-label > .main-text {
  padding-left: 6px;
  white-space: normal!important;
  text-indent: -6px;
  hyphens: auto;
}

.filter-option-label > .additional-text.wrapping {
  white-space: normal;
}

.filter-option-label > .additional-text {
  text-overflow: ellipsis;
  flex-shrink: 1;
  font-size: 0.8em;
  font-weight: 400;
}

.filter-option-label > .indicator-text {
  text-overflow: ellipsis;
  flex-shrink: 1;
  color: var(--ion-color-medium);
  font-size: 0.8em;
  font-weight: 400;
}

.no-results-hint > *, .no-entries-hint > * {
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
  white-space: normal!important;
}

/* Prevent text selection in hints */
.no-results-hint, .no-entries-hint {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ion-content::part(scroll) {
  max-height: 50vh;
  overflow-y: auto;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
}

ion-checkbox, ion-radio {
  margin-right: 16px;
  margin-left: 5px;
}

ion-footer {
  background: var(--ion-background-color, #fff);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0px;
  padding-bottom: 9px;
}

.italic, .italic > * {
  font-style: italic;
}

.bold, .bold > * {
  font-weight: bold;
}

.avatar-icon {
  border-radius: var(--border-radius);
  background: var(--background, white);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
}

.avatar-icon > * {
  color: var(--custom-color, var(--ion-color-quaternary));
  width: 100%;
  height: 100%;
}

.avatar.gradient .avatar-icon > * {
  color: hsl(var(--hue, 200), 100%, 50%);
}

.avatar {
  background: var(--custom-color, var(--ion-color-quaternary));
  padding: 2px;
  width: 40px;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.avatar.gradient {
  background-image: conic-gradient(hsl(var(--hue, 200), 100%, 50%), hsl(var(--hue, 200), 100%, 65%), hsl(var(--hue, 200), 100%, 50%), hsl(var(--hue, 200), 100%, 65%), hsl(var(--hue, 200), 100%, 50%));
}

/* Style the checkmark of a custom select item with primary color */
.custom-single-option-checkmark {
  margin-inline-start: 16px;
  color: var(--ion-color-primary-text, #fff);
  visibility: hidden;
}
.custom-single-option-checkmark.visible {
  visibility: visible;
}

/* Use inverse color for colored items for better contrast */
ion-item.custom-background .custom-single-option-checkmark {
  mix-blend-mode: difference;
  color: white!important;
}
</style>
